/* eslint-disable */
import React, { useState } from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import {
  MainContainer,
  ModalBody,
  ModalHeading,
  InSideBody,
  MtB5,
  LinkSignInBtn,
} from './deleteImageModal.styles';

import './style.css';

const DeleteImageModal = ({
  imageId,
  setImageDeleteModel,
  deleteFile,
}: {
  imageId: string;
  setImageDeleteModel: any;
  deleteFile: any;
}) => {
  const [visible, setVisible] = useState(true);
  return (
    <MainContainer>
      <Modal
        title="Basic Modal"
        className="sure-modal"
        visible={visible}
        onCancel={() => setImageDeleteModel(false)}
        closeIcon={<CloseOutlined color="#BCCBD9" size={30} />}
      >
        <ModalBody>
          <InSideBody>
            <ModalHeading>Are you sure you want to delete the data?</ModalHeading>
            {/* <ModalDescription>data will remain stored for 24 hours amount of time.</ModalDescription> */}

            <MtB5>
              <LinkSignInBtn onClick={() => deleteFile(imageId)} type="submit">
                Yes
              </LinkSignInBtn>
              <LinkSignInBtn
                style={{ marginTop: '10px' }}
                onClick={() => setImageDeleteModel(false)}
                type="submit"
              >
                No
              </LinkSignInBtn>
            </MtB5>
          </InSideBody>
        </ModalBody>
      </Modal>
    </MainContainer>
  );
};

export default DeleteImageModal;
