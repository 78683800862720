import React, { FC, ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

interface State {
  auth: {
    user: null | {
      is_email_verified: boolean;
      vehicles: string[];
    };
  }
}

export const withoutAuth = (WrappedComponent: FC): FC => ((props): ReactElement => {
  const user = useSelector(({ auth }: State) => auth.user);

  if (user) {
    if (!user.is_email_verified) return <Redirect to="/verifyEmail" />;
    if (!user.vehicles || !Array.isArray(user.vehicles) || user.vehicles.length === 0) {
      return <Redirect to="/addVehicle" />;
    }

    return <Redirect to="/vehicleinspection" />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...props} />;
});
