import { combineReducers } from 'redux';
import auth, { AuthInitialState, authInitialState } from './authReducer';
import instructions, { InstructionInitialState, instructionInitialState } from './instructionReducer';
import vehicals, { VehicalInitialState, vehicalInitialState } from './vehicalReducer';

export interface InitialState {
  auth: AuthInitialState;
  instructions: InstructionInitialState;
  vehicals:VehicalInitialState;
}

export const initialState = {
  auth: authInitialState,
  instructions: instructionInitialState,
  vehicals: vehicalInitialState,
};

const rootReducer = combineReducers({
  auth,
  instructions,
  vehicals,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
