import styled from 'styled-components';

export const MainContainer = styled.div`
  padding: 20px;
`;
export const PrivacyPolicyHeading = styled.p`
  color: #000000;
  font-size: 26px;
  font-weight: bold;
`;

export const PrivacyPolicyUnderLine = styled.p`
  margin-top: 10px;
  font-size: 18px;
`;

export const PrivacyPolicyText = styled.p`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const MainBgSignin = styled.div`
  background: linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%);
  position: relative;
  width: 100%;
  padding: 10px 0;
`;

export const PrivacyPolicySpan = styled.span``;
