export enum ActionTypes {
  AuthFAIL = 'AUTH_FAIL',

  Loading = 'LOADING',

  LoginFromToken = 'LOGIN_FROM_TOKEN',

  LoginUserEnd = 'LOGIN_USER_END',
  LoginUserStart = 'LOGIN_USER_START',

  LogoutUserEnd = 'LOGOUT_USER_END',
  LogoutUserStart = 'LOGOUT_USER_START',

  ForgetPasswordEnd = 'FORGET_PASSWORD_END',
  ForgetPasswordStart = 'FORGET_PASSWORD_START',

  RestPasswordEnd = 'RESET_PASSWORD_END',
  RestPasswordStart = 'RESET_PASSWORD_START',

  RegisterUserEnd = 'REGISTER_USER_END',
  RegisterUserStart = 'REGISTER_USER_START',
  setContactUs='CONTACT_US',
  UploadFileEnd = 'UPLOAD_FILE_END',
  deleteFileEnd = 'DELETE_FILE_END',
  UploadFileStart = 'UPLOAD_FILE_START',
  ImageUploadLoading = 'IMAGE_UPLOAD_LOADING',
  SpecificImageeUploadLoading = 'SPECIFIC_IMAGE_UPLOAD_LOADING',
  IsVehicalInspectionModal = 'IS_VEHICLE_INSPECTION_MODAL',
  setVehicalModalValue = 'SET_VEHICLE_MODAL_VALUE',
  setThankYouModalValue = 'SET_THANK_YOU_MODAL_VALUE',
  setThankYouModalStateValue = 'SET_THANK_YOU_MODAL_STATE_VALUE',

  DeleteFile = 'DELETE_FILE',

  AddVehicleStart = 'ADD_VEHICLE_START',
  getVinNumber = 'GET_VIN_NUMBER',
  getVinNumberComplete = 'GET_VIN_NUMBER_COMPLETE',
  addVehicleLoading = 'ADD_VEHICLE_LOADING',
  setVehicleDetailModal = 'SET_VEHICLE_DETAIL_MODAL',
  vehicleDetailModalData = 'VEHICLE_DETAIL_MODAL_DATA',
  vehicleStatusChanged = 'VEHICLE_STATUS_CHANGED',
  EmailVerificationStart = 'EMAIL_VERIFICATION_START',

  ConfirmEmailStart = 'CONFIRM_EMAIL_START',

  CreatePaymentStart = 'CREATE_PAYMENT_START',
  setTransactionStatus = 'TRANSACTION_STATUS',
  setTransactionStatusComplete = 'TRANSACTION_STATUS_COMPLETE',
  transactionHistory = 'TRANSACTION_HISTORY',
  setTransactionHistory = 'SET_TRANSACTIONS_HISTORY',
  getVehicleCompanies = 'GET_VEHICLE_COMPANIES',
  getVehicleCompaniesComplete = 'GET_VEHICLE_COMPANIES_COMPLETE',
  getVehicleTransactionStatus = 'GET_VEHICLE_TRANSACTION_STATUS',
  getImageToken = 'GET_IMAGE_TOKEN',
  getGeneralCertificate = 'GET_GENERAL_CERTIFICATE',
  getGeneralCertificateFinal = 'GET_GENERAL_CERTIFICATE_FINAL',

  skipPaymentMethod = 'SKIP_PAYMENT_METHOD'
}
