import { AxiosResponse } from 'axios';
import api from '.';

export const register = (values: object): Promise<AxiosResponse> => (
  api.post('/api/v1/auth/signup', values)
);

export const getUserFromToken = (): Promise<AxiosResponse> => (
  api.get('/api/v1/user/me')
);

export const resendEmail = (email: string): Promise<AxiosResponse> => (
  api.post('/api/v1/users/resend/verify/email', { email })
);

export const confirmEmail = (params: object): Promise<AxiosResponse> => (
  api.get('/api/v1/users/verify/email', { params })
);
