import React from 'react';
import DashboardLeftSideBarComp from '../DashboardComponent/DashboardLeftSideBar.component';
import DashboardTopBarComp from '../DashboardComponent/DashboardTopBar.component';
import DashboardInspectionContentComp from './DashboardInspectionContent.component';
import {
  MainContainer,
  RightContent,
} from '../DashboardComponent/DashboardPage.styles';

const DashboardInspectionComp = () => (
  <MainContainer>
    <DashboardLeftSideBarComp />
    <RightContent>
      <DashboardTopBarComp />
      <DashboardInspectionContentComp />
    </RightContent>
  </MainContainer>
);
export default DashboardInspectionComp;
