import React from 'react';
import DashboardLeftSideBarComp from '../DashboardComponent/DashboardLeftSideBar.component';
import DashboardTopBarComp from '../DashboardComponent/DashboardTopBar.component';
import DashboardContentComp from './DashboardContent.component';
import {
  MainContainer,
  RightContent,
} from '../DashboardComponent/DashboardPage.styles';

const DashboardPageComp = () => (
  <MainContainer>
    <DashboardLeftSideBarComp />
    <RightContent>
      <DashboardTopBarComp />
      <DashboardContentComp />
    </RightContent>
  </MainContainer>
);
export default DashboardPageComp;
