import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer, { initialState } from '../reducers';
import sagaMiddleware from '../middlewares/sagas';
import { rootSaga } from '../dragons/index';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }

  interface process {
    env: {
      NODE_ENV: String
    }
  }
}

const composeEnhancers: typeof compose = process.env.NODE_ENV === 'development'
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;

const middlewares = applyMiddleware(sagaMiddleware);
const store = createStore(rootReducer, initialState, composeEnhancers(middlewares));
sagaMiddleware.run(rootSaga);

export default store;
