/* eslint-disable */
import React, { useState } from 'react';
import { ClipLoader } from 'react-spinners';
// import * as Yup from 'yup';
// import { useFormik } from 'formik';
import { CardExpiryElement, CardNumberElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { toast } from 'react-toastify';
import { LinkSignInBtnStrip } from '../LoginPage/LoginPage.styles';
import {
  MainContainer,
  ImageBackgroundPaymentinfo,
  MainBgPaymentInfo,
  ContentPaymentInfo,
  ContentPaymentInfoSpanArrowBackA,
  ContentPaymentInfoSpan,
  ContentPaymentinfoH1,
  ContentPaymentInfoH5Span,
  // ContentPaymentInfoSpanDot,
  ContentFooterAreaPaymentInfo,
  ContentFooterAreaBorderInput,
  ContentFooterAreaPaymentLabel,
  ContentFooterAreaPaymentCVV,
  ContentFooterAreaPaymentExpireCVV,
  ContentFooterAreaPaymentExpireDate,
  ContentFooterAreaPaymentP,
  ContentFooterAreaPaymenMtB5,
  PromoConditionText,
  HeaderItem,
  LoaderContainer,
  ContentFooterAreaBorderInputStripe,
  MainDownContainer,
} from './PaymentDetails.styles';

import { RootState } from '../../reducers';
import { startPayment } from '../../actions/AuthActions';

// const initialValues = {
//   promoCode: '',
// };

// const validationSchema = Yup.object().shape({
//   promoCode: Yup.string().required('Promo code is required!'),
// });

const PaymentDetailsComp = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const addVehicleLoading: any = useSelector((state: RootState) => state.vehicals.isAddVehicle);
  const loading: any = useSelector((state: RootState) => state.auth.loading);

  const [placeHolderName, setPlaceHolderName] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [fields, setFields] = useState(true);
  const [promoCode, setPromoCode] = useState('');

  const mainState = useSelector((state: RootState) => state);
  // console.log('main sate', mainState.auth.user.companies.length);
  const vechileId = get(mainState, 'auth.user.vehicles[0].id');
  const getCompanies = get(mainState, 'auth.user.companies');
  console.log('getCOmapnies', getCompanies);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    setDisableButton(true);
    const card = elements.getElement(CardNumberElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      toast(result.error.message, { type: 'error' });
      setDisableButton(false);
    } else {
      setDisableButton(true);
      dispatch(startPayment({ source: result.token.id, vechileId }));
    }
    setDisableButton(false);
  };
  const handlePromo = async (event: any) => {
    event.preventDefault();
    dispatch(startPayment({ promoCode, vechileId }));
  };
  return (
    <form onSubmit={promoCode.length ? handlePromo : handleSubmit}>
      <MainContainer>
        <ImageBackgroundPaymentinfo>
          <MainBgPaymentInfo>
            <ContentPaymentInfo>
              <HeaderItem>
                <ContentPaymentInfoSpanArrowBackA to="/">
                  <i className="fas fa-chevron-left fa-2x" />
                </ContentPaymentInfoSpanArrowBackA>
                <ContentPaymentInfoH5Span>Payment Info</ContentPaymentInfoH5Span>
              </HeaderItem>
              <ContentPaymentinfoH1>
                $19
                <ContentPaymentInfoSpan>.99</ContentPaymentInfoSpan>
              </ContentPaymentinfoH1>
            </ContentPaymentInfo>
            {loading ? (
              <LoaderContainer>
                <ClipLoader size={50} color="#1A60A6" loading />
              </LoaderContainer>
            ) : (
              <MainDownContainer>
                <ContentFooterAreaPaymentInfo>
                  <ContentFooterAreaBorderInput>
                    {/* {getCompanies?.length > 1 ? null : (
                      <>
                        <ContentFooterAreaPaymentLabel>Please enter a promo code provided to you from the ridesharing company</ContentFooterAreaPaymentLabel>
                        <ContentFooterAreaBorderInput>
                          <input
                            disabled={fields === false}
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            name="cardnumber"
                            type="password"
                            placeholder="Promo Code"
                          />
                        </ContentFooterAreaBorderInput>
                        <PromoConditionText>If you dont have a promo code, please enter payment information.</PromoConditionText>
                      </>
                    )} */}
                    <ContentFooterAreaPaymentLabel style={{ marginTop: '15px' }}>Card Number</ContentFooterAreaPaymentLabel>
                    <ContentFooterAreaBorderInputStripe style={{ width: '100%' }}>
                      <CardNumberElement
                        onChange={(e) => setFields(e.empty)}
                        options={{
                          disabled: promoCode !== '',
                          style: {
                            base: {
                              backgroundColor: '#E7EEF5',
                              fontSize: '20px',
                              ':disabled': {
                                color: '#aab7c4',
                              },
                              color: '#424770',
                              '::placeholder': {
                                color: '#aab7c4',
                              },
                            },

                            invalid: {
                              color: '#9e2146',
                            },
                          },
                        }}
                      />
                    </ContentFooterAreaBorderInputStripe>
                    <ContentFooterAreaPaymentP style={{ marginTop: '25px' }}>Cardholder Name</ContentFooterAreaPaymentP>
                    <input value={placeHolderName} onChange={(e) => setPlaceHolderName(e.target.value)} type="text" name="name" disabled={promoCode !== ''} />
                    <ContentFooterAreaPaymentExpireCVV>
                      <ContentFooterAreaPaymentExpireDate>
                        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                          <ContentFooterAreaPaymentLabel>Expire Date</ContentFooterAreaPaymentLabel>
                          <ContentFooterAreaBorderInputStripe>
                            <CardExpiryElement
                              onChange={(e) => setFields(e.empty)}
                              options={{
                                disabled: promoCode !== '',
                                style: {
                                  base: {
                                    fontSize: '20px',
                                    backgroundColor: '#E7EEF5',
                                    color: '#424770',
                                    '::placeholder': {
                                      color: '#aab7c4',
                                    },
                                  },
                                  invalid: {
                                    color: '#9e2146',
                                  },
                                },
                              }}
                            />
                          </ContentFooterAreaBorderInputStripe>
                        </div>
                      </ContentFooterAreaPaymentExpireDate>

                      <ContentFooterAreaPaymentCVV>
                        <ContentFooterAreaPaymentLabel>CVC</ContentFooterAreaPaymentLabel>
                        <ContentFooterAreaBorderInputStripe>
                          <CardCvcElement
                            onChange={(e) => setFields(e.empty)}
                            options={{
                              disabled: promoCode !== '',
                              style: {
                                base: {
                                  fontSize: '20px',
                                  backgroundColor: '#E7EEF5',
                                  color: '#424770',
                                  '::placeholder': {
                                    color: '#aab7c4',
                                  },
                                },
                                invalid: {
                                  color: '#9e2146',
                                },
                              },
                            }}
                          />
                        </ContentFooterAreaBorderInputStripe>
                      </ContentFooterAreaPaymentCVV>
                    </ContentFooterAreaPaymentExpireCVV>
                    <ContentFooterAreaPaymenMtB5>
                      <LinkSignInBtnStrip disabled={disableButton} type="submit">
                        {addVehicleLoading ? <ClipLoader size={20} color="#1A60A6" loading /> : 'Submit'}
                      </LinkSignInBtnStrip>
                    </ContentFooterAreaPaymenMtB5>
                  </ContentFooterAreaBorderInput>
                </ContentFooterAreaPaymentInfo>
              </MainDownContainer>
            )}
          </MainBgPaymentInfo>
        </ImageBackgroundPaymentinfo>
      </MainContainer>
    </form>
  );
};
export default PaymentDetailsComp;
