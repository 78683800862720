/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { Carousel } from 'antd';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';
import {
  MainContainer,
  MainBgInsertDetails,
  HeaderItem,
  ArrowBackA,
  ContentVehicleInspectionH1,
  InsertDetailsP,
  InsertDetailsH,
  CarouselContainer,
  CarouselContainerItem,
  DescriptionContainer,
  InsertDetailsDescription,
  InsertDetailsMtB5,
  CarouselContainerItemList,
  CarouselContainerItemPlayContainer,
  CarouselContainerItemPlayImage,
  TipsHeading,
} from './DoAndDont.styles';

const DoAndDont: FC = () => {
  const location = useLocation();
  const [playing, setPlaying] = useState(true);
  const [icon, setIcon] = useState(false);
  useEffect(() => {
    console.log('location', location.search);
    // console.log(location.search)
  });
  return (
    <MainContainer>
      <MainBgInsertDetails>
        <HeaderItem>
          {/* <ArrowBackA to="/addVehicleDetail">
            <i className="fas fa-chevron-left fa-2x" color="#2584E0" />
          </ArrowBackA> */}
          <ContentVehicleInspectionH1>{/* Do's and Don't */}</ContentVehicleInspectionH1>
        </HeaderItem>
        {/* <InsertDetailsP>
          We will add a combination of videos and written stuff here to prepare
          users before Main function page
        </InsertDetailsP> */}
        <TipsHeading>Tips to successfully conduct an inspection:</TipsHeading>
        {/* <InsertDetailsH>Lorem ipsum dolor</InsertDetailsH> */}
        <CarouselContainer>
          <div className="carousel-div">
            <Carousel autoplay infinite>
              <CarouselContainerItem>
                <CarouselContainerItemList>
                  {/* <source src="/images/Video.mov" ></source> */}
                  <video controls autoPlay={false} loop={true} width="100%">
                    <source src="/images/Video.mov"></source>
                  </video>
                  {/* <ReactPlayer
                    height="170px"
                    playing={playing}
                    loop={true}
                    width="100%"
                    url="/images/Video.mov"
                  /> */}
                </CarouselContainerItemList>
                {icon ? (
                  <CarouselContainerItemPlayContainer onClick={() => setPlaying(!playing)}>
                    <CarouselContainerItemPlayImage src="/images/playbtn.png" />
                  </CarouselContainerItemPlayContainer>
                ) : null}
              </CarouselContainerItem>
            </Carousel>
          </div>
        </CarouselContainer>
        <DescriptionContainer>
          <InsertDetailsDescription>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. At
            ullamcorper enim bibendum in sed. Tristique aenean nulla sit
            ullamcorper. Tellus lectus pellentesque aliquet nisl, ornare egestas
            suspendisse elit. */}

            <ul>
              <li>Find an empty lot or area with minimal distractions</li>
              <li>Avoid noisy surroundings</li>
              <li>Take photos and record videos during the daylight</li>
              <li>Have a friend/partner to assist with video clips</li>
              <li>Get a penny for tire inspections</li>
            </ul>
          </InsertDetailsDescription>
        </DescriptionContainer>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InsertDetailsMtB5 to={`/vehicleInspection${location.search}`}>NEXT</InsertDetailsMtB5>
        </div>
      </MainBgInsertDetails>
    </MainContainer>
  );
};

export default DoAndDont;
