import React, { useEffect } from 'react';
import qs from 'qs';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmEmailStart } from '../../actions/AuthActions';
import history from '../../history';

const ConfirmEmailPage = () => {
  const { search } = useLocation();

  const dispatch = useDispatch();

  useEffect(() => {
    const { email, token } = qs.parse(search.replace('?', '')) as { email?: string; token?: string; };
    if (!email || !token) {
      history.push('/login');
      toast('Invalid link!', { type: 'error' });
    } else {
      dispatch(confirmEmailStart({ email, token }));
    }
  }, []);

  return <div />;
};

export default ConfirmEmailPage;
