import { Action } from 'redux';
// import { createAsExpression, createDebuggerStatement } from 'typescript';
import { ActionTypes } from '../actions/ActionTypes';

export interface DispatchAction extends Action {
  payload: Partial<InstructionInitialState>;
}

export interface InstructionObj {
  id: string;
  type: 'Photo' | 'Clip';
  title: string;
  description: string;
  steps: string[];
  file?: string;
  fileId?: any;
  image?: any;
  loading?: boolean;
  video?: any;
}

const description = 'Instructional video on how to record video of rear of moving vehicle';
// const steps = ['Please record a 5 second video clip,
// showing htadlight swithed on, Indicators/emergency parking
// lights on and windshield wiper on and functioning.'];
const tireSteps = [
  'Please place a penny on the tire thread with heads on the penny upside down and facing the camera and take a picture',
];
export interface InstructionInitialState {
  instructions: InstructionObj[];
  loading: boolean;
  isVehicalModal: boolean;
}

export const instructionInitialState: InstructionInitialState = {
  loading: false,
  isVehicalModal: false,
  instructions: [
    {
      id: 'exterior_left',
      type: 'Photo',
      title: 'Exterior Left',
      description,
      steps: [
        'Please take a photo from the center position of the left side (driver) of your vehicle capturing the entire exterior left side of your vehicle',
      ],
      image: '/images/Photo1Exterior Left.png',
      video: '/images/Clip 1 - Front.mov',
      loading: false,
    },
    {
      id: 'exterior_right',
      type: 'Photo',
      title: 'Exterior Right',
      description,
      steps: [
        'Please take a photo from the center position of the right side (Passenger) of your vehicle capturing the entire exterior right side of your vehicle',
      ],
      image: '/images/Photo2Exterior Right.png',
      video: '/images/Clip 2 - Rear.mov',
      loading: false,
    },
    {
      id: 'exterior_front',
      type: 'Clip',
      title: 'Exterior Front',

      description: 'Instructional video on how to record video of rear of moving vehicle',
      steps: [
        'Please record a short video clip showing the front of the vehicle with all the front lights, indicators and windshield wipers turned on. Our goal is to verify the functionality of those features and identify any major damage on the front',
      ],
      video: '/images/Clip 1 - Front-1-720p.m4v',
      loading: false,
    },
    {
      id: 'exterior_rear',
      type: 'Clip',
      title: 'Exterior Rear',
      description: "Instructional video on how to record your vehicle's exterior",
      steps: [
        'Set phone down behind car (or have someone hold the phone with car in field of view); once camera is positioned well, drive car 3-5 feet in reverse and push on the brake pedal. Once car is in the park mode, push the gas pedal. Please ensure that the sound is clear(for checking muffler and for brake sound) and the brake light is in the clear view',
      ],
      video: '/images/Clip 2 - Rear-1-720p.m4v',
      loading: false,
    },
    {
      id: 'interior_driver_side',
      type: 'Photo',
      title: 'Interior Driverside',
      description,
      steps: [
        'Please take a photo of the interior of the vehicle with left driver side door open with clear view of the interior rearview mirror and brakepads. ',
      ],
      image: '/images/Photo 3 - Driverside Interior.png',
      loading: false,
    },
    {
      id: 'odometer',
      type: 'Photo',
      title: 'Odometer',
      description,
      steps: ['Please take a photo entire odometer dashboard area with vehicle turned on.'],
      image: '/images/Photo 5Odometer.png',
      loading: false,
    },
    {
      id: 'interior_passenger_side',
      type: 'Photo',
      title: 'Interior Passengerside',
      description,
      steps: [
        'Please take a photo of the interior of the vehicle with right passenger side door open with clear view of the inside. ',
      ],
      image: '/images/Photo4Passengerside Interior.png',
      loading: false,
    },
    {
      id: 'interior_backseat',
      type: 'Photo',
      title: 'Interior Backseat',
      description,
      steps: ['Please take a photo of the backseats with back doors opened and seatbelts buckled.'],
      image: '/images/Photo6Backseat.png',
      loading: false,
    },
    {
      id: 'driver_seat',
      type: 'Clip',
      title: 'Driver Seat Adjustment, Seatbelt & Horn',
      description:
        'Instructional video on how to record video driver seat Adj., horn and seat belt',
      steps: [
        'Please record a video clip showing front driver side seat adjustment, buckling and unbuckling of driver seat belt and honking horn with a clear sound.',
      ],
      video: '/images/Clip 3 - Driverside-1-720p.m4v',
      loading: false,
    },
    {
      id: 'passenger_seat',
      type: 'Clip',
      title: 'Passenger Seat Adjustment & Seatbelt',
      description: 'Instructional video on how to record video of rear of moving vehicle',
      steps: [
        'Please record a clip showing adjustment for front passenger seat and buckling and unbuckling of passenger seat belt.',
      ],
      video: '/images/Clip 4 - Passengerside-1-720p.m4v',
      loading: false,
    },
    {
      id: 'left_front_tire',
      type: 'Photo',
      title: 'Left Front Tire',
      description,
      steps: [`${tireSteps}`],
      image: '/images/Photot710Tire.png',
      loading: false,
    },
    {
      id: 'left_rear_tire',
      type: 'Photo',
      title: 'Left Rear Tire',
      description,
      steps: [`${tireSteps}`],
      image: '/images/Photot710Tire.png',
      loading: false,
    },
    {
      id: 'right_front_tire',
      type: 'Photo',
      title: 'Right Front Tire',
      description,
      steps: [`${tireSteps}`],
      image: '/images/Photot710Tire.png',
      loading: false,
    },
    {
      id: 'right_rear_tire',
      type: 'Photo',
      title: 'Right Rear Tire',
      description,
      steps: [`${tireSteps}`],
      image: '/images/Photot710Tire.png',
      loading: false,
    },
  ],
};

export default (state = instructionInitialState, { type, payload }: any) => {
  switch (type) {
    case ActionTypes.ImageUploadLoading:
      return {
        ...state,
        loading: payload,
      };
    case ActionTypes.setVehicalModalValue:
      return {
        ...state,
        isVehicalModal: payload,
      };
    // case ActionTypes.SpecificImageeUploadLoading:
    //   const obj = state.instructions.find((i) => i.id === payload.id);
    //   console.log('outer obj', obj);
    //   if (obj.loading === false) {
    //     obj.loading = payload.loading;
    //     console.log('obj', obj);
    //     return {
    //       ...state,
    //       loadspec: obj,
    //     };
    //   }
    // case ActionTypes.SpecificImageeUploadLoading:
    //   return {
    //     ...
    //   }
    default:
      return state;
  }
};
