import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FrontPageDiv, WrapperDiv, PrimaryButton } from '../styles';
import {
  MessageContainer, MessageContainer2, MessageH2, MessageP, MessageContainer3,
} from './FinalPage.styles';
import { logoutUser } from '../../actions/AuthActions';
import { RootState } from '../../reducers';

const Review = () => {
  const dispatch = useDispatch();

  const user = useSelector(({ auth }: RootState) => auth.user);

  const logout = () => dispatch(logoutUser());

  return (
    <FrontPageDiv finalBackground>
      <WrapperDiv>
        <MessageContainer>
          <MessageContainer2>
            <MessageContainer3>
              <MessageH2>Thank you for using Chex.AI</MessageH2>
              <MessageP>
                You may now exit our app and one of the representatives will
                reach out to you for the next steps.
              </MessageP>
              {user && <PrimaryButton onClick={logout}>Logout</PrimaryButton>}
            </MessageContainer3>
          </MessageContainer2>
        </MessageContainer>
      </WrapperDiv>
    </FrontPageDiv>
  );
};

export default Review;
