import React, { FC } from 'react';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { RootState } from '../../reducers';
import TextInput from '../formFields/TextInput';
import { forgetPassword } from '../../actions/AuthActions';
import { withoutAuth } from '../../hocs/withoutAuth';
import {
  MainContainer,
  ImageBackgroundSignin,
  MainBgSignin,
  ArrowBack,
  ContentFooterAreaSignin,
  MainScreenP,
  InputSigninPage,
  ArrowBackA,
  MtB5,
  LinkSignInBtn,
  HeaderItem,
  ContentVehicleInspectionH1,
} from './ForgotPassword.styles';

const validationSchema = Yup.object({}).shape({
  email: Yup.string().email('Invalid email!').required('Email is required!'),
  // password: Yup.string().required('Password is required!'),
});

const initialValues = {
  email: '',
  // password: '',
};

const ForgotPassword: FC = () => {
  const dispatch = useDispatch();
  const loading: any = useSelector((state: RootState) => state.auth.loading);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log('email', values);
      dispatch(forgetPassword(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContainer>
        <ImageBackgroundSignin>
          <MainBgSignin>
            <HeaderItem>
              <ArrowBack>
                <ArrowBackA to="/">
                  <i className="fas fa-chevron-left fa-2x" />
                </ArrowBackA>
              </ArrowBack>

              <ContentVehicleInspectionH1>Forgot password</ContentVehicleInspectionH1>
            </HeaderItem>
            <MainScreenP>Type email for password reset</MainScreenP>

            <ContentFooterAreaSignin>
              <InputSigninPage>
                <TextInput formik={formik} name="email" label="Email" />
              </InputSigninPage>
              <MtB5>
                <LinkSignInBtn disabled={loading} type="submit">
                  {loading ? <ClipLoader size={20} color="#1A60A6" loading={loading} /> : 'Send'}
                </LinkSignInBtn>
              </MtB5>
            </ContentFooterAreaSignin>
          </MainBgSignin>
        </ImageBackgroundSignin>
      </MainContainer>
    </form>
  );
};

export default withoutAuth(ForgotPassword);
