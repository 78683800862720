/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';
import { Token } from 'react-stripe-checkout';
import DeleteImageModal from './deleteImageModal';
import { ClipLoader } from 'react-spinners';
import {
  MainContainer,
  MainDownContainer,
  ImageBackgroundVehicleInspection,
  ContentVehicleInspectionH1,
  VehicleInspectionArrowBack,
  OpenNavSpan,
  ContentVehicleInspectionH1Span,
  VehicleInspectionP,
  ContentVehicleInspection,
  ContentFooterareaVehicleinspection,
  RowContentVehicleInspection,
  MtB5VehicleInspection,
  VehicalDetailButton,
  VehicleDetailBtnContainer,
  LinkSignInBtn,
  HeaderItem,
  ArrowBackA,
  MainBgInsertDetails,
  LinkSignInBtn1,
  LinkSignInBtnDisable,
  LinkBtn,
  MobileViewWarningContainer,
  MobileViewWarning,
  BoldSpan,
} from './VehicleInspection.styles';
import { VehicleInstructionModal } from './VehicalInstructionModal.component';
import { VehicleDetailModal } from './VehicleDetailModal.component';
import { ThankyouModal } from './ThankYouModal.component';
import { uploadFile, deleteFileForInspection, startPayment, isModalOpen } from '../../actions/AuthActions';
import { setThankYouModal, imageToken, getVehicleTransactionStatus, vehicleStatusChanged, skipPaymentStatusChanged } from '../../actions/VehicleAction';
import { RootState } from '../../reducers';
import { InspectionCard } from './InspectionCard.component';
import { withAuth } from '../../hocs/withAuth';
// import StripeCheckoutBtn from '../StripeCheckoutBtn/StripeCheckoutBtn.component';
import LeftSidebarComp from '../LeftSidebar/LeftSidebar.component';
import { useHistory } from 'react-router-dom';

const VehicleInspection = () => {
  const history = useHistory();
  const [showSideBar, setShowSidebar] = useState(false);
  const [showInspectParams, setInspectParam] = useState({
    type: 'Photo',
    id: null,
    vechileId: null,
  });
  // const [showVehicleData, setShowVehicleData] = useState({});
  const [isOpenVehicalDetailModal, setIsOpenVehicalDetailModal] = useState(false);
  const [showInstruction, setInstruction] = useState({});
  const [imageModal, setImageModal] = useState(false);
  const [imageString, setImageString] = useState();
  const [imageType, setImageType] = useState();
  const [imageDeleteModel, setImageDeleteModel] = useState(false);
  const [imageId, setImageId] = useState('');
  const [clickImageFeature, setClickImageFeature] = useState('');
  const [progressBarCompare, setProgressBarCompare] = useState([]);
  const [catagory, setCatagory] = useState('');
  const [companyName, setCompanyName] = useState('');
  const dispatch = useDispatch();

  const mainState = useSelector((state: RootState) => state);
  const files = get(mainState, 'auth.user.files', []);
  const vechileId = get(mainState, 'auth.user.vehicles[0].id');
  // const uploadLoading: any = mainState.instructions.loading;
  const isModalOpenFlag: any = mainState.instructions.isVehicalModal;
  const isThanksModal: any = mainState.vehicals.isThanksModal;
  const transactionStatus: any = mainState.vehicals.transactionStatus;
  const vehicleDetail: any = get(mainState, 'auth.user.vehicles[0]');
  const addVehicleLoading: any = useSelector((state: RootState) => state.vehicals.isAddVehicle);
  const progress: any = mainState.vehicals.imageProgress;

  const docs = mainState.instructions.instructions.map((f) => {
    const obj = { ...f };
    const fileExist = files.find((c: any) => c.category === f.id);
    if (fileExist && fileExist.url) {
      obj.file = fileExist.url;
      obj.fileId = fileExist.id;
    }
    return obj;
  });

  const allFilesSubmitted = docs.every((f) => !!f.file);

  const makePayment = (token: Token) => {
    if (token && token.id && vechileId) dispatch(startPayment({ source: token.id, vechileId }));
  };

  useEffect(() => {
    const company = localStorage.getItem('COMPANY_NAME');
    setCompanyName(company);
    if (vehicleDetail.review_status === 'READY_FOR_REVIEW') {
      dispatch(setThankYouModal(true));
    }
    console.log(vechileId);
    dispatch(getVehicleTransactionStatus(vechileId));
  }, []);

  const onClickCard = (type: string, id: any) => {
    setInspectParam({ type, id, vechileId });
    // console.log('progressBarCOmpare', progressBarCompare);
    dispatch(isModalOpen(true));
    docs.find((element: any) => {
      if (element.id === id) {
        setInstruction(element);
      }
    });
  };

  const handleUpload = async (event: any, blob: boolean) => {
    const file = event.target.files[0];
    setClickImageFeature(showInspectParams.id);
    // progressBarCompare.push(showInspectParams.id);
    setProgressBarCompare([...progressBarCompare, showInspectParams.id]);
    dispatch(isModalOpen(false));
    await dispatch(uploadFile(file, showInspectParams.id, vechileId));
  };

  const deleteFile = (id: string, category: string) => {
    const arr = [...progressBarCompare];
    let index = progressBarCompare?.indexOf(catagory);
    if (index > -1) {
      arr.splice(index, 1);
      setProgressBarCompare(arr);
    }
    dispatch(deleteFileForInspection(id, vechileId, catagory));
    setImageDeleteModel(false);
  };

  const setShowModalValue = (value: boolean) => {
    dispatch(isModalOpen(value));
    // dispatch(imageToken({ percentCompleted: 0, category: '' }));
  };

  const deleteImageModel = (id: string, category: string) => {
    setImageId(id);
    setCatagory(category);
    setImageDeleteModel(true);
  };

  const handeStatusChanged = () => {
    console.log('button Pressed');
    dispatch(vehicleStatusChanged(vechileId));
  };

  const goToVehicleStatus = () => {
    if (companyName?.indexOf('Zum') > -1 || companyName?.indexOf('zum') > -1) {
      console.log(vechileId);
      dispatch(skipPaymentStatusChanged(vechileId));
    } else {
      history.push('/paymentDetails');
    }
    // '/vehiclestatus'
  };

  // const closeDeleteModel = () => {
  //   setCatagory('');
  //   setImageDeleteModel(false);
  // };

  // const showVehicleDetails = () => {
  //   setShowVehicleData(vehicleDetail);
  //   setIsOpenVehicalDetailModal(true);
  // };

  // const closeVehicalDetailModal = (value: boolean) => {
  //   setIsOpenVehicalDetailModal(value);
  // };

  if (showSideBar) return <LeftSidebarComp handleClose={() => setShowSidebar(false)} />;
  return (
    <MainContainer>
      <ImageBackgroundVehicleInspection>
        <MainBgInsertDetails>
          <ContentVehicleInspection>
            <HeaderItem>
              <ArrowBackA>
                <i className="fas fa-chevron-left fa-2x" style={{ opacity: 0 }} color="#FFFFFF" />
              </ArrowBackA>
              <ContentVehicleInspectionH1>
                CHEX
                <ContentVehicleInspectionH1Span>.AI</ContentVehicleInspectionH1Span>
              </ContentVehicleInspectionH1>
              <VehicleInspectionArrowBack>
                <OpenNavSpan type="button" onClick={() => setShowSidebar(true)}>
                  &#9776;
                </OpenNavSpan>
              </VehicleInspectionArrowBack>
            </HeaderItem>

            <VehicleInspectionP>Please complete below items to finish vehicle inspection</VehicleInspectionP>
            <MobileViewWarningContainer>
              <MobileViewWarning>
                Please use <BoldSpan>mobile phone </BoldSpan>for optimal perfomance
              </MobileViewWarning>
            </MobileViewWarningContainer>
          </ContentVehicleInspection>
          <VehicleDetailBtnContainer>
            {/* <VehicalDetailButton onClick={showVehicleDetails}>
            Vehicle Detail
          </VehicalDetailButton> */}
          </VehicleDetailBtnContainer>
          <MainDownContainer >
            <ContentFooterareaVehicleinspection>
              <RowContentVehicleInspection>
                {docs?.map((c, index) => (
                  <>
                    {/* {console.log('vehilcleInspection', c)} */}
                    <InspectionCard
                      isOpenVehicalDetailModal={isOpenVehicalDetailModal}
                      modalValue={isModalOpenFlag}
                      isThanksModal={isThanksModal}
                      key={c.id}
                      doc={c}
                      deleteFile={deleteFile}
                      onClickCard={onClickCard}
                      imageModal={imageModal}
                      setImageModal={setImageModal}
                      imageString={imageString}
                      setImageString={setImageString}
                      imageType={imageType}
                      setImageType={setImageType}
                      imageDeleteModel={imageDeleteModel}
                      setImageDeleteModel={deleteImageModel}
                      clickImageFeature={clickImageFeature}
                      progress={progress}
                      showInspectParams={progressBarCompare}
                      docIndex={index}
                    />
                  </>
                ))}
                {imageDeleteModel ? <DeleteImageModal imageId={imageId} setImageDeleteModel={setImageDeleteModel} deleteFile={deleteFile} /> : null}
              </RowContentVehicleInspection>
              {transactionStatus ? (
                <MtB5VehicleInspection>
                  <LinkSignInBtn1 onClick={handeStatusChanged}>{addVehicleLoading ? <ClipLoader size={20} color="#1A60A6" loading /> : 'NEXT'}</LinkSignInBtn1>
                </MtB5VehicleInspection>
              ) : (
                <MtB5VehicleInspection>
                  {allFilesSubmitted ? <LinkBtn onClick={goToVehicleStatus}>NEXT</LinkBtn> : <LinkSignInBtnDisable>NEXT</LinkSignInBtnDisable>}
                </MtB5VehicleInspection>
              )}
            </ContentFooterareaVehicleinspection>
          </MainDownContainer>
        </MainBgInsertDetails>
      </ImageBackgroundVehicleInspection>
      <VehicleInstructionModal doc={showInspectParams} instruction={showInstruction} isOpen={isModalOpenFlag} setShowModalValue={setShowModalValue} handleUpload={handleUpload} />
      {/* <VehicleDetailModal
        isOpenVehicalDetailModal={isOpenVehicalDetailModal}
        vehicleDetail={showVehicleData}
        closeVehicalDetailModal={closeVehicalDetailModal}
      /> */}
      <ThankyouModal isOpen={isThanksModal} />
    </MainContainer>
  );
};

export default withAuth(VehicleInspection);
