import { AxiosResponse } from 'axios';
import api from '.';

type UploadFileType = {
  url: string;
  category: string;
  vehicleId: number;
  ext: string;
};

export const uploadFile = (values: UploadFileType): Promise<AxiosResponse> => api.post(`/api/v1/vehicle/${values.vehicleId}/file`, values);

export const deleteFileRequest = (values: any): Promise<AxiosResponse> => api.delete(`/api/v1/files/${values.vehicleId}/${values.id}`);
