import { AxiosResponse } from 'axios';
import api from '.';

export const vehicle = (values: object): Promise<AxiosResponse> => api.post('/api/v1/vehicle', values);

export const getCompanies = (): Promise<AxiosResponse> => api.get('/api/v1/companies');

export const getTransactionStatus = (value: any): Promise<AxiosResponse> => api.get(`/api/v1/transactions/vehicle/${value}`);

export const vehicleStatus = (id: any): Promise<AxiosResponse> => api.post(`api/v1/vehicle/status/${id}`, {});

export const skipPayment = (id: any): Promise<AxiosResponse> => api.post(`api/v1/vehicles/payment/${id}`, {});

export const getReportData = (id: any): Promise<AxiosResponse> => api.get(`/api/v1/vehicle/${id}/files`);
