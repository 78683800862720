/* eslint-disable */

import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import qs from 'qs';
import { useLocation, useHistory } from 'react-router-dom';

import { RootState } from '../../reducers';
import { addVehicle, getVinNumber } from '../../actions/VehicleAction';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  MainContainer,
  ImageBackgroundInsertDetails,
  MainBgInsertDetails,
  HeaderItem,
  ArrowBackA,
  ContentVehicleInspectionH1,
  ContentFooterAreaInsertDetails,
  LoaderContainer,
  VehicalDetailContainer,
  InsertDetailsLabel,
  VehicalDetailDiv,
  InsertDetailsMtB5,
  RenterText,
} from './AddVehcileDetail.styles';
import TextInput from '../formFields/TextInput';

const initialValues = {
  year: '',
  make: '',
  model: '',
  // manufacturer: '',
  // engine: '',
  // trim: '',
  // transmission: '',
};

const validationSchema = Yup.object().shape({
  year: Yup.string().required('Year is required!'),
  make: Yup.string().required('Make is required!'),
  model: Yup.string().required('Model is required!'),
  // manufacturer: Yup.string().required('Manufacturer is required!'),
  // engine: Yup.string().required('Engine is required!'),
  // trim: Yup.string().required('Trim is required!'),
  // transmission: Yup.string().required('Transmission is required!'),
});

const AddVehicleDetailPage: FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  // const history = useHistory();
  const vehicleDetail: any = useSelector((state: RootState) => state.vehicals.vehicalDetail);
  const loading: any = useSelector((state: RootState) => state.auth.loading);
  const addVehicleLoading: any = useSelector((state: RootState) => state.vehicals.isAddVehicle);
  const dispatch = useDispatch();

  const [showVehicalValues, setVehicalValues] = useState(initialValues);

  // console.log('vehicleDetail', vehicleDetail);

  useEffect(() => {
    const { vin } = qs.parse(search.replace('?', '')) as { vin?: string };
    console.log(vin);
    dispatch(getVinNumber(`https://api.carmd.com/v3.0/decode?vin=${vin}`));
  }, []);

  const addVehicleData = () => {
    const { vin, license_plate, color, companies } = qs.parse(search.replace('?', '')) as {
      vin?: string;
      license_plate?: string;
      color?: string;
      companies?: any;
    };
    const companiesArray = companies ? companies.split(',') : undefined;
    console.log('companies', companiesArray);
    const { make, year, model, engine, trim, transmission } = vehicleDetail;
    const vehicleData: object = {
      vin,
      license_plate_no: license_plate,
      make,
      model,
      year,
      color,
      engine,
      trim,
      transmission,
      companies: companiesArray,
    };
    console.log('vehicleData', vehicleData);
    dispatch(addVehicle(vehicleData));
  };

  const addManualData = () => {
    // const { make, year, model, engine, trim, transmission, manufacturer } = showVehicalValues;
    // console.log('vehicleData', vehicleData);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: any) => {
      const { vin, license_plate, color, companies } = qs.parse(search.replace('?', '')) as {
        vin?: string;
        license_plate?: string;
        color?: string;
        companies?: any;
      };
      const companiesArray = companies ? companies.split(',') : undefined;
      const data = {
        vin,
        license_plate_no: license_plate,
        color,
        year: values.year,
        make: values.make,
        model: values.model,
        // manufacturer: values.manufacturer,
        // engine: values.engine,
        // trim: values.trim,
        // transmission: values.transmission,
        companies: companiesArray,
      };
      setVehicalValues(data);
      console.log('data', data);
      dispatch(addVehicle(data));
    },
  });

  const handleReEnter = () => {
    const { vin, license_plate, color, companies } = qs.parse(search.replace('?', '')) as {
      vin?: string;
      license_plate?: string;
      color?: string;
      companies?: any;
    };
    const companiesArray = companies ? companies.split(',') : undefined;
    const data = {
      vin,
      license_plate_no: license_plate,
      color,
      // manufacturer: values.manufacturer,
      // engine: values.engine,
      // trim: values.trim,
      // transmission: values.transmission,
      companies,
    };
    //history.push('/addVehicle', data);
    history.push(
      `/addVehicle?vin=${data.vin}&color=${data.color}&license_plate=${data.license_plate_no}&companies=${data.companies}`
    );
  };

  return (
    <MainContainer>
      <ImageBackgroundInsertDetails>
        <MainBgInsertDetails>
          {vehicleDetail ? (
            <>
              <HeaderItem>
                <ArrowBackA>
                  {/* <i className="fas fa-chevron-left fa-2x" color="#2584E0" /> */}
                </ArrowBackA>
                <ContentVehicleInspectionH1>Vehicle Details</ContentVehicleInspectionH1>
              </HeaderItem>
              <ContentFooterAreaInsertDetails>
                {loading ? (
                  <LoaderContainer>
                    <ClipLoader size={50} color="#1A60A6" loading />
                  </LoaderContainer>
                ) : (
                  <>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Year</InsertDetailsLabel>
                      <VehicalDetailDiv>{vehicleDetail?.year}</VehicalDetailDiv>
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Make</InsertDetailsLabel>
                      <VehicalDetailDiv>{vehicleDetail?.make}</VehicalDetailDiv>
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Model</InsertDetailsLabel>
                      <VehicalDetailDiv>{vehicleDetail?.model}</VehicalDetailDiv>
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Manufacturer</InsertDetailsLabel>
                      <VehicalDetailDiv>{vehicleDetail?.manufacturer}</VehicalDetailDiv>
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Engine</InsertDetailsLabel>
                      <VehicalDetailDiv>{vehicleDetail?.engine}</VehicalDetailDiv>
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Trim</InsertDetailsLabel>
                      <VehicalDetailDiv>{vehicleDetail?.trim}</VehicalDetailDiv>
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Transmission</InsertDetailsLabel>
                      <VehicalDetailDiv>{vehicleDetail?.transmission}</VehicalDetailDiv>
                    </VehicalDetailContainer>
                    <InsertDetailsMtB5>
                      <button onClick={addVehicleData} type="submit">
                        {addVehicleLoading ? (
                          <ClipLoader size={20} color="#1A60A6" loading />
                        ) : (
                          'Submit'
                        )}
                      </button>
                      <div>
                        <RenterText onClick={handleReEnter}>Re-enter VIN</RenterText>
                      </div>
                    </InsertDetailsMtB5>
                  </>
                )}
              </ContentFooterAreaInsertDetails>
            </>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <HeaderItem>
                <ArrowBackA>
                  {/* <i className="fas fa-chevron-left fa-2x" color="#2584E0" /> */}
                </ArrowBackA>
                <ContentVehicleInspectionH1>Vehicle Details</ContentVehicleInspectionH1>
              </HeaderItem>
              <ContentFooterAreaInsertDetails>
                {loading ? (
                  <LoaderContainer>
                    <ClipLoader size={50} color="#1A60A6" loading />
                  </LoaderContainer>
                ) : (
                  <>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Year</InsertDetailsLabel>
                      <TextInput type="number" formik={formik} name="year" label="" />
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Make</InsertDetailsLabel>
                      <TextInput formik={formik} name="make" label="" />
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Model</InsertDetailsLabel>
                      <TextInput formik={formik} name="model" label="" />
                    </VehicalDetailContainer>
                    {/* <VehicalDetailContainer>
                      <InsertDetailsLabel>Manufacturer</InsertDetailsLabel>
                      <TextInput formik={formik} name="manufacturer" label="" />
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Engine</InsertDetailsLabel>
                      <TextInput formik={formik} name="engine" label="" />
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Trim</InsertDetailsLabel>
                      <TextInput formik={formik} name="trim" label="" />
                    </VehicalDetailContainer>
                    <VehicalDetailContainer>
                      <InsertDetailsLabel>Transmission</InsertDetailsLabel>
                      <TextInput formik={formik} name="transmission" label="" />
                    </VehicalDetailContainer> */}
                    <InsertDetailsMtB5>
                      <button onClick={addManualData} type="submit">
                        {addVehicleLoading ? (
                          <ClipLoader size={20} color="#1A60A6" loading />
                        ) : (
                          'Submit'
                        )}
                      </button>
                      <div>
                        <RenterText onClick={handleReEnter}>Re-enter VIN</RenterText>
                      </div>
                    </InsertDetailsMtB5>
                  </>
                )}
              </ContentFooterAreaInsertDetails>
            </form>
          )}
        </MainBgInsertDetails>
      </ImageBackgroundInsertDetails>
    </MainContainer>
  );
};

export default AddVehicleDetailPage;
