/* eslint-disable */

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  ColAreaVehicleInspection,
  ColCameraVehicleInspection,
  MidTickUploadDone,
  MidDownloadIconVehicleInspection,
  Content1DownloadVehicleInspection,
  Content2DownloadVehicleInspection,
  MidDownloadIconVehicleInspectionUpdate,
  CaptureImages,
  ImageContainer,
  CrossVideoIcon,
  VehicleAfterCamera,
  ImageBackgroundInspection,
  VehicleAfterVideo,
  VideoMainContainer,
  VideoTag,
} from './VehicleInspection.styles';
import './style.css';
import { InstructionObj } from '../../reducers/instructionReducer';
import { Modal } from 'antd';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import { ProgressBar } from 'react-bootstrap';
import { s3Link } from '../../config';

export const InspectionCard = ({
  doc,
  modalValue,
  isOpenVehicalDetailModal,
  isThanksModal,
  onClickCard,
  deleteFile,
  imageModal,
  setImageModal,
  imageString,
  setImageString,
  imageType,
  setImageType,
  imageDeleteModel,
  setImageDeleteModel,
  clickImageFeature,
  progress,
  showInspectParams,
  docIndex,
}: {
  doc: InstructionObj;
  modalValue: boolean;
  isOpenVehicalDetailModal: boolean;
  isThanksModal: boolean;
  onClickCard: (type: string, id: string) => void;
  deleteFile: (id: string, category: string) => void;
  imageModal: any;
  setImageModal: any;
  imageString: any;
  setImageString: any;
  imageType: any;
  setImageType: any;
  imageDeleteModel: any;
  setImageDeleteModel: any;
  clickImageFeature: string;
  progress: any;
  showInspectParams: any;
  docIndex: any;
}) => {
  const loading: any = useSelector((state: RootState) => state.instructions.instructions);
  let a = loading.find((i: any) => i.loading === true);
  const [filters, setFilter] = useState([]);
  const [storeIndex, setStoreIndex] = useState('');

  let store: any = '';

  useEffect(() => {
    let array: any = [];
    progress &&
      progress.forEach(
        (i: any) =>
          showInspectParams &&
          showInspectParams.forEach((j: any) => {
            if (i.category === j) {
              array.push(i);
            }
          })
      );
    setFilter(array);
  }, [showInspectParams]);

  const getValues = () => {
    progress.filter((i: any) => showInspectParams.forEach((j: any) => j === i.category)) ? (
      showInspectParams.filter((i: any) => i === doc.id) ? (
        progress
          .filter((i: any) => showInspectParams.forEach((j: any) => j === i.category))
          .map((j: any) => (
            <div className="d-flex">
              {j.percentCompleted === 100 ? null : (
                <ProgressBar
                  className="progressNumber"
                  now={j.percentCompleted}
                  label={`${j.percentCompleted}%`}
                />
              )}
            </div>
          ))
      ) : (
        <div>
          <img src="/images/download_icon.png" alt="" />
          <span>click here</span>
        </div>
      )
    ) : (
      <div>
        <img src="/images/download_icon.png" alt="" />
        <span>click here</span>
      </div>
    );
  };
  return (
    <ColAreaVehicleInspection className="salman">
      <div className="imageModal">
        <Modal
          title="Basic Modal"
          className="imageModal"
          visible={imageModal}
          onCancel={() => setImageModal(false)}
          onOk={() => setImageModal(false)}
          centered={true}
          closeIcon={
            <div style={{ color: '#d07575' }}>
              <i className="fas fa-times"></i>
            </div>
          }
        >
          <div>
            {imageType === 'Photo' ? (
              <ImageBackgroundInspection src={imageString} height="100%" width="100%" alt="" />
            ) : (
              <div>
                <ReactPlayer controls={true} url={imageString} height="300px" width="100%" />
              </div>
            )}
          </div>
        </Modal>
      </div>

      {/* {imageDeleteModel ?  : null} */}

      {doc.file ? (
        <>
          <MidDownloadIconVehicleInspectionUpdate>
            {doc.type === 'Photo' ? (
              <>
                {!modalValue && !isOpenVehicalDetailModal && isThanksModal ? (
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  ></div>
                ) : null}
                <ImageContainer>
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <ImageBackgroundInspection
                      src={`${s3Link}/${doc.file}`}
                      height="165"
                      alt=""
                    />
                    <div
                      style={{
                        width: '100%',
                        height: '150px',
                        zIndex: 0,
                        background: 'rgba(0,0,0, 0.3)',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        borderRadius: '8px',
                      }}
                      onClick={() => {
                        setImageModal(true);
                        setImageString(`${s3Link}/${doc.file}`);
                        setImageType('Photo');
                      }}
                    ></div>
                    <CrossVideoIcon
                      // style={{ marginTop: '12px' }}
                      onClick={() => setImageDeleteModel(doc.fileId, doc.id)}
                    >
                      <i className="fas fa-trash"></i>
                    </CrossVideoIcon>
                    <VehicleAfterCamera src="/images/inspectionCamera.png"></VehicleAfterCamera>
                  </div>
                </ImageContainer>
              </>
            ) : (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <>
                {!modalValue && !isOpenVehicalDetailModal && !isThanksModal ? (
                  <div
                    style={{
                      position: 'relative',
                      width: '100%',
                      height: '100%',
                    }}
                  ></div>
                ) : null}

                <VideoMainContainer>
                  <ImageContainer>
                    <VideoTag preload="metadata">
                      <source
                        src={`${s3Link}/${doc.file}#t=0.5`}
                        type="video/mp4"
                      />
                    </VideoTag>
                  </ImageContainer>
                  <div
                    style={{
                      width: '100%',
                      height: '150px',
                      zIndex: 0,
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      borderRadius: '8px',
                    }}
                    onClick={() => {
                      setImageModal(true);
                      setImageString(`${s3Link}/${doc.file}#t=0.5`);
                      setImageType('Video');
                    }}
                  ></div>
                  <CrossVideoIcon
                    // style={{ marginTop: '12px' }}
                    onClick={() => setImageDeleteModel(doc.fileId, doc.id)}
                  >
                    <i className="fas fa-trash"></i>
                  </CrossVideoIcon>
                  <VehicleAfterVideo src="/images/play_inspection.png"></VehicleAfterVideo>
                </VideoMainContainer>
              </>
            )}
          </MidDownloadIconVehicleInspectionUpdate>
          {!modalValue && !isOpenVehicalDetailModal && !isThanksModal ? (
            <MidTickUploadDone>
              <i className="fas fa-check" />
            </MidTickUploadDone>
          ) : null}
        </>
      ) : doc.type ? (
        <CaptureImages>
          {doc.type === 'Photo' ? (
            <ColCameraVehicleInspection>
              <img src="/images/icon_cam.png" alt="" />
            </ColCameraVehicleInspection>
          ) : (
            <ColCameraVehicleInspection>
              <img src="/images/play_icon.png" alt="" />
            </ColCameraVehicleInspection>
          )}

          <MidDownloadIconVehicleInspection>
            {filters.map((i: any) => {
              if (doc.id === i.category) {
                store = docIndex;
                return (
                  <div className="d-flex">
                    {/* {i.percentCompleted === 0 ? (
                      <img
                        onClick={() => onClickCard(doc.type, doc.id)}
                        src="/images/download_icon.png"
                        alt=""
                        style={{ width: '30px' }}
                      />
                    ) : ( */}
                    {/* <ProgressBar
                      className="progressNumber"
                      now={i.percentCompleted}
                      label={`${i.percentCompleted}%`}
                    /> */}
                    
<div style={{textAlign: "center",color: "#387ABC",fontWeight: "bold"}}>{`${i.percentCompleted}%`}</div>
                    {/* )} */}
                  </div>
                );
              }
            })}
        
            {store !== '' ? null : (
              <div style={{ textAlign: 'center' }} onClick={() => onClickCard(doc.type, doc.id)}>
                <img src="/images/download_icon.png" alt="" style={{ width: '30px' }} />

                <span style={{ fontSize: '9px' }}>click here</span>
              </div>
            )}
          </MidDownloadIconVehicleInspection>
        </CaptureImages>
      ) : (
        <CaptureImages onClick={() => onClickCard(doc.type, doc.id)}>
          <ColCameraVehicleInspection>
            <img
              src="/images/icon_cam.png"
              alt="
            style={{width: '30px'}}"
            />
          </ColCameraVehicleInspection>
          <MidDownloadIconVehicleInspection>
            <div style={{ textAlign: 'center' }} onClick={() => onClickCard(doc.type, doc.id)}>
              <img src="/images/download_icon.png" alt="" style={{ width: '30px' }} />
              <span style={{ fontSize: '9px' }}>click here</span>
            </div>
          </MidDownloadIconVehicleInspection>
        </CaptureImages>
      )}
      <Content1DownloadVehicleInspection>{doc.title}</Content1DownloadVehicleInspection>
      <Content2DownloadVehicleInspection>{doc.type}</Content2DownloadVehicleInspection>
    </ColAreaVehicleInspection>
  );
};
