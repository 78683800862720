import styled, { css } from 'styled-components';

interface FrontPageDivProps {
  blackBackground?: Boolean
  finalBackground?: Boolean
}

// TODO: Find a better way for props

export const FrontPageDiv = styled.div`
  background: url(/images/component.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: table;

  ${(p: FrontPageDivProps) => p.blackBackground && css`
    background: #000000;
  `}

  ${(p: FrontPageDivProps) => p.finalBackground && css`
    background: url(/images/final-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
  `}
`;

export const TaskDiv = styled.div`
  margin: 50px 0px 15px 0px;
  width: 100%;
  position: relative;
  display: inline-block;

  width: 100%;
  position: relative;
  display: inline-block;
`;

export const TaskFooterArea = styled.footer`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const TaskFooterGallery = styled.div`
  float: left;
  display: inline-block;
`;

export const TaskFooterGalleryImageView = styled.button`
  width: 49px;
  height: 51px;
  float: left;
  margin-left: 32px;
  margin-top: 35px;
  margin-right: 50px;
`;

export const TaskMainTitle = styled.h1`
  font-size: 26px;
  color: #F5F8F9;
  text-align: center;
  font-family: 'Conv_Georgia Regular font';
  line-height: 40px;
  font-weight: 500;
`;

export const TaskRecordButtonArea = styled.div`
  width: 75px;
  height: 75px;
  border: 2px solid #fff;
  border-radius: 100%;
  float: left;
  position: relative;
  padding: 2px;
  text-align: -webkit-center;
  margin: 20px 0px 0px 0px;
  display: inline-block;
`;

export const TaskSubtitle = styled.h2`
  color: #E2D4D4;
  font-size: 20px;
  text-align: center;
  font-family: 'Conv_Georgia Regular font';
  font-weight: 400;
  line-height: 30px;
`;

export const TaskTitle = styled.h2`
  color: #E2D4D4;
  font-size: 20px;
  text-align: center;
  font-family: 'Conv_Georgia Regular font';
  font-weight: 400;
  line-height: 30px;
`;

export const WrapperDiv = styled.div`
  padding: 0px 15px;
  display: table;
  position: relative;
  width: 100%;
`;
