import React, { FC } from 'react';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { RootState } from '../../reducers';
import TextInput from '../formFields/TextInput';
import { loginUser } from '../../actions/AuthActions';
import { withoutAuth } from '../../hocs/withoutAuth';
import {
  MainContainer,
  ImageBackgroundSignin,
  MainBgSignin,
  ContentMainScreen,
  ArrowBack,
  ContentFooterAreaSignin,
  SigninHeadingH3,
  LogoSpanColor,
  MainScreenP,
  InputSigninPage,
  ContentMainScreenh5,
  ArrowBackA,
  MtB5,
  LoginPageH1,
  LinkSignInBtn,
  ForgotPasswordText,
  // PrivacyLabel,
  // CheckMark,
} from './LoginPage.styles';

const validationSchema = Yup.object({}).shape({
  email: Yup.string().email('Invalid email!').required('Email is required!'),
  password: Yup.string().required('Password is required!'),
});

const initialValues = {
  email: '',
  password: '',
};

const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const loading: any = useSelector((state: RootState) => state.auth.loading);
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(loginUser(values));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContainer>
        <ImageBackgroundSignin>
          <MainBgSignin>
            <ContentMainScreen>
              <ContentMainScreenh5>
                <ArrowBack>
                  <ArrowBackA to="/">
                    <i className="fas fa-chevron-left fa-2x" />
                  </ArrowBackA>
                </ArrowBack>
              </ContentMainScreenh5>
              <LoginPageH1>
                CHEX
                <LogoSpanColor>.AI</LogoSpanColor>
              </LoginPageH1>
              <MainScreenP>Virtual Inspections</MainScreenP>
            </ContentMainScreen>
            <ContentFooterAreaSignin>
              <SigninHeadingH3>Sign in</SigninHeadingH3>
              <InputSigninPage>
                <TextInput formik={formik} name="email" label="Email" />
                <TextInput formik={formik} name="password" type="password" label="Password" />
                <ForgotPasswordText to="/forgotPassword">Forgot password?</ForgotPasswordText>
              </InputSigninPage>

              {/* <PrivacyLabel className="container">
                Remember Password
                <input type="checkbox" />
                <CheckMark className="checkmark" />
              </PrivacyLabel> */}
              <MtB5>
                <LinkSignInBtn disabled={loading} type="submit">
                  {loading ? <ClipLoader size={20} color="#1A60A6" loading={loading} /> : 'Sign in'}
                </LinkSignInBtn>
              </MtB5>
            </ContentFooterAreaSignin>
            <div style={{ height: '100px' }} />
          </MainBgSignin>
        </ImageBackgroundSignin>
      </MainContainer>
    </form>
  );
};

export default withoutAuth(LoginPage);
