/* eslint-disable */

import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import moment from 'moment';
import { get } from 'lodash';

import LeftSidebarComp from '../LeftSidebar/LeftSidebar.component';

import store from '../../store/index';
import { withAuth } from '../../hocs/withAuth';
import { RootState } from '../../reducers';
import { loginFromToken, getTransactionHistory } from '../../actions/AuthActions';
import { getToken } from '../../api';

import {
  ContentVehicleInspection,
  ContentVehicleInspectionH1,
  VehicleInspectionArrowBack,
  OpenNavSpan,
  ContentVehicleInspectionH1Span,
  VehicleInspectionP,
  MainContainer,
  VehicleDetailMainContainer,
  VehicleDetailContainer,
} from './transcationHistory.styles';

const TransactionHisory: FC = () => {
  const [showSideBar, setShowSidebar] = useState(false);
  const dispatch = useDispatch();

  const mainState = useSelector((state: RootState) => state);
  const transactionDetails = get(mainState, 'auth.transactionHistory.data[0]');
  const loading: any = mainState.vehicals.isAddVehicle;

  useEffect(() => {
    const token = getToken();
    if (token) store.dispatch(loginFromToken());
    dispatch(getTransactionHistory({ page: 1, pageSize: 10 }));
  }, []);

  if (showSideBar) return <LeftSidebarComp handleClose={() => setShowSidebar(false)} />;

  return (
    <MainContainer>
      <ContentVehicleInspection>
        <ContentVehicleInspectionH1>
          <VehicleInspectionArrowBack>
            <OpenNavSpan type="button" onClick={() => setShowSidebar(true)}>
              &#9776;
            </OpenNavSpan>
          </VehicleInspectionArrowBack>
          CHEX
          <ContentVehicleInspectionH1Span>.AI</ContentVehicleInspectionH1Span>
        </ContentVehicleInspectionH1>
        <VehicleInspectionP>Transaction History</VehicleInspectionP>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ClipLoader size={20} color="#1A60A6" loading={loading} />
          </div>
        ) : (
            <VehicleDetailMainContainer>
              <VehicleDetailContainer>
                <div style={{ width: '50%', color: '#1362B1' }}>Source</div>
                <div>
                  {transactionDetails
                    ? transactionDetails.isPromo
                      ? 'Promo'
                      : 'Card'
                    : null}
                </div>
              </VehicleDetailContainer>
              <VehicleDetailContainer>
                <div style={{ width: '50%', color: '#1362B1' }}>Vehicle ID:</div>
                <div>{transactionDetails && transactionDetails.vehicle_id}</div>
              </VehicleDetailContainer>
              <VehicleDetailContainer>
                <div style={{ width: '50%', color: '#1362B1' }}>Amount:</div>
                <div>
                  {transactionDetails
                    ? transactionDetails.amount !== null
                      ? transactionDetails && transactionDetails.amount
                      : 0
                    : null}
                  {transactionDetails && transactionDetails ? '$' : null}
                </div>
              </VehicleDetailContainer>
              <VehicleDetailContainer>
                <div style={{ width: '50%', color: '#1362B1' }}>Transaction date:</div>
                {transactionDetails ?
                  moment(transactionDetails && transactionDetails.updated_at).format('MM-DD-YYYY') :
                  null}
                {/* <div>{transactionDetails && transactionDetails.id}</div> */}
              </VehicleDetailContainer>
              <VehicleDetailContainer>
                <div style={{ width: '50%', color: '#1362B1' }}>Created at:</div>
                <div>
                  {transactionDetails ?
                    moment(transactionDetails && transactionDetails.created_at).format('MM-DD-YYYY')
                    :
                    null
                  }

                </div>
              </VehicleDetailContainer>
            </VehicleDetailMainContainer>
          )}
      </ContentVehicleInspection>
    </MainContainer>
  );
};

export default withAuth(TransactionHisory);
