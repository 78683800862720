import { Action } from 'redux';
import { ActionTypes } from '../actions/ActionTypes';

export interface DispatchAction extends Action {
  payload: Partial<VehicalInitialState>;
}

// export interface VehicalDetailObj {
//   year: number;
//   make: string;
//   modal: string;
//   manufacturer: string;
//   engine: string;
//   trim: string;
//   transmission: string;
// }

export interface VehicalInitialState {
  error: string;
  vehicalDetail: any;
  isAddVehicle: boolean;
  isThanksModal: boolean;
  vehicleDetailModal: boolean;
  imageProgress: any;
  category?: any;
  percentCompleted?: any;
  vehicleDetailModalData: any;
  transactionStatus: any;
  certificateData: any;
}

export const vehicalInitialState: VehicalInitialState = {
  error: '',
  isAddVehicle: false,
  isThanksModal: false,
  vehicleDetailModal: false,
  transactionStatus: false,
  vehicalDetail: [],
  vehicleDetailModalData: [],
  certificateData: [],
  imageProgress: [
    {
      category: 'exterior_right',
      percentCompleted: 0,
    },
    {
      category: 'exterior_left',
      percentCompleted: 0,
    },
    {
      category: 'exterior_front',
      percentCompleted: 0,
    },
    {
      category: 'exterior_rear',
      percentCompleted: 0,
    },
    {
      category: 'interior_driver_side',
      percentCompleted: 0,
    },
    {
      category: 'odometer',
      percentCompleted: 0,
    },
    {
      category: 'interior_passenger_side',
      percentCompleted: 0,
    },
    {
      category: 'interior_backseat',
      percentCompleted: 0,
    },
    {
      category: 'driver_seat',
      percentCompleted: 0,
    },
    {
      category: 'passenger_seat',
      percentCompleted: 0,
    },
    {
      category: 'left_front_tire',
      percentCompleted: 0,
    },
    {
      category: 'left_rear_tire',
      percentCompleted: 0,
    },
    {
      category: 'right_front_tire',
      percentCompleted: 0,
    },
    {
      category: 'right_rear_tire',
      percentCompleted: 0,
    },
  ],
};

export default (state = vehicalInitialState, { type, payload }: DispatchAction) => {
  switch (type) {
    case ActionTypes.getVinNumberComplete:
      return {
        ...state,
        vehicalDetail: payload,
        error: '',
      };
    case ActionTypes.addVehicleLoading:
      return {
        ...state,
        isAddVehicle: payload,
      };
    case ActionTypes.setThankYouModalStateValue:
      return {
        ...state,
        isThanksModal: payload,
      };

    case ActionTypes.getVehicleCompaniesComplete:
      return {
        ...state,
        allCompanies: payload,
      };
    case ActionTypes.getGeneralCertificateFinal:
      return { ...state, certificateData: payload };

    case ActionTypes.setTransactionStatusComplete:
      return {
        ...state,
        transactionStatus: payload,
      };
    case ActionTypes.setVehicleDetailModal:
      return {
        ...state,
        vehicleDetailModal: payload,
      };

    case ActionTypes.vehicleDetailModalData:
      return {
        ...state,
        vehicleDetailModalData: payload,
      };

    case ActionTypes.getImageToken: {
      const progressFile = state.imageProgress.find(
        (file: any) => file.category === payload.category,
      );
      if (progressFile) {
        progressFile.percentCompleted = payload.percentCompleted;
        return {
          ...state,
          imageProgress: state.imageProgress,
        };
      }
      return {
        ...state,
        imageProgress: [],
      };
    }
    default:
      return state;
  }
};
