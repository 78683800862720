import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import HomePage from './components/HomePage/HomePage.component';
import RegisterPage from './components/RegisterPage/RegisterPage.component';
import LoginPage from './components/LoginPage/LoginPage.component';
import InstructionsPage from './components/InstructionsPage/InstructionsPage.component';
import FinalPage from './components/FinalPage/FinalPage.component';
// import CapturePage from './components/CapturePage/CapturePage.component';
import ReviewPage from './components/ReviewPage/ReviewPage.component';
import history from './history';
import AddVehiclePage from './components/AddVehiclePage/AddVehiclePage.component';
import VehicleInspection from './components/VehicleInspection/VehicleInspection.component';
import PaymentDetails from './components/PaymentDetails/PaymentDetails.component';
import ThankYou from './components/styles/ThankYou/ThankYou.component';
import DashboardPage from './components/DashboardPage/DashboardPage.component';
import DashboardInspection from './components/DashboardInspection/DashboardInspection.component';
import EmailVerificationPage from './components/EmailVerificationPage/EmailVerificationPage.component';
import ConfirmEmailPage from './components/ConfirmEmailPage/ConfirmEmailPage.component';
// import CheckoutMethods from './components/CheckoutMethods/chekoutMethod.component';
import VehicleDetailAfterPayment from './components/VehicleStatusAfterPayment/vehicleStatusAfterPayment.component';
import TransactionHisory from './components/TranscationHistory/transcationHistory.component';
import VehicleDetails from './components/VehicleDetails/VehicleDetails.component';
// import PrivacyPolicyComponent from './components/RegisterPage/PrivacyPolicy.component';
import DoAndDontComponent from './components/DoAndDont/DoAndDont.component';
import AddVehicleDetailPage from './components/AddVehiclePage/AddVehicleDetailPage.component';
import ForgotPasswordComponent from './components/ForgotPassword/ForgotPassword.component';
import ResetPassword from './components/ResetPassword/ResetPassword.component';
import ContactUs from './components/ContactUsPage/ContactUs';
import GeneralCertificate from './components/GeneralCertificate/GeneralCertificate.component';
import VehicleCertificate from './components/VehicleCertificate/VehicleCertificatre.component';
// import MaintaincePage from './components/MaintancePage/MaintancePage';

const App = () => (
  <>
    <ToastContainer position="top-center" />
    <Router history={history}>
      <Switch>
        {/* <Route path="/" exact>
          <MaintaincePage />
        </Route> */}
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/login" exact>
          <LoginPage />
        </Route>
        <Route path="/register" exact>
          <RegisterPage />
        </Route>
        <Route path="/addVehicle" exact>
          <AddVehiclePage />
        </Route>
        <Route path="/vehicleInspection" exact>
          <VehicleInspection />
        </Route>
        <Route path="/instructions/:id" exact>
          <InstructionsPage />
        </Route>
        {/* <Route path="/capture/:id" exact><CapturePage /></Route> */}
        <Route path="/verifyEmail" exact>
          <EmailVerificationPage />
        </Route>
        <Route path="/confirmEmail" exact>
          <ConfirmEmailPage />
        </Route>
        <Route path="/vehiclestatus" exact>
          <VehicleDetailAfterPayment />
        </Route>
        <Route path="/paymentDetails" exact>
          <PaymentDetails />
        </Route>
        <Route path="/transactionHisory" exact>
          <TransactionHisory />
        </Route>

        <Route path="/contactUs" exact>
          <ContactUs />
        </Route>
        {/* <Route path="/checkoutMethods" exact><CheckoutMethods /></Route> */}
        <Route path="/thankyou" exact>
          <ThankYou />
        </Route>
        <Route path="/review" exact>
          <ReviewPage />
        </Route>
        <Route path="/final" exact>
          <FinalPage />
        </Route>
        <Route path="/dashboard" exact>
          <DashboardPage />
        </Route>
        <Route path="/vehicleDetails" exact>
          <VehicleDetails />
        </Route>
        <Route path="/addVehicleDetail" exact>
          <AddVehicleDetailPage />
        </Route>
        <Route path="/dashboard/inspection" exact>
          <DashboardInspection />
        </Route>
        {/* <Route path="/privacyPolicy" exact>
          <PrivacyPolicyComponent />
        </Route> */}
        <Route path="/doAndDont" exact>
          <DoAndDontComponent />
        </Route>
        <Route path="/forgotPassword" exact>
          <ForgotPasswordComponent />
        </Route>
        <Route path="/resetPassword" exact>
          <ResetPassword />
        </Route>
        <Route path="/generalCertificate" exact>
          <GeneralCertificate />
        </Route>
        <Route path="/vehicleCertificate" exact>
          <VehicleCertificate />
        </Route>
      </Switch>
    </Router>
  </>
);

export default App;
