/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { BsEnvelope } from 'react-icons/bs';
import { RiPhoneLine } from 'react-icons/ri';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LeftSidebarComp from '../LeftSidebar/LeftSidebar.component';

import store from '../../store/index';
import { withAuth } from '../../hocs/withAuth';
import { RootState } from '../../reducers';
import { loginFromToken } from '../../actions/AuthActions';
import { getToken } from '../../api';

import {
  ContentVehicleInspection,
  ContentVehicleInspectionH1,
  VehicleInspectionArrowBack,
  OpenNavSpan,
  ContentVehicleInspectionH1Span,
  VehicleInspectionP,
  MainContainer,
  VehicleDetailMainContainer,
  VehicleDetailContainer,
} from '../TranscationHistory/transcationHistory.styles';

import {
  ContentFooterAreaInsertDetails,
  LoaderContainer,
  InsertDetailsMtB5,
  MainDownContainer,
  OrText,
  DirectlyReactText,
  InsertDetailsMtB6,
  ContactText,
  ContactHeading,
} from './ContactUs.styles';
import { contactUs } from '../../actions/AuthActions';

const ContactUs: FC = () => {
  const dispatch = useDispatch();
  const [showSideBar, setShowSidebar] = useState(false);
  const [msg, setMsg] = useState('');
  const [name, setName] = useState('');

  const addVehicleLoading: any = useSelector((state: RootState) => state.vehicals.isAddVehicle);

  // const mainState = useSelector((state: RootState) => state);
  // const loading: any = mainState.vehicals.isAddVehicle;

  useEffect(() => {
    const token = getToken();
    if (token) store.dispatch(loginFromToken());
  }, []);

  const initialValues = {
    year: '',
    make: '',
    model: '',
  };

  const validationSchema = Yup.object().shape({
    year: Yup.string().required('Year is required!'),
    make: Yup.string().required('Make is required!'),
    model: Yup.string().required('Model is required!'),
  });

  const handleSubmit = () => {
    const object = {
      name: name,
      message: msg,
    };
    dispatch(contactUs(object));
  };

  if (showSideBar) {
    return <LeftSidebarComp handleClose={() => setShowSidebar(false)} />;
  }

  return (
    <MainContainer>
      <ContentVehicleInspection>
        <ContentVehicleInspectionH1>
          <VehicleInspectionArrowBack>
            <OpenNavSpan type="button" onClick={() => setShowSidebar(true)}>
              &#9776;
            </OpenNavSpan>
          </VehicleInspectionArrowBack>

          <ContentVehicleInspectionH1Span />
        </ContentVehicleInspectionH1>
        <VehicleInspectionP />

        <VehicleDetailMainContainer>
          <VehicleDetailContainer>
            <div>
              <MainDownContainer>
                <ContentFooterAreaInsertDetails>
                  {/* <div style={{ textAlign: 'center', fontSize: '18px' }}>Contact Us</div> */}
                  <ContactHeading>Contact Us</ContactHeading>
                  {false ? (
                    <LoaderContainer>
                      <ClipLoader size={50} color="#1A60A6" />
                    </LoaderContainer>
                  ) : (
                    <>
                      <input
                        style={{
                          background: '#e7eef5',
                          border: '1px solid #e7eff8',
                          boxSizing: 'border-box',
                          borderRadius: '3px',
                          width: '100%',
                          margin: '0 auto 20px',
                          padding: ' 15px 20px',
                          fontStyle: 'normal',
                          fontWeight: 'normal',
                          fontSize: '16px',
                          lineHeight: '24px',
                          // -webkit-letter-spacing: 1px;
                          // -moz-letter-spacing: 1px;
                          // -ms-letter-spacing: 1px;
                          letterSpacing: '1px',
                        }}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                        placeholder="Name"
                        name="name"
                      />

                      <textarea
                        onChange={(e) => {
                          setMsg(e.target.value);
                        }}
                        style={{
                          border: 'none',
                          fontSize: '16px',
                          width: '100%',
                          borderRadius: '3px',
                          background: '#E7EEF5',
                          padding: 15,
                        }}
                        placeholder="Your Queries"
                        rows={6}
                      />

                      <InsertDetailsMtB5>
                        <button onClick={handleSubmit} type="submit">
                          {addVehicleLoading ? <ClipLoader size={20} color="#1A60A6" /> : 'Submit'}
                        </button>
                        <div>{/* <RenterText>Re-enter VIN</RenterText> */}</div>
                      </InsertDetailsMtB5>
                    </>
                  )}
                </ContentFooterAreaInsertDetails>
                <OrText>Or</OrText>
                <DirectlyReactText>You can directly reach us at</DirectlyReactText>
                <InsertDetailsMtB6>
                  <button>
                    <div style={{ marginRight: 20 }}>
                      <BsEnvelope color="#FF9500" />
                    </div>
                    <ContactText>support@chex.ai</ContactText>
                  </button>
                </InsertDetailsMtB6>
                <InsertDetailsMtB6>
                  <button>
                    <div style={{ marginRight: 20 }}>
                      <RiPhoneLine color="#FF9500" />
                    </div>
                    <ContactText>(650) 665-9068</ContactText>
                  </button>
                </InsertDetailsMtB6>
              </MainDownContainer>
            </div>
          </VehicleDetailContainer>
        </VehicleDetailMainContainer>
      </ContentVehicleInspection>
    </MainContainer>
  );
};

export default withAuth(ContactUs);
