import styled, { css } from 'styled-components';

export const VideoDescriptionDiv = styled.div`
  padding: 17px 50px;
  display: inline-block;
  position: relative;
`;

export const VideoDescriptionList = styled.ul`
  margin: 0;
  padding: 0;
`;

export const VideoDescriptionListItem = styled.li`
  list-style: none;
  display: block;
  text-decoration: none;
`;

export const VideoDescriptionListItemSpan = styled.span`
  color: #E2D4D4;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
`;

interface VideoPlayButtonProps {
  whiteBorder?: Boolean
}

export const VideoPlayButton = styled.button`
  border: 4px solid #1494BA;
  width: 70px;
  height: 70px;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  ${(p: VideoPlayButtonProps) => p.whiteBorder && css`
    border: 4px solid #FFFFFF;
  `}
`;

interface VideoPlayIconProps {
  whiteIcon?: Boolean
}

export const VideoPlayIcon = styled.i`
  font-size: 20px;
  color: #1494BA;
  padding: 21px 25px;
  ${(p: VideoPlayIconProps) => p.whiteIcon && css`
    color: #FFFFFF;
  `}
`;

export const VideoRecordingArea = styled.div`
  width: 100%;
  background: #F1FDFF;
  border-radius: 10px;
`;

export const VideoReviewDiv = styled.div`
  width: 100%;
  height: 190px;
  background: #BCE0FD;
  position: relative;
  margin-bottom: 15px;
`;

export const VideoScreenDiv = styled.div`
  height: 274px;
  width: 100%;
  display: inline-block;
  background: #000;
  position: relative;
`;
