import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  MainContainer,
  ImageBackgroundSignin,
  MainBgSignin,
  ContentMainScreen,
  ContentFooterAreaSignin,
  LogoSpanColor,
  MainScreenP,
  InputSigninPage,
  ContentMainScreenh5,
  LoginPageH1,
  VerifiMailText,
  VerifiMailTextSpan,
  ResendLink,
  ArrowBack,
  // ArrowBackA,
  VehicleInspectionArrowBack,
  OpenNavSpan,
  SigninHeadingHText,
  BackToLogin,
  BackToLoginText,
  ArrowBackLogin,
  ArrowBackALogin,
} from './EmailVerificationPage.styles';
import { RootState } from '../../reducers';
import { resendEmailLink, logoutUser } from '../../actions/AuthActions';

const EmailVerificationPage = () => {
  const dispatch = useDispatch();
  const user = useSelector<RootState>((state) => state.auth.user) as {
    email: string;
  };
  if (!user || !user.email) return <Redirect to="/login" />;
  const resendEmail = () => dispatch(resendEmailLink(user.email));
  const logout = () => dispatch(logoutUser());

  return (
    <MainContainer>
      <ImageBackgroundSignin>
        <MainBgSignin>
          <ContentMainScreen>
            <ContentMainScreenh5>
              <ArrowBack>
                {/* <ArrowBackA to="/">
                  <i className="fas fa-chevron-left fa-2x" />
                </ArrowBackA> */}
              </ArrowBack>
              <VehicleInspectionArrowBack>
                <OpenNavSpan type="button">&#9776;</OpenNavSpan>
              </VehicleInspectionArrowBack>
            </ContentMainScreenh5>

            <LoginPageH1>
              CHEX
              <LogoSpanColor>.AI</LogoSpanColor>
            </LoginPageH1>
            <MainScreenP>Virtual Inspections</MainScreenP>
          </ContentMainScreen>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '10%',
            }}
          >
            <ContentFooterAreaSignin>
              {/* <SigninHeadingH3>
                <SigninHeadingH3Span>Thanks!</SigninHeadingH3Span>
              </SigninHeadingH3> */}
              {/* <br /> */}
              <SigninHeadingHText>
                Please check your email and verify your account
              </SigninHeadingHText>
              <InputSigninPage>
                <VerifiMailText>
                  <VerifiMailTextSpan>{user.email}</VerifiMailTextSpan>
                </VerifiMailText>
                <ResendLink type="button" onClick={resendEmail}>
                  Resend
                </ResendLink>
              </InputSigninPage>

              <BackToLogin>
                <ArrowBackLogin>
                  <ArrowBackALogin to="/login">
                    <i className="fas fa-chevron-left fa-2x" />
                  </ArrowBackALogin>
                </ArrowBackLogin>

                <BackToLoginText onClick={logout}>Back to Login</BackToLoginText>
              </BackToLogin>
            </ContentFooterAreaSignin>
          </div>
        </MainBgSignin>
      </ImageBackgroundSignin>
    </MainContainer>
  );
};

export default EmailVerificationPage;
