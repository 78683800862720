/* eslint-disable */

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { RootState } from '../../reducers';
import { addVehicle, getVinNumber } from '../../actions/VehicleAction';
import {
  MainContainer,
  ImageBackgroundInsertDetails,
  MainBgInsertDetails,
  HeaderItem,
  ArrowBackA,
  ContentVehicleInspectionH1,
  ContentFooterAreaInsertDetails,
  LoaderContainer,
  VehicalDetailContainer,
  InsertDetailsLabel,
  VehicalDetailDiv,
  InsertDetailsMtB5,
  RenterText,
  MainDownContainer,
} from './AddVehcileDetail.styles';
import TextInput from '../formFields/TextInput';
import { setVehicleDetailModal } from '../../actions/VehicleAction';
import './style.css';

const initialValues = {
  year: '',
  make: '',
  model: '',
};

const validationSchema = Yup.object().shape({
  year: Yup.string().required('Year is required!'),
  make: Yup.string().required('Make is required!'),
  model: Yup.string().required('Model is required!'),
});

const AddVehicleDetailModal = ({
  openModal,
  data,
  closeModal,
  selectedCompanies,
  selectedState,
}: {
  openModal: boolean;
  data: any;
  closeModal: any;
  selectedCompanies: any;
  selectedState: any;
}) => {
  const loading: any = useSelector((state: RootState) => state.auth.loading);
  const addVehicleLoading: any = useSelector((state: RootState) => state.vehicals.isAddVehicle);
  const vehicleDetailModalData: any = useSelector((state: RootState) => state.vehicals.vehicleDetailModalData);
  const dispatch = useDispatch();
  const [detail, setDetail] = useState({
    year: '',
    make: '',
    model: '',
    engine: '',
    Manufacturer: '',
  });

  useEffect(() => {
    console.log('useEffect', vehicleDetailModalData);
    if (Object.keys(vehicleDetailModalData).length !== 0) {
      const object = { ...detail };
      vehicleDetailModalData?.data.map((i: any) => {
        if (i.label === 'Model Year') {
          object.year = i.value;
        } else if (i.label === 'Make') {
          object.make = i.value;
        } else if (i.label === 'Model') {
          object.model = i.value;
        } else if (i.label === 'Manufacturer') {
          object.Manufacturer = i.value;
        } else if (i.label === 'Engine Displacement (ccm)') {
          object.engine = i.value;
        }
        setDetail(object);
      });
    }
  }, []);

  const addVehicleData = () => {
    const { make, year, model, engine } = detail;
    const vehicleData: object = {
      vin: data.vin,
      license_plate_no: data.licensePlateNo,
      vehicle_mileage: data.vehicle_mileage,
      make,
      model,
      color: data.color,
      year,
      engine: engine.toString(),
      // Manufacturer,
      companies: selectedCompanies,
      registration_state: selectedState,
    };
    console.log('vehicleData', vehicleData);
    dispatch(addVehicle(vehicleData));
    // dispatch(setVehicleDetailModal(false));
  };

  const addManualData = () => {
    // const { make, year, model, engine, trim, transmission, manufacturer } = showVehicalValues;
    // console.log('vehicleData', vehicleData);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values: any) => {
      const data1 = {
        vin: data.vin,
        license_plate_no: data.licensePlateNo,
        color: data.color,
        vehicle_mileage: data.vehicle_mileage,
        year: values.year,
        make: values.make,
        model: values.model,
        // manufacturer: values.manufacturer,
        // engine: values.engine,
        // trim: values.trim,
        // transmission: values.transmission,
        companies: selectedCompanies,
        registration_state: selectedState,
      };
      // setVehicalValues(data1);
      console.log('data', data1);
      dispatch(addVehicle(data1));
      // dispatch(setVehicleDetailModal(false));
    },
  });

  const handleReEnter = () => {
    dispatch(setVehicleDetailModal(false));
  };

  return (
    <Modal className="modal-styling" isOpen={openModal}>
      <MainContainer>
        <ImageBackgroundInsertDetails>
          <MainBgInsertDetails>
            {Object.keys(vehicleDetailModalData).length !== 0 ? (
              <>
                <HeaderItem>
                  <ArrowBackA>{/* <i className="fas fa-chevron-left fa-2x" color="#2584E0" /> */}</ArrowBackA>
                  <ContentVehicleInspectionH1>Vehicle Details</ContentVehicleInspectionH1>
                </HeaderItem>
                <MainDownContainer>
                  <ContentFooterAreaInsertDetails>
                    {loading ? (
                      <LoaderContainer>
                        <ClipLoader size={50} color="#1A60A6" loading />
                      </LoaderContainer>
                    ) : (
                      <>
                        <VehicalDetailContainer>
                          <InsertDetailsLabel>Year</InsertDetailsLabel>
                          <VehicalDetailDiv>{detail.year}</VehicalDetailDiv>
                        </VehicalDetailContainer>
                        <VehicalDetailContainer>
                          <InsertDetailsLabel>Make</InsertDetailsLabel>
                          <VehicalDetailDiv>{detail.make}</VehicalDetailDiv>
                        </VehicalDetailContainer>
                        <VehicalDetailContainer>
                          <InsertDetailsLabel>Model</InsertDetailsLabel>
                          <VehicalDetailDiv>{detail.model}</VehicalDetailDiv>
                        </VehicalDetailContainer>
                        <VehicalDetailContainer>
                          <InsertDetailsLabel>Color</InsertDetailsLabel>
                          <VehicalDetailDiv>{data.color}</VehicalDetailDiv>
                        </VehicalDetailContainer>
                        {/* <VehicalDetailContainer>
                        <InsertDetailsLabel>Engine</InsertDetailsLabel>
                        <VehicalDetailDiv>{detail.engine}</VehicalDetailDiv>
                      </VehicalDetailContainer> */}
                        {/* <VehicalDetailContainer>
                        <InsertDetailsLabel>Trim</InsertDetailsLabel>
                        <VehicalDetailDiv>{detail.trim}</VehicalDetailDiv>
                      </VehicalDetailContainer>
                      <VehicalDetailContainer>
                        <InsertDetailsLabel>Transmission</InsertDetailsLabel>
                        <VehicalDetailDiv>{detail.transmission}</VehicalDetailDiv>
                      </VehicalDetailContainer> */}
                        <InsertDetailsMtB5>
                          <button onClick={addVehicleData} type="submit">
                            {addVehicleLoading ? <ClipLoader size={20} color="#1A60A6" loading /> : 'Submit'}
                          </button>
                          <div>
                            <RenterText onClick={handleReEnter}>Re-enter VIN</RenterText>
                          </div>
                        </InsertDetailsMtB5>
                      </>
                    )}
                  </ContentFooterAreaInsertDetails>
                </MainDownContainer>
              </>
            ) : (
              <form onSubmit={formik.handleSubmit}>
                <HeaderItem>
                  <ArrowBackA>{/* <i className="fas fa-chevron-left fa-2x" color="#2584E0" /> */}</ArrowBackA>
                  <ContentVehicleInspectionH1>Vehicle Details</ContentVehicleInspectionH1>
                </HeaderItem>
                <MainDownContainer>
                  <ContentFooterAreaInsertDetails>
                    {loading ? (
                      <LoaderContainer>
                        <ClipLoader size={50} color="#1A60A6" loading />
                      </LoaderContainer>
                    ) : (
                      <>
                        <VehicalDetailContainer>
                          <InsertDetailsLabel>Year</InsertDetailsLabel>
                          <TextInput type="number" formik={formik} name="year" label="" />
                        </VehicalDetailContainer>
                        <VehicalDetailContainer>
                          <InsertDetailsLabel>Make</InsertDetailsLabel>
                          <TextInput formik={formik} name="make" label="" />
                        </VehicalDetailContainer>
                        <VehicalDetailContainer>
                          <InsertDetailsLabel>Model</InsertDetailsLabel>
                          <TextInput formik={formik} name="model" label="" />
                        </VehicalDetailContainer>
                        {/* <VehicalDetailContainer>
                        <InsertDetailsLabel>Manufacturer</InsertDetailsLabel>
                        <TextInput formik={formik} name="manufacturer" label="" />
                      </VehicalDetailContainer>
                      <VehicalDetailContainer>
                        <InsertDetailsLabel>Engine</InsertDetailsLabel>
                        <TextInput formik={formik} name="engine" label="" />
                      </VehicalDetailContainer>
                      <VehicalDetailContainer>
                        <InsertDetailsLabel>Trim</InsertDetailsLabel>
                        <TextInput formik={formik} name="trim" label="" />
                      </VehicalDetailContainer>
                      <VehicalDetailContainer>
                        <InsertDetailsLabel>Transmission</InsertDetailsLabel>
                        <TextInput formik={formik} name="transmission" label="" />
                      </VehicalDetailContainer> */}
                        <InsertDetailsMtB5>
                          <button onClick={addManualData} type="submit">
                            {addVehicleLoading ? <ClipLoader size={20} color="#1A60A6" loading /> : 'Submit'}
                          </button>
                          <div>
                            <RenterText onClick={handleReEnter}>Re-enter VIN</RenterText>
                          </div>
                        </InsertDetailsMtB5>
                      </>
                    )}
                  </ContentFooterAreaInsertDetails>
                </MainDownContainer>
              </form>
            )}
          </MainBgInsertDetails>
        </ImageBackgroundInsertDetails>
      </MainContainer>
    </Modal>
  );
};

export default AddVehicleDetailModal;
