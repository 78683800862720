import { Action } from 'redux';
import { ActionTypes } from '../actions/ActionTypes';

export interface DispatchAction extends Action {
  payload: Partial<AuthInitialState>;
}

export interface FileObj {
  id: number;
  category: string;
  url: string;
  vehicle_id: number;
}

interface VehicleObj {
  id: number;
  user_id: number;
  vin: string;
  license_plate_no: string;
  make: string;
  model: string;
  year: number;
  color: string;
  review_status: string;
  created_at: string;
  updated_at: string;
}

interface UserObj {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone?: string;
  dl_number: string;
  created_at: string;
  updated_at: string;
  vehicles: VehicleObj[];
  files: FileObj[];
}

interface ForgetPasswordObj {
  email: string;
}

interface ResetPasswordObj {
  password: string;
  confirm_password: string;
}

export interface AuthInitialState {
  error: string;
  user: UserObj;
  loading: boolean;
  transactionHistory: object;
  forgetPassword: ForgetPasswordObj;
  resetPassword: ResetPasswordObj;
}

export const authInitialState: AuthInitialState = {
  error: '',
  user: null,
  loading: false,
  transactionHistory: {},
  forgetPassword: null,
  resetPassword: null,
};

export default (state = authInitialState, { type, payload }: DispatchAction) => {
  switch (type) {
    case ActionTypes.LoginUserEnd:
      return {
        ...state,
        user: payload,
        error: '',
      };
    case ActionTypes.ForgetPasswordEnd:
      console.log('reducer', payload);
      return {
        ...state,
        forgetPassword: payload,
        error: '',
      };
    case ActionTypes.RestPasswordEnd:
      return {
        ...state,
        resetPassword: payload,
        error: '',
      };
    case ActionTypes.Loading:
      return { ...state, loading: payload };
    case ActionTypes.LogoutUserEnd:
      return { ...state, user: null, error: '' };
    case ActionTypes.AuthFAIL:
      return { ...state, error: payload || '' };

    case ActionTypes.setTransactionHistory:
      return { ...state, transactionHistory: payload || '' };
    case ActionTypes.UploadFileEnd:
      return {
        ...state,
        user: state.user
          ? {
            ...state.user,
            files: state.user.files ? [...state.user.files, payload] : [payload],
          }
          : null,
      };
    case ActionTypes.deleteFileEnd: {
      const { fileId } = payload as any;
      const files = state.user ? state.user.files.filter((file) => file.id !== fileId) : [];
      return {
        ...state,
        user: state.user ? { ...state.user, files } : state.user,
      };
    }
    default:
      return state;
  }
};
