import styled from 'styled-components';

export const ContentVehicleInspectionH1 = styled.h1`
  margin: 0px;
  padding-top: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 25px;
  text-align: center;
  color: white;
`;

export const ContentVehicleInspection = styled.div`
  width: 100%;
  background: #1b68b3;
`;

export const VehicleInspectionArrowBack = styled.span`
  color: #2584e0;
  position: absolute;
  left: 30px;
  top: 30px;
  font-size: 14px;
`;

export const OpenNavSpan = styled.button`
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: 0;
  color: #2584e0;
`;

export const ContentVehicleInspectionH1Span = styled.span`
  color: #ff9900;
`;

export const VehicleInspectionP = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #798696;
  margin: 20px auto;
  width: 50%;
`;

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const LinkSignInBtn = styled.div`
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 14px 104px 15px;
  text-decoration: none;

  :focus,
  :active {
    outline: none;
    box-shadow: 0 0 0 0 !important;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const VehicleDetailMainContainer = styled.div`
  // padding-left: 20px;
  // padding-right: 20px;
  margin-top: 70px;
`;

export const VehicleDetailContainer = styled.div`
  // display: flex;
  padding-bottom: 10px;
  margin-top: 150px;
`;
