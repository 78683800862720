import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ThankYouContainer = styled.div`
  width: 100%;
`;

export const ImageBackgroundThankYou = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 35%;
  top: 0px;
  background: url(../images/finalThanksImage.png);
  background-repeat: no-repeat;
  // background-size: 100%;
  @media screen and (min-width: 400px) {
    .ImageBackgroundThankYou {
      background-size: 220%;
    }
  }

  @media screen and (max-width: 600px) {
    background-size: 100%;
    left: 0px;
  }
`;

export const ThankYouBg = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const ContentThankYou = styled.div`
  width: 30%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const ContentThankYouH5 = styled.h1`
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 46px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding-bottom: 0px;
  margin: 0px 0 36px;
`;

export const ContentThankYouSpan = styled.span`
  font-size: 24px;
`;

export const ContentThankYouP = styled.p`
  font-style: normal;
  font-weight: 200;
  font-size: 11px;
  line-height: 27px;
  letter-spacing: 0.18em;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
  margin: 0px;
`;

export const ContentFooterAreaThankYou = styled.div`
  text-align: center;
  position: absolute;
  margin: 0 0 0 20px;
  width: 90%;
  bottom: 56px;
`;

export const SigninBtnThankYou = styled(Link)`
  background: -webkit-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  background: -webkit-linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  background: -moz-linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 14px 104px 15px;
`;

export const ContentFooterAreaThankYouMtB5 = styled.div`
  margin-bottom: 0px;
  margin-top: 60px;
  width: 30%;
  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
