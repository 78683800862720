/* eslint-disable */

import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { RootState } from '../../reducers';
import { registerUser } from '../../actions/AuthActions';
import { PrivacyPage } from './PrivacyPolicy.component';
import TextInput from '../formFields/TextInput';
import { withoutAuth } from '../../hocs/withoutAuth';
import {
  MainContainer,
  ImageBackgroundSignin,
  MainBgSignin,
  ContentMainScreen,
  ArrowBack,
  ContentFooterAreaSignin,
  SigninHeadingH3,
  LogoSpanColor,
  MainScreenP,
  InputSigninPage,
  ContentMainScreenh5,
  ArrowBackA,
  MtB5,
  LinkSignInBtn,
  LoginPageH1,
  PrivacyLabel,
  CheckMark,
  TermsUsetext,
} from './RegisterPage.styles';

const validationSchema = Yup.object({}).shape({
  name: Yup.string().required('Name is required!'),
  password: Yup.string().required('Password is required!'),
  email: Yup.string().email('Invalid email!').required('Email is required!'),
  phone: Yup.string().required('phone number is required!'),
  driving_license: Yup.string().required('Driving License is required'),
});

const initialValues = {
  name: '',
  password: '',
  email: '',
  driving_license: '',
  last_name: '',
  phone: '',
};

const RegisterPage: FC = () => {
  const [showTermsCheck, setTermsCheck] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const loading: any = useSelector((state: RootState) => state.auth.loading);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (showTermsCheck) {
        console.log('values', values);
        dispatch(registerUser(values));
      } else {
        toast('Please accept terms and conditions before register', { type: 'error' });
      }
    },
  });

  const handleTermsCondition = () => {
    setTermsCheck(!showTermsCheck);
  };

  const handlePrivacyPolicy = () => {
    // window.open('/privacyPolicy', '_blank');
    setShowModal(true);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContainer>
        <ImageBackgroundSignin>
          <MainBgSignin>
            <ContentMainScreen>
              <ContentMainScreenh5>
                <ArrowBack>
                  <ArrowBackA to="/">
                    <i className="fas fa-chevron-left fa-2x" />
                  </ArrowBackA>
                </ArrowBack>
              </ContentMainScreenh5>
              <LoginPageH1>
                CHEX
                <LogoSpanColor>.AI</LogoSpanColor>
              </LoginPageH1>
              <MainScreenP>Virtual Inspections</MainScreenP>
            </ContentMainScreen>
            <ContentFooterAreaSignin>
              <SigninHeadingH3>Register</SigninHeadingH3>
              <InputSigninPage>
                <TextInput formik={formik} name="name" label="First Name" />
                <TextInput formik={formik} name="last_name" label="Last Name" />
                <TextInput formik={formik} name="email" label="Email" />
                <TextInput formik={formik} name="phone" label="Phone No" />
                <TextInput formik={formik} type="password" name="password" label="Password" />
                <TextInput formik={formik} name="driving_license" label="Driver License Number" />
                <PrivacyLabel style={{ paddingLeft: '38px' }}>
                  I accept{' '}
                  <TermsUsetext onClick={handlePrivacyPolicy}>
                    <u>terms of use</u>
                  </TermsUsetext>
                  <input checked={showTermsCheck} onClick={handleTermsCondition} type="checkbox" />
                  <CheckMark className="checkmark" />
                </PrivacyLabel>
              </InputSigninPage>

              <MtB5>
                <LinkSignInBtn disabled={loading} type="submit">
                  {loading ? <ClipLoader size={20} color="#1A60A6" loading={loading} /> : 'Register'}
                </LinkSignInBtn>
              </MtB5>
            </ContentFooterAreaSignin>
          </MainBgSignin>
        </ImageBackgroundSignin>
        {showModal ? <PrivacyPage showModal={showModal} closeModal={() => setShowModal(false)} /> : null}
      </MainContainer>
    </form>
  );
};

export default withoutAuth(RegisterPage);
