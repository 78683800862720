import styled from 'styled-components';

export const InstructionsDiv = styled.div`
  margin: 75px 0px 0px 0px;
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const InstructionsTitleDiv = styled.div`
  padding: 0px 50px;
`;

export const InstructionsTitleH1 = styled.h1`
  color: #E2D4D4;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  font-family: 'Conv_Georgia Regular font';
  font-weight: 400;
  padding: 0px 0px 12px 0px;
`;
