/* eslint-disable */

import React from 'react';
import { FormikProps, FormikValues } from 'formik';
import { InputDiv, Input, InputLabel } from './styles';

interface TextInputProps {
  type?: string;
  name: string;
  label: string;
  style?: any;
  formik: FormikProps<FormikValues>;
}

const TextInput = ({ name, label, formik, type = 'text', style }: TextInputProps) => {
  const invalid = formik.touched[name] && !!formik.errors[name];
  // console.log(formik.values);
  return (
    <InputDiv style={style}>
      {invalid && <InputLabel invalid={invalid}>{formik.errors[name]}</InputLabel>}
      <Input type={type} name={name} placeholder={label} value={formik.values[name]} onChange={formik.handleChange} onBlur={formik.handleBlur} invalid={invalid} />
    </InputDiv>
  );
};

export default TextInput;
TextInput.defaultProps = {
  type: 'input',
};
