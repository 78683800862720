import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const MainDownContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  background: #1b68b3;
  // background: linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%);
  // background: 'linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%)';
  background-repeat: no-repeat;
  width: 100%;
  background-size: 205%;
  background-position: center top;

  @media screen and (max-width: 700px) {
    background: none;
    margin-top: 0px;
    width: 100%;
    height: 100vh;
  }
`;

export const ImageBackgroundInsertDetails = styled.div`
  position: absolute;
  width: 100%;
  height: 72vh;
  left: 0px;
  top: 0px;
  background: url(../images/vin_pass_img.png);
  background-repeat: no-repeat;
  background-size: 135%;
  background-position: center top;
  @media screen and (min-width: 700px) {
    height: 100%;
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;
export const CancelButton = styled.div`
  // float: right;
  cursor: pointer;
  font-size: 17px;
  position: absolute;
  top: 15px;
  right: 20px;
`;

export const MainBgInsertDetails = styled.div`
  background: linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%);
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const ContentInsertDetails = styled.div`
  width: 100%;
`;

export const InsertDetailsH5 = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 8px;
  color: #ffffff;
  margin: 39px 0;
`;

export const ArrowBack = styled.span`
  color: #fff;
  a {
    color: #fff;
  }
`;

export const ArrowBackA = styled.span`
  color: #2584e0;
`;

export const VehicalDetailDiv = styled.div`
  padding-left: 10px;
  background: rgb(228, 226, 226);
  padding-top: 13px;
  padding-bottom: 13px;
`;

export const VehicalDetailContainer = styled.div`
  margin-bottom: 13px;
`;

export const ArrowBackA2 = styled(Link)`
  color: #2584e0;
`;

export const InsertDetailsP = styled.p`
  color: #4a93e9;
  text-align: center;
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;

export const ContentFooterAreaInsertDetails = styled.div`
  background: #fff;
  border-radius: 30px 30px 0 0;
  margin-top: 70px;
  height: 100%;
  padding: 30px 30px;
  width: 30%;
  @media screen and (max-width: 880px) {
    width: 100%;
  }
`;

export const InputInsertDetailsPage = styled.div`
  width: 100%;

  .color-select {
    width: 100%;
  }
  .color-select .ant-select-selector {
    background: #e7eef5;
    height: 50px;
  }

  .color-select .ant-select-selection-item {
    padding: 9px 0;
  }

  .color-select .ant-select-arrow {
    color: #2584e0;
    font-size: 14px;
  }

  .ant-select-clear {
    right: 30px !important;
  }

  .ant-select-selector {
    background: #e7eff8;
    border: none;
  }

  .ant-select-selection-placeholder {
    color: #889bad;
  }
`;

export const MobileViewWarningContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MobileViewWarning = styled.div`
  text-align: center;
  color: white;
  background-color: red;
  padding: 3px;
  border-radius: 6px;
`;

export const BoldSpan = styled.span`
  font-weight: bold;
`;

// export const InputField = styled.input`
//   background: #e7eef5;
//   border: 1px solid #e7eff8;
//   box-sizing: border-box;
//   border-radius: 3px;
//   width: 100%;
//   margin: 0 auto 20px;
//   padding: 15px 20px;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 24px;
//   color: #889bad;
// `;

// export const InsertDetailsInputFile = styled.div`
//   position: relative;
//   display: flex;
//   padding-left: 13px !important;
//   align-items: center;
//   color: #7f7f7f;
//   height: 50px;
//   text-align: right;
//   background: #e7eef5;
//   border: 1px solid #e7eff8;
//   box-sizing: border-box;
//   border-radius: 3px;
//   width: 100%;
//   margin: 0 auto 20px;
//   padding: 0px 0px 0;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 16px;
//   line-height: 24px;
//   color: #889bad;
//   cursor: pointer;

//   span{
//     position: absolute;
//     top: -3px;
//     right: -10px;
//     opacity: 1;
//     padding: 18px 30px 0;
//     z-index: 999;
//   }

// input{
//   margin:0 auto 0;
// }
// `;

export const InsertDetailsInputFile = styled.div`
  position: relative;
  color: #7f7f7f;
  text-align: right;
  // background: #e7eef5;
  // border: 1px solid #e7eff8;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  margin: 0 auto 20px;
  padding: 0px 0px 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #889bad;

  span {
    position: absolute;
    top: -3px;
    right: -10px;
    opacity: 1;
    padding: 18px 30px 0;
    z-index: 999;
  }
  input {
    margin: 0 auto 0;
  }
`;

export const InsertDetailsLabel = styled.label`
  padding-bottom: 8px;
  padding-left: 10px;
  display: block;
  color: #889bad;
`;

export const VehicleDetailHeading = styled.h4`
  text-align: center;
  display: block;
  margin: 10px 0;
`;

export const InsertDetailsMtB5 = styled.div`
  margin-top: 48px;
  text-align: center;
  margin-bottom: 0px;

  button {
    background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
    border-radius: 100px;
    border: 1px solid #ff7400;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    padding: 14px 40px;
    text-decoration: none;
    margin-bottom: 20px;
    width: 100%;

    :focus {
      outline: none;
      box-shadow: 0;
    }
  }
`;

export const InsertDetailsSigninBtn = styled(Link)`
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 14px 104px 15px;
  text-decoration: none;
`;

export const OpenNavSpan = styled.button`
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: 0;
  color: white;
`;

export const OcrFooter = styled.div`
  position: absolute;
  bottom: 0%;
  left: 0;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #000000;
  z-index: 200;
`;

export const FooterTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OrangeTextOcr = styled.p`
  color: #ff7a00;
  font-size: 18px;
  font-family: Poppins;
  line-height: 17px;
  text-align: center;
  align-self: center;
`;

export const OcrWhiteText = styled.p`
  color: #ffffff;
`;

export const CameraImageContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const RedOcrtext = styled.span`
  color: #f55858;
  margin-left: 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  font-family: 'Poppins';
`;

export const HeaderItems = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
`;
