/* eslint-disable */


// export const apiUrl = 'https://5n14sx6x96.execute-api.us-east-1.amazonaws.com/production';
// export const bucketName = 'chex-backend-prod';
// export const isDevEnv = process.env.NODE_ENV === 'development';
// export const stripeKey = 'pk_live_51H4FwoAHhppDeqJkEWYP9zUaZZ8w6VellRjOdAepw0VArMngRiuZGSDxozQF62tzdDKPxwtLYS1XxlGmiVysercD00RbBqs9L4';
// export const vinUrl = 'http://api.carmd.com/v3.0/decode?vin=';
// export const vinAuthKey = 'Basic YmE1Yzk5MzQtYTljMy00MDgyLThmNmQtMGZiMzc1MGJkYmFh';
// export const vinPartnerKey = 'af03b7bff37341818df76a7c2b3e9eb4';
// export const s3Link = 'http://chex-backend-prod.s3.amazonaws.com';
// // constants
// export const jwtTokenKey = 'chexToken';
// export const TOTAL_STEPS_COUNT = 5;

export const apiUrl = 'https://4ptrwx26u2.execute-api.us-east-1.amazonaws.com/development';
export const bucketName = process.env.AWS_S3_BUCKET_NAME;
export const isDevEnv = process.env.NODE_ENV === 'development';
export const stripeKey = process.env.STRIPE_PUBLIC_KEY;
export const vinUrl = process.env.VIN_URL;
export const vinAuthKey = process.env.VIN_AUTH_KEY;
export const vinPartnerKey = process.env.VIN_PARTNER_KEY;
export const s3Link = process.env.AWS_S3_LINK;

// constants
export const jwtTokenKey = 'chexToken';
export const TOTAL_STEPS_COUNT = 5;
