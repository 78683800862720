import React from 'react';
import {
  TopBar,
  TopBarTitle,
  TopbarSearch,
  TopbarNotifications,
  Dropbtn,
  Dropdown,
  DropdownContent,
  DropdownContentLink,
} from './DashboardPage.styles';

const DashboardTopBarComp = () => (
  <TopBar>
    <TopBarTitle>
      <h1>Dashboard</h1>
    </TopBarTitle>
    <TopbarSearch>
      <input type="text" id="myInput" placeholder="Search" />
    </TopbarSearch>
    <TopbarNotifications>
      <Dropdown>
        <Dropbtn>
          <i className="fas fa-sort-down" />
        </Dropbtn>
        <DropdownContent>
          <DropdownContentLink to="/">Link 1</DropdownContentLink>
          <DropdownContentLink to="/">Link 2</DropdownContentLink>
          <DropdownContentLink to="/">Link 3</DropdownContentLink>
        </DropdownContent>
      </Dropdown>
      <img
        src="https://via.placeholder.com/150?text=Avatar+Placeholder"
        alt=""
      />
      <h3>
        James smith
        <br />
        <span>James smith</span>
      </h3>
    </TopbarNotifications>
  </TopBar>
);
export default DashboardTopBarComp;
