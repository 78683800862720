import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import store from './store/index';
import App from './App';
import { getToken } from './api';
import { loginFromToken } from './actions/AuthActions';
import { stripeKey } from './config';
// import 'bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
// import '../node_modules/antd/dist/antd.css';
import '../node_modules/antd/dist/antd.css';

const token = getToken();
if (token) store.dispatch(loginFromToken());
const stripePromise = loadStripe(stripeKey);

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <App />
    </Provider>
  </Elements>,
  document.getElementById('app'),
);

if ((module as any).hot) {
  (module as any).hot.accept();
}
