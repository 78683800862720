import React from 'react';
import {
  ContentTable,
  ColHead,
  ColHead1,
  ColHead2,
  ColHead3,
  ColHead4,
  ColHead5,
  ColBody,
  ColBody1,
  ColBody2,
  ColBody3,
  ColBody4,
  ColBody5,
  ColBody6,
} from '../DashboardComponent/DashboardPage.styles';

const DashboardContentComp = () => (
  <ContentTable>
    <ColHead>
      <ColHead1>ID #</ColHead1>
      <ColHead2>Requestor</ColHead2>
      <ColHead3>Requestor date</ColHead3>
      <ColHead4>Review link</ColHead4>
      <ColHead5>Status</ColHead5>
    </ColHead>
    <ColBody>
      <ColBody1>20-0001</ColBody1>
      <ColBody2>Uber</ColBody2>
      <ColBody3>05.14.2020 16:00pm</ColBody3>
      <ColBody4>
        <a href="inspection.html">
          <i className="fas fa-file-invoice" />
          File.01.20_001
        </a>
      </ColBody4>
      <ColBody5>Pending Review</ColBody5>
      <ColBody6>
        <i className="fas fa-circle" />
      </ColBody6>
    </ColBody>

    <ColBody>
      <ColBody1>20-0001</ColBody1>
      <ColBody2>Uber</ColBody2>
      <ColBody3>05.14.2020 16:00pm</ColBody3>
      <ColBody4>
        <a href="inspection.html">
          <i className="fas fa-file-invoice" />
          File.01.20_001
        </a>
      </ColBody4>
      <ColBody5>Pending Review</ColBody5>
      <ColBody6>
        <i className="fas fa-circle" />
      </ColBody6>
    </ColBody>
  </ContentTable>
);
export default DashboardContentComp;
