import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const PrimaryLinkButton = styled(Link)`
  background: #2699FB;
  width: 100%;
  display: block;
  border-radius: 4px;
  padding: 14px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
`;

export const PrimaryButton = styled.button`
  background: #2699FB;
  width: 100%;
  display: block;
  border-radius: 4px;
  padding: 14px;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
`;

export const NextButtonLink = styled(Link)`
  float: right;
  background: #2699FB;
  width: 91px;
  height: 51px;
  vertical-align: middle;
  color: #fff;
  text-align: center;
  display: inline-block;
  padding: 17px;
  margin-top: 35px;
`;

export const NextButton = styled.button`
  float: right;
  background: #2699FB;
  width: 91px;
  height: 51px;
  vertical-align: middle;
  color: #fff;
  text-align: center;
  display: inline-block;
  padding: 17px;
  margin-top: 35px;

  ${(props) => props.disabled && css`
    background-color: #67b7fc;
  `}
`;

export const RecordButton = styled.button`
  background: #EC2E2E;
  width: 66px;
  height: 66px;
  border-radius: 100%;
  border: 1px solid #707070;
  box-shadow: 0px 0px 0px 2px #000;
`;

export const ErrorP = styled.p`
  font-size: 14px;
  color: red;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  background-color: #fff;
`;
