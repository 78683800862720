import styled from 'styled-components';

export const ContentVehicleInspectionH1 = styled.h1`
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 25px;
  text-align: center;
  color: #ff7a00;
  margin: 0 auto;

  span {
    color: #1262b1;
  }
`;

export const CollapseContainer = styled.div`
  width: 30%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const ImageBackgroundInsertDetails = styled.div`
  position: absolute;
  width: 100%;
  height: 72vh;
  left: 0px;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 135%;
  background-position: center top;
`;

export const MainBgInsertDetails = styled.div`
  background: #e5e5e5;
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const ContentVehicleInspection = styled.div`
  width: 100%;
`;

export const VehicleInspectionArrowBack = styled.span`
  color: #2584e0;
  position: absolute;
  left: 30px;
  top: 30px;
  font-size: 14px;
`;

export const OpenNavSpan = styled.button`
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: 0;
  color: #1362b1;
`;

export const ContentVehicleInspectionH1Span = styled.span`
  color: #ff9900;
`;

export const VehicleInspectionP = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #798696;
  margin: 20px auto;
  width: 50%;
`;

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const LinkSignInBtn = styled.div`
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 14px 104px 15px;
  text-decoration: none;

  :focus,
  :active {
    outline: none;
    box-shadow: 0 0 0 0 !important;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export const VehicleDetailMainContainer = styled.div`
  height: 100%;
  background: #e5e5e5;
  margin-top: 30px;
  margin-top: 45px;
  padding-left: 15px;
  padding-right: 15px;

  .ant-collapse-item {
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
  }

  .ant-collapse-content-box {
    padding: 0px 16px 16px 16px;
  }
`;

export const VehicleDetailContainer = styled.div`
  display: flex;
  padding-bottom: 10px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

export const Images = styled.div`
  flex: 0 0 100%;
  margin-bottom: 0px;

  position: relative;
  background-position: center;
`;

export const VehicleModalImage = styled.img`
  border-radius: 4px;
  width: 100%;
  height: 189px;
`;

export const VehicleModalVideo = styled.video`
  border-radius: 4px;
  width: 100%;
  height: 150px;
`;

export const CrossVideoIcon = styled.div`
  position: absolute;
  width: 0 !important;
  z-index: 1000;
  right: 18px;
  top: 5px;
  font-weight: bold;
  color: red;
  cursor: pointer;
`;

export const VehicleModalText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  color: #2a2a2a;
  margin-top: 10px;
  margin-left: 10px;
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 5.5%;
  right: 9%;
`;

export const CameraImage = styled.div`
  position: absolute;
  top: 7%;
  left: 8%;
`;

export const SmalImage = styled.img`
  height: 18px;
  width: 22px;
`;

export const VehicleModalImageOpen = styled.img`
  height: 350px;
  border: 2px solid #575757;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 2px;
  background-position: center;
  background-repeat: no-repeat;
`;

export const VehicleModalTextArea = styled.textarea`
  height: 100px;
  width: 100%;
  margin-top: 10px;
  background: #f5f5f5;
  padding: 10px;
  color: #333333;
  font-size: 14px;
  border-color: #b0b0b0;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: #969696;
  }
`;

export const VehicleModalVideoOpen = styled.video`
  border-radius: 4px;
  width: 100%;
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

export const ApprovedImages = styled.div`
  margin-top: 40px;
`;

export const GrayTextHeader = styled.h4`
  color: #888888;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;

export const Reviewtext = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #1468ba;
`;

export const DaysText = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;

  color: #ff7a00;
`;

export const CommentContainer = styled.div`
  padding: 10px 10px 5px 10px;
  background: #F1F5FC;
  border-radius: 3px;
  position: relative;
  margin-top: 20px;
  margin-bottom 20px;
`;

export const UpArrow = styled.div`
  background: #f1f5fc;
  position: absolute;
  left: 10px;
  top: -15px;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  width: 24px;
  height: 18px;
`;
