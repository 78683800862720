import { ActionTypes } from './ActionTypes';

export const addVehicle = (values: object) => ({
  type: ActionTypes.AddVehicleStart,
  values,
});

export const getVinNumber = (values: any) => ({
  type: ActionTypes.getVinNumber,
  values,
});

export const vehicleStatusChanged = (values: any) => ({
  type: ActionTypes.vehicleStatusChanged,
  values,
});

export const skipPaymentStatusChanged = (values: any) => ({
  type: ActionTypes.skipPaymentMethod,
  values,
});

export const getVinNumberComplete = (payload: Object) => ({
  type: ActionTypes.getVinNumberComplete,
  payload,
});

export const vehicleDetailModalData = (payload: any) => ({
  type: ActionTypes.vehicleDetailModalData,
  payload,
});

export const setVehicleDetailModal = (payload: boolean) => ({
  type: ActionTypes.setVehicleDetailModal,
  payload,
});

export const getVehicleTransactionStatus = (payload: any) => ({
  type: ActionTypes.getVehicleTransactionStatus,
  payload,
});

export const setTransactionStatus = (payload: any) => ({
  type: ActionTypes.setTransactionStatusComplete,
  payload,
});

export const setAddVehicleLoading = (payload: boolean) => ({
  type: ActionTypes.addVehicleLoading,
  payload,
});

export const setThankYouModal = (payload: boolean) => ({
  type: ActionTypes.setThankYouModalValue,
  payload,
});

export const setThankYouModalState = (payload: boolean) => ({
  type: ActionTypes.setThankYouModalStateValue,
  payload,
});

export const getVehcicleCompanies = () => ({
  type: ActionTypes.getVehicleCompanies,
});
export const getVehcicleCompaniesComplete = (payload: any) => ({
  type: ActionTypes.getVehicleCompaniesComplete,
  payload,
});

export const getReportDataComplete = (payload: any) => ({
  type: ActionTypes.getGeneralCertificate,
  payload,
});

export const certificateData = (payload: any) => ({
  type: ActionTypes.getGeneralCertificateFinal,
  payload,
});

export const imageToken = (payload: any) => ({
  type: ActionTypes.getImageToken,
  payload,
});
