import { AxiosResponse } from 'axios';
import api from '.';

export const createPayment = (values: any): Promise<AxiosResponse> => (
  api.post(`/api/v1/transactions/${values.vechileId}`, values)
);

export const getUserTransactions = (values: any): Promise<AxiosResponse> => (
  api.get(`/api/v1/transactions/user?page=${values.page}&pageSize=${values.pageSize}`)
);
