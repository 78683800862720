import React, { FC, ReactElement } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../reducers';

interface State {
  auth: {
    user: null | {
      is_email_verified: number;
      vehicles: any;
    };
  };
}

export const withAuth = (WrappedComponent: FC): FC => (props): ReactElement => {
  const user = useSelector(({ auth }: State) => auth.user);
  const isThanksModal = useSelector(({ vehicals }: RootState) => vehicals.isThanksModal);
  // const transactionStatus = useSelector(({ vehicals }: RootState) => vehicals.transactionStatus);

  const { pathname } = useLocation();
  const route = pathname.toLowerCase();

  if (!user) return <Redirect to="/login" />;

  // if (route === '/generalCertificate') {
  //   console.log('routeeeeeeeeeeee', route);
  //   return <Redirect to="/generalCertificate" />;
  // }

  if (!['/verifyemail', '/addvehicle'].includes(route)) {
    if (!user.is_email_verified) return <Redirect to="/verifyEmail" />;
    if (!user.vehicles || !Array.isArray(user.vehicles) || user.vehicles.length === 0) {
      return <Redirect to="/addVehicle" />;
    }
  }
  if (route === '/verifyemail' && user.is_email_verified) {
    return <Redirect to="/vehicleInspection" />;
  }

  if (route === '/addvehicle' && user.vehicles.length > 0) {
    return <Redirect to="/vehicleInspection" />;
  }

  if (
    route === '/vehicleinspection'
    && user.vehicles[0].review_status === 'READY_FOR_REVIEW'
    && !isThanksModal
  ) {
    return <Redirect to="/vehiclestatus" />;
  }

  if (route === '/vehicleinspection' && user.vehicles[0].review_status === 'REVIEWED') {
    return <Redirect to="/vehiclestatus" />;
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <WrappedComponent {...props} />;
};
