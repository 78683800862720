import styled from 'styled-components';

export const MessageContainer = styled.div`
  position: relative;
  display: table;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 100vh;
`;

export const MessageContainer2 = styled.div`
  text-align: center;
  width: 100%;
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const MessageContainer3 = styled.div`
  margin-bottom: 80px;
  display: inline-block;
`;

export const MessageH2 = styled.h2`
  font-size: 24px;
  line-height: 30px;
  color: #BA7214;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const MessageP = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #BA7214;
  font-weight: 600;
  padding: 0px 32px 32px 32px;
`;
