import React from 'react';
import { useSelector } from 'react-redux';
import {
  ReviewHeaderDiv, ReviewTitle, VideoContainer, Video,
} from './ReviewPage.styles';
import { FrontPageDiv, WrapperDiv, PrimaryLinkButton } from '../styles';
import { RootState } from '../../reducers';
// import { bucketName } from '../../config';

const ReviewPage = () => {
  const {
    instructions: { instructions },
  } = useSelector((state: RootState) => state);

  return (
    <FrontPageDiv blackBackground>
      <WrapperDiv>
        <ReviewHeaderDiv>
          <ReviewTitle>Review Files</ReviewTitle>
        </ReviewHeaderDiv>

        {[].map((f, i) => (
          <VideoContainer key={f.id}>
            {typeof f.stage === 'number'
              && instructions[f.stage - 1]
              && instructions[f.stage - 1].title
              ? <ReviewTitle>{instructions[f.stage - 1].title}</ReviewTitle>
              : <ReviewTitle>{`Step: ${i + 1}`}</ReviewTitle>}
            <Video
              controls
              muted
              preload="metadata"
              src={`chex-ai-uploads/${f.url}`}
            />
          </VideoContainer>
        ))}

        <div>
          <PrimaryLinkButton to="/final">Submit</PrimaryLinkButton>
        </div>
      </WrapperDiv>
    </FrontPageDiv>
  );
};

export default ReviewPage;
