import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { apiUrl, jwtTokenKey } from '../config';

export const getToken = (): string => localStorage.getItem(jwtTokenKey) || null;
export const setToken = (token: string): void => localStorage.setItem(jwtTokenKey, token);
export const removeToken = (): void => localStorage.removeItem(jwtTokenKey);
export const removeCompanyName = (): void => localStorage.removeItem('COMPANY_NAME');

const axiosInstance = axios.create({ baseURL: apiUrl });

const errHandling = (error: Error) => Promise.reject(error);

const reqHandling = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const obj = { ...config };
  const token = getToken();
  if (token) obj.headers.Authorization = `Bearer ${token}`;

  return obj;
};

const resHandling = (response: AxiosResponse): AxiosResponse => response;

axiosInstance.interceptors.request.use(reqHandling, errHandling);
axiosInstance.interceptors.response.use(resHandling, errHandling);

export default axiosInstance;
