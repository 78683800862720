/* eslint-disable */

import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Field } from 'formik';
import Modal from 'react-modal';
import { Select, TreeSelect } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../history';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { ClipLoader } from 'react-spinners';

// import { vinUrl } from 'ss../../config';
import TextInput from '../formFields/TextInput';
import { RootState } from '../../reducers';
import { withAuth } from '../../hocs/withAuth';
import {
  MainContainer,
  ImageBackgroundInsertDetails,
  MainBgInsertDetails,
  ContentInsertDetails,
  InsertDetailsH5,
  // ArrowBack,
  InsertDetailsP,
  ContentFooterAreaInsertDetails,
  InputInsertDetailsPage,
  InsertDetailsLabel,
  InsertDetailsInputFile,
  InsertDetailsMtB5,
  OpenNavSpan,
  HeaderItems,
  ArrowBackA,
  MobileViewWarning,
  MobileViewWarningContainer,
  BoldSpan,
  MainDownContainer,
} from './AddVehiclePage.styles';

import CropVinNumber from './cropVinNumber.component';
import LeftSidebarComp from '../LeftSidebar/LeftSidebar.component';

import { getVehcicleCompanies, getVinNumber } from '../../actions/VehicleAction';
import AddVehicleDetailModal from './AddVehicleDetailModal.component';

const { Option } = Select;
const { TreeNode } = TreeSelect;

const state = [
  { name: 'Alabama', id: 'AL' },
  { name: 'Alaska', id: 'AK' },
  { name: 'Arizona', id: 'AZ' },
  { name: 'Arkansas', id: 'AR' },
  { name: 'California', id: 'CA' },
  { name: 'Colorado', id: 'CO' },
  { name: 'Connecticut', id: 'CT' },
  { name: 'Delaware', id: 'DE' },
  { name: 'Florida', id: 'FL' },
  { name: 'Georgia', id: 'GA' },
  { name: 'Hawaii', id: 'HI' },
  { name: 'Idaho', id: 'ID' },
  { name: 'Illinois', id: 'IL' },
  { name: 'Indiana', id: 'IN' },
  { name: 'Iowa', id: 'IA' },
  { name: 'Kansas', id: 'KS' },
  { name: 'Kentucky', id: 'KY' },
  { name: 'Louisiana', id: 'LA' },
  { name: 'Maine', id: 'ME' },
  { name: 'Maryland', id: 'MD' },
  { name: 'Massachusetts', id: 'MA' },
  { name: 'Michigan', id: 'MI' },
  { name: 'Minnesota', id: 'MN' },
  { name: 'Mississippi', id: 'MS' },
  { name: 'Missouri', id: 'MO' },
  { name: 'Montana', id: 'MT' },
  { name: 'Nebraska', id: 'NE' },
  { name: 'Nevada', id: 'NV' },
  { name: 'New Hampshire', id: 'NH' },
  { name: 'New Jersey', id: 'NJ' },
  { name: 'New Mexico', id: 'NM' },
  { name: 'New York', id: 'NY' },
  { name: 'North Carolina', id: 'NC' },
  { name: 'North Dakota', id: 'ND' },
  { name: 'Ohio', id: 'OH' },
  { name: 'Oklahoma', id: 'OK' },
  { name: 'Oregon', id: 'OR' },
  { name: 'Pennsylvania', id: 'PA' },
  { name: 'Rhode Island', id: 'RI' },
  { name: 'South Carolina', id: 'SC' },
  { name: 'South Dakota', id: 'SD' },
  { name: 'Tennessee', id: 'TN' },
  { name: 'Texas', id: 'TX' },
  { name: 'Utah', id: 'UT' },
  { name: 'Vermont', id: 'VT' },
  { name: 'Virginia', id: 'VA' },
  { name: 'Washington', id: 'WA' },
  { name: 'West Virginia', id: 'WV' },
  { name: 'Wisconsin', id: 'WI' },
  { name: 'Wyoming', id: 'WY' },
];

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'scroll',
    minWidth: '100%',
    height: '100%',
  },
};

const customStylesOcrModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    overflow: 'scroll',
    minWidth: '100%',
  },
};

const validationSchema = Yup.object().shape({
  vin: Yup.string()
    .min(17)
    .required('VIN is required!')
    .matches(/^[a-zA-Z0-9]*$/, 'should be alphanumeric'),

  licensePlateNo: Yup.string().required('License plate number is required!'),
  color: Yup.string().required('color is required!'),
  vehicle_mileage: Yup.string().required('Vehicle Milage is required'),
});

const companies = [
  {
    id: 1,
    name: 'company 1',
    address: 'Lahore',
    domain: 'domain 1',
  },
  {
    id: 2,
    name: 'company 2',
    address: 'Lahore',
    domain: 'domain 2',
  },
  {
    id: 3,
    name: 'company 3',
    address: 'Lahore',
    domain: 'domain 3',
  },
];

let initialValues = {
  vin: '',
  licensePlateNo: '',
  color: '',
  vehicle_mileage: '',
};
const AddVehiclePage: FC = (props) => {
  const { search } = useLocation();
  const [showSideBar, setShowSidebar] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showVehicalValues, setVehicalValues] = useState(initialValues);
  const [showCroppedImage, setCroppedImage] = useState();
  const [showOcrModal, setOcrModal] = useState(false);
  const [isModalsOpen, setIsModalsOpen] = useState(true);
  const [showVinNumberText, setVinNumberText] = useState(null);
  const [selectedCompanies, setselectedCompanies]: any = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [selected_states, setSelectedStates] = useState('');

  // TODO: NEEDS PROPER TYPECASTING INSTEAD OF ANY
  const vehicleDetail: any = useSelector((state: RootState) => state.vehicals.vehicalDetail);
  const loading: any = useSelector((state: RootState) => state.auth.loading);
  const addVehicleLoading: any = useSelector((state: RootState) => state.vehicals.isAddVehicle);
  const vehicleDetailModal: any = useSelector((state: RootState) => state.vehicals.vehicleDetailModal);

  const [allCompanies, setallCompanies]: any = useState([{ id: 0, name: '' }]);
  const dispatch = useDispatch();
  const getallComp: any = useSelector((state: RootState) => state.vehicals);

  useEffect(() => {
    dispatch(getVehcicleCompanies());
    const { vin, license_plate, color, companies, vehicle_mileage } = qs.parse(search.replace('?', '')) as {
      vin?: string;
      license_plate?: string;
      color?: string;
      companies?: any;
      vehicle_mileage: any;
    };
    if (vin && license_plate && color && vehicle_mileage) {
      initialValues = {
        vin,
        licensePlateNo: license_plate,
        color,
        vehicle_mileage,
      };
      // console.log('compnaies', companies);
      const comp = getallComp.allCompanies.filter((i: any) => i.id === JSON.parse(companies));
      console.log('comp', comp);
      setselectedCompanies([comp[0].id]);
    } else {
      initialValues = {
        vin: '',
        licensePlateNo: '',
        color: '',
        vehicle_mileage: '',
      };
    }
  }, []);

  useEffect(() => {
    setallCompanies(getallComp.allCompanies);
  }, [getallComp]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      const data = {
        color: values.color,
        licensePlateNo: values.licensePlateNo,
        vin: values.vin,
        vehicle_mileage: values.vehicle_mileage,
        companies: selectedCompanies,
        registration_state: selected_states,
      };
      localStorage.setItem('VEHICLE_COMPANIES', selectedCompanies.length);
      localStorage.setItem('COMPANY_NAME', companyName);
      setVehicalValues(data);
      // history.push(
      //   `/addVehicleDetail?vin=${values.vin}&color=${values.color}&license_plate=${values.licensePlateNo}&companies=${selectedCompanies}`
      // );
      await dispatch(getVinNumber(values.vin));
      setShowModal(true);
    },
  });

  const getCroppedImageUrl = (imageUrl: any) => {
    setCroppedImage(imageUrl);
  };

  const closeOcrModal = (vinNumberText: string) => {
    if (vinNumberText) {
      setVinNumberText(vinNumberText);
    }
    setOcrModal(false);
    setIsModalsOpen(true);
  };

  const multiSelectChange = (value: any) => {
    //const a = value[1];
    allCompanies.map((comapany: any) => {
      if (comapany.id === value) {
        console.log(comapany.name);
        setCompanyName(comapany.name);
      }
    });
    setselectedCompanies([value]);
  };

  const handleState = (value: any) => {
    setSelectedStates(value);
  };

  if (showSideBar) return <LeftSidebarComp handleClose={() => setShowSidebar(false)} />;
  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContainer>
        {/* {console.log('initial ', initialValues)} */}
        <ImageBackgroundInsertDetails>
          <MainBgInsertDetails>
            <ContentInsertDetails>
              <HeaderItems>
                <ArrowBackA>{/* <i className="fas fa-chevron-left fa-2x" color="#2584E0" /> */}</ArrowBackA>
                <InsertDetailsH5>Insert Detail</InsertDetailsH5>
                <OpenNavSpan type="button" onClick={() => setShowSidebar(true)}>
                  &#9776;
                </OpenNavSpan>
              </HeaderItems>
              <InsertDetailsP>Please insert below driver and vehicle information</InsertDetailsP>
              <MobileViewWarningContainer>
                <MobileViewWarning>
                  Please use <BoldSpan>mobile phone </BoldSpan>for optimal perfomance
                </MobileViewWarning>
              </MobileViewWarningContainer>
            </ContentInsertDetails>
            <MainDownContainer>
              <ContentFooterAreaInsertDetails>
                <InputInsertDetailsPage>
                  <InsertDetailsLabel>Type Vin</InsertDetailsLabel>
                  <InsertDetailsInputFile>
                    {/* <span style={{ opacity: showModal ? 0 : 1 }}>
                    <img src="/images/vinTypeImage.svg" alt="" width="25px" />
                  </span> */}
                    <TextInput style={{ width: '100%' }} formik={formik} name="vin" label="" />
                  </InsertDetailsInputFile>
                  <InsertDetailsLabel>License plate number</InsertDetailsLabel>
                  <TextInput style={{ width: '100%' }} formik={formik} name="licensePlateNo" label="" />
                  <InsertDetailsLabel>Select Vehicle Color</InsertDetailsLabel>
                  <TextInput style={{ width: '100%' }} formik={formik} name="color" label="" />
                  <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                    <div style={{ width: '50%' }}>
                      <InsertDetailsLabel>Registration State</InsertDetailsLabel>
                      <div style={{ backgroundColor: '#e7eef5', padding: '10px' }}>
                        <Select
                          value={selected_states}
                          onChange={handleState}
                          style={{
                            width: '100%',
                            fontSize: '16px',
                          }}
                          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        >
                          {state?.map((i: any) => (
                            <Option value={i.id}>{i.name}</Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div style={{ width: '40%' }}>
                      <InsertDetailsLabel>Vehicle Milage</InsertDetailsLabel>
                      <TextInput style={{ width: '100%' }} formik={formik} name="vehicle_mileage" label="" />
                    </div>
                  </div>
                  <InsertDetailsLabel>Please select companies</InsertDetailsLabel>
                  <div style={{ width: '100%', backgroundColor: '#e7eef5', padding: '10px' }}>
                    <Select
                      placeholder="Please select companies"
                      value={selectedCompanies}
                      onChange={multiSelectChange}
                      style={{
                        width: '100%',
                        fontSize: '16px',
                      }}
                      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    >
                      {allCompanies?.map((i: any) => (
                        <Option value={i.id}>{i.name}</Option>
                      ))}
                    </Select>
                  </div>

                  {/* <TreeSelect
                  showSearch
                  style={{
                    width: '100%',
                    background: '#e7eef5',
                    border: '1px solid #e7eff8',
                    boxSizing: 'border-box',
                    borderRadius: '3px',
                    margin: '0 auto 20px',
                    padding: '15px 20px',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '16px',
                    lineHeight: '24px',
                  }}
                  value={selectedCompanies}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="Please select companies"
                  allowClear
                  multiple
                  treeDefaultExpandAll
                  onChange={multiSelectChange}
                >
                  {allCompanies?.map((i: any) => (
                    <TreeNode value={i.id} title={i.name} />
                  ))}
                </TreeSelect> */}
                </InputInsertDetailsPage>

                <InsertDetailsMtB5>
                  <button disabled={loading} type="submit">
                    {loading ? <ClipLoader size={20} color="#1A60A6" loading /> : 'NEXT STEP'}
                  </button>
                </InsertDetailsMtB5>
              </ContentFooterAreaInsertDetails>
            </MainDownContainer>
          </MainBgInsertDetails>
          <Modal
            isOpen={showOcrModal}
            onRequestClose={() => {
              setOcrModal(false);
              setIsModalsOpen(true);
            }}
            style={customStylesOcrModal}
            contentLabel="OCR MODAL"
          >
            <CropVinNumber getCroppedImageUrl={getCroppedImageUrl} closeOcrModal={closeOcrModal} />
          </Modal>
        </ImageBackgroundInsertDetails>
        {vehicleDetailModal ? (
          <AddVehicleDetailModal
            openModal={vehicleDetailModal}
            closeModal={() => setOpenModal(false)}
            data={showVehicalValues}
            selectedCompanies={selectedCompanies}
            selectedState={selected_states}
          />
        ) : null}
        )
      </MainContainer>
    </form>
  );
};

export default withAuth(AddVehiclePage);
