import axios, { AxiosResponse } from 'axios';
import api from '.';

export const getSignedUrl = (values: object): Promise<AxiosResponse> => api.post('/api/v1/file/upload', values);

type UploadToS3Bucket = {
  url: string;
  file: any;
  type: string;
};

export const uploadToS3Bucket = (
  { url, file, type }: UploadToS3Bucket,
  progress: any,
): Promise<AxiosResponse> => {
  const headers = { 'Content-Type': type } as { 'Content-Type': string };

  // if (contentEncoding) headers['Content-Encoding'] = contentEncoding;

  // const payload = Buffer.from(file.replace(/^data:image\/\w+;base64,/, ''), 'base64');

  return axios.put(url, file, { headers, onUploadProgress: progress });
};
