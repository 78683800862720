import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 20px;
`;

export const MainBgInsertDetails = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
`;

export const ArrowBackA = styled(Link)`
  color: #2584e0;
`;

export const ContentVehicleInspectionH1 = styled.h1`
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
  text-align: center;
  color: #000000;
  align-self: center;
  margin: 0 auto;
`;

export const InsertDetailsP = styled.p`
  color: #000000;
  text-align: center;
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
`;

export const InsertDetailsH = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  margin-top: 30px;
  color: #ff7a00;
`;

export const CarouselContainer = styled.div`
  padding: 0px 25px;
  .carousel-div {
    .slick-dots {
      bottom: -50px;
      margin-left: auto;
      margin-right: auto;
      li.slick-active {
        button {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #ff7a00;
        }
      }

      li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #c9d4e4;
        margin-right: 7px;
        margin-left: 7px;
      }
    }
  }
`;

export const CarouselContainerItem = styled.div`
  display: flex !important;
  justify-content: center;
`;

export const DescriptionContainer = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InsertDetailsDescription = styled.p`
  color: #889bad;
  text-align: left;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 25px;
  margin-left: 20px;

  margin-bottom: 35px;

  ul {
    text-align: left;
    li {
      margin-bottom: 5px;
    }
  }

  @media screen and (max-width: 700px) {
    margin-right: auto;
    width: 80%;
    margin-left: 50px;
  }
`;

export const InsertDetailsMtB5 = styled(Link)`
  margin-top: 48px;
  text-align: center;
  margin-bottom: 0px;
  margin: 0 auto;
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 14px 14px 15px;
  text-decoration: none;
  margin-bottom: 20px;
  width: 30%;
  @media screen and (max-width: 700px) {
    width: 70%;
  }
`;

export const CarouselContainerItemList = styled.div`
  color: #fff;
  width: 30%;

  text-align: center;
  background: #07090c;
  border-radius: 5px;
  position: relative;

  ::focus + CarouselContainerItemPlayContainer {
    opacity: 1;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const CarouselContainerItemPlayContainer = styled.div`
  width: 63px;
  height: 63px;
  background: rgba(0 0 0, 0.4);
  border-radius: 50%;
  opacity: 0;
  border: 1px solid #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  :hover {
    opacity: 1;
  }
`;

export const CarouselContainerItemPlayImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
`;

export const TipsHeading = styled.p`
  color: #000000;

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 10px;

  // margin-right: auto;

  @media screen and (max-width: 700px) {
    margin-left: 30px;
    width: 80%;
  }
`;
