/* eslint-disable */
import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import {
  UploadButton,
  UploadButtonContainer,
  ImageCapture,
  VideoCapture,
  CrossIcon,
  CrossContainer,
  VehicleInstructionMainContainer,
  VehicleTitleWrapper,
  VehicleTitle,
  VehicleInstructionDescription,
  VehicleInstructionImageContainer,
  VehicleInstructionStepsContainer,
  VehicleInstructionStep,
  UploadLabel,
  ImageContainerUpload,
  ImageContainerMainUpload,
} from './VehicalInstructionModal.styles';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: ' 100%',
    backgroundColor: '#12579b',
    padding: '10px 20px',
    width: '100%',
  },
};
//testing develop

export const VehicleInstructionModal = ({
  doc,
  isOpen,
  instruction,
  handleUpload,
  setShowModalValue,
}: {
  doc: any;
  instruction: any;
  isOpen: boolean;
  handleUpload: (event: any, blob: boolean) => void;
  setShowModalValue: (value: boolean) => void;
}) => (
  <div>
    <Modal
      isOpen={isOpen}
      // onAfterOpen={afterOpenModal}
      // onRequestClose={() => setShowModalValue(false)}
      style={customStyles}
    >
      <CrossContainer>
        <CrossIcon onClick={() => setShowModalValue(false)}>
          <i className="fas fa-times" />
        </CrossIcon>
      </CrossContainer>
      {instruction && instruction.type === 'Photo' ? (
        <ImageContainerMainUpload>
          <div style={{ textAlign: 'center', paddingTop: '40px' }}>
            <div style={{ color: 'white', fontSize: '19px' }}>{instruction.title}</div>
          </div>
          <ImageContainerUpload>
            <img
              style={{
                height: '200px',
                width: '100%',
                marginTop: '20px',
                marginBottom: '20px',
                borderRadius: '5px',
              }}
              alt=""
              src={`${instruction?.image}`}
            />
          </ImageContainerUpload>
          <div>
            <VehicleInstructionStepsContainer>
              {instruction.steps && instruction.steps.map((step: any) => <VehicleInstructionStep style={{ paddingTop: '10px', color: 'white' }}>{step}</VehicleInstructionStep>)}
            </VehicleInstructionStepsContainer>
          </div>
        </ImageContainerMainUpload>
      ) : (
        <ImageContainerMainUpload>
          <VehicleInstructionMainContainer>
            <VehicleTitleWrapper>
              <VehicleTitle>{instruction.title}</VehicleTitle>
            </VehicleTitleWrapper>
            <VehicleInstructionDescription>{/* {instruction.description} */}</VehicleInstructionDescription>
            <VehicleInstructionImageContainer>
              <ReactPlayer
                controls
                looping
                // playing
                height="200px"
                width="100%"
                url={instruction.video}
              />
            </VehicleInstructionImageContainer>
            <VehicleInstructionStepsContainer>
              {instruction.steps && instruction.steps.map((step: any) => <VehicleInstructionStep style={{ paddingTop: '10px', color: 'white' }}>{step}</VehicleInstructionStep>)}
            </VehicleInstructionStepsContainer>
          </VehicleInstructionMainContainer>
        </ImageContainerMainUpload>
      )}

      <UploadButtonContainer>
        <UploadButton>
          <UploadLabel htmlFor={doc.id} className="custom-file-upload" id="choose-file-label">
            {doc.type === 'Photo' ? 'Next' : 'Next'}
          </UploadLabel>
          {doc.type === 'Photo' ? (
            <ImageCapture id={doc.id} type="file" accept="image/*" capture onChange={(event) => handleUpload(event, true)} />
          ) : (
            <VideoCapture onChange={(event) => handleUpload(event, false)} id={doc.id} type="file" accept="video/*" capture />
          )}
        </UploadButton>
      </UploadButtonContainer>
    </Modal>
  </div>
);
