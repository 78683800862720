import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

import {
  Sidenav,
  SidebarLink,
  MySlideNavH1,
  SidebarLinkAnc,
  SidebarLinkButton,
} from './LeftSidebar.styles';
import { ActionTypes } from '../../actions/ActionTypes';
import { RootState } from '../../reducers';
import LogoutModal from './logoutModal.component';

const LeftSidebarComp = ({ handleClose }: { handleClose: () => void }) => {
  const [showModel, setShowModel] = useState(false);

  const dispatch = useDispatch();
  const mainState = useSelector((state: RootState) => state);
  const vehicle = get(mainState, 'auth.user.vehicles[0]');
  const logout = () => dispatch({ type: ActionTypes.LogoutUserStart });

  return (
    <Sidenav style={{ width: '100%' }}>
      <SidebarLink type="button" onClick={handleClose}>
        &times;
      </SidebarLink>
      <MySlideNavH1>CHEX.AI</MySlideNavH1>
      <SidebarLinkAnc to="/">
        <i className="fas fa-home" />
        &nbsp; Home
      </SidebarLinkAnc>

      <SidebarLinkAnc to="/transactionHisory">
        <i className="fas fa-history" />
        &nbsp; History
      </SidebarLinkAnc>

      <SidebarLinkAnc to="/vehicleDetails">
        <i className="fas fa-car-alt" />
        &nbsp; Vehicle Details
      </SidebarLinkAnc>

      {vehicle?.review_status && vehicle?.review_status === 'REVIEWED' ? (
        <SidebarLinkAnc to="/vehiclestatus">
          <i className="fas fa-info-circle" />
          &nbsp; Vehicle Status
        </SidebarLinkAnc>
      ) : null}

      <SidebarLinkAnc to="/contactUs">
        <i className="fas fa-phone" />
        &nbsp; Contact us
      </SidebarLinkAnc>

      <SidebarLinkButton onClick={() => setShowModel(true)}>
        <i className="fas fa-sign-out-alt" />
        &nbsp; Logout
      </SidebarLinkButton>
      {showModel ? <LogoutModal setShowModel={setShowModel} logout={logout} /> : null}
    </Sidenav>
  );
};

export default LeftSidebarComp;
