/* eslint-disable */

import React, { useEffect } from 'react';
import qs from 'qs';
//@ts-ignore
import Pdf from 'react-to-pdf';
import { Col, Row } from 'antd';
import { ClipLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';

import { jwtTokenKey } from '../../config';
import { useSelector, useDispatch } from 'react-redux';
import { getReportDataComplete } from '../../actions/VehicleAction';
import DownloadVehicleCertificate from './DownloadVehicleCertificate';
import './style.css';

const refs: React.RefObject<HTMLDivElement> = React.createRef();

const VehicleCertificate = (props: any) => {
  const { search } = useLocation();
  const reportData: any = useSelector((state: any) => state.vehicals.certificateData);
  const addVehicleLoading: any = useSelector((state: any) => state.vehicals.isAddVehicle);
  const dispatch = useDispatch();

  useEffect(() => {
    const getToken = (): string => localStorage.getItem(jwtTokenKey) || null;
    if (getToken) {
      const { id } = qs.parse(search.replace('?', '')) as { id?: string };
      dispatch(getReportDataComplete(id));
    } else {
      props.history.push('/login');
    }
  }, []);

  return (
    <div className="container">
      <div className="content m-0 p-0 ">
        {addVehicleLoading ? (
          <div
            style={{
              height: '50vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader size={50} color="#1A60A6" loading />
          </div>
        ) : (
          <>
            <Pdf
              targetRef={refs}
              // options={options}
              x={6.0}
              //y={-7.5}
              scale={0.65}
              filename={`Report of Vehicle ${reportData[0]?.id}.pdf`}
            >
              {({ toPdf }: any) => (
                <div className="p-3">
                  <p
                    onClick={toPdf}
                    style={{
                      cursor: 'pointer',
                      color: 'blue',
                      display: 'inline',
                    }}
                  >
                    <b>Download Pdf</b> <i className="fa fa-download" />
                  </p>
                </div>
              )}
            </Pdf>

            <DownloadVehicleCertificate refs={refs} />
            <div style={{ width: '100%', height: '100%' }}>
              <Row gutter={[20, 12]}>
                <Col span={24}>
                  <div className="d-flex-1 align-items-center flex-column">
                    <img src="/images/chex-ai-logo.png" />
                    <div>
                      <p className="text-center">
                        <b>1383 SAN MATEO AVE. SOUTH SAN FRANCISCO, CA 94080</b>
                      </p>
                      <p className="text-center">
                        <b>ARD # 217646</b>
                      </p>
                      <h3 className="text-center fontFamily">
                        <b>INSPECTION CHECKLIST</b>
                      </h3>
                    </div>
                  </div>
                </Col>
                <div className="w-100 p-5">
                  <Row gutter={[20, 12]}>
                    {reportData &&
                      reportData?.map((i: any, index: any) =>
                        i?.vehicleChecks.map((j: any, index1: any) => (
                          <Col sm={24} md={12} className="w-100">
                            <div key={index}>
                              <div className="d-flex-1 justify-content-between fontFamily">
                                <div>
                                  <h5 className="d-inline-block" style={{ borderBottom: '1px solid black' }}>
                                    {j.category}
                                  </h5>
                                  <div className="d-flex-1 justify-content-between" key={index1}>
                                    <label>{j.name}</label>
                                  </div>
                                </div>
                                <div style={{ width: '18%' }}>
                                  <div className="d-flex-1 justify-content-between">
                                    <h6>Pass</h6>
                                    <h6>Fail</h6>
                                  </div>
                                  <div className="d-flex-1 justify-content-around ">
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                      }}
                                    >
                                      <label className="certificate-input-conatainer">
                                        <input type="checkbox" style={{ fontSize: '15px' }} checked={j.status === 1 || j.status === true ? true : false} />
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>

                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        width: '100%',
                                      }}
                                    >
                                      <label className="certificate-input-conatainer">
                                        <input type="checkbox" style={{ fontSize: '15px', marginLeft: '15px' }} checked={j.status === 0 || j.status === false ? true : false} />
                                        <span className="checkmark fail-checkbox"></span>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        ))
                      )}
                  </Row>
                  <Col span={24}>
                    <div>
                      <h4 className="d-inline fontFamily" style={{ borderBottom: '1px solid black' }}>
                        INSPECTION RESULTS:
                      </h4>
                      <div className="d-flex-1 justify-content-between p-3 fontFamily">
                        <h5 className="vehicle-milage">
                          VEHICLE MILAGE: <b>{reportData && reportData[0]?.vehicle_mileage}</b>
                        </h5>
                        <h5 className="lisence-plate">
                          LICENSE PLATE#: <b>{reportData && reportData[0]?.license_plate_no}</b>
                        </h5>
                        <h5 className="vin-inspection">
                          VIN#: <b>{reportData && reportData[0]?.vin}</b>
                        </h5>
                      </div>
                      <div className="d-flex-1 justify-content-between p-3 fontFamily">
                        <h5 className="vehicle-make">
                          VEHICLE MAKE: <b>{reportData && reportData[0]?.make}</b>
                        </h5>
                        <h5 className="vehicle-model">
                          VEHICLE MODEL#: <b>{reportData && reportData[0]?.model}</b>
                        </h5>
                        <h5 className="vehicle-year">
                          VEHICLE YEAR: <b>{reportData && reportData[0]?.year}</b>
                        </h5>
                      </div>
                    </div>
                  </Col>
                </div>
              </Row>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default VehicleCertificate;
