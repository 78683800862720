import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { RootState } from '../../reducers';
import { withAuth } from '../../hocs/withAuth';
import LeftSidebarComp from '../LeftSidebar/LeftSidebar.component';

import {
  InsertDetailsLabel,
  MainContainer,
  VehicalDetailDiv,
  VehicalDetailContainer,
  ContentVehicleInspectionH1,
  ImageBackgroundInsertDetails,
  MainBgInsertDetails,
  ContentFooterAreaInsertDetails,
  HeaderItem,
  ArrowBackA,
  MainDownContainer,
} from './VehicleDetails.styles';

const VehicleDetails = () => {
  const [showSideBar, setShowSidebar] = useState(false);
  const mainState = useSelector((state: RootState) => state);

  const vehicleDetail: any = get(mainState, 'auth.user.vehicles[0]');

  if (showSideBar) return <LeftSidebarComp handleClose={() => setShowSidebar(false)} />;
  return (
    <div>
      <MainContainer>
        <ImageBackgroundInsertDetails>
          <MainBgInsertDetails>
            <HeaderItem>
              <ArrowBackA to="/">
                <i className="fas fa-chevron-left fa-2x" color="#2584E0" />
              </ArrowBackA>
              <ContentVehicleInspectionH1>Vehicle Details</ContentVehicleInspectionH1>
            </HeaderItem>
            <MainDownContainer>
              <ContentFooterAreaInsertDetails>
                <VehicalDetailContainer>
                  <InsertDetailsLabel>Year</InsertDetailsLabel>
                  <VehicalDetailDiv>{vehicleDetail.year}</VehicalDetailDiv>
                </VehicalDetailContainer>
                <VehicalDetailContainer>
                  <InsertDetailsLabel>Make</InsertDetailsLabel>
                  <VehicalDetailDiv>{vehicleDetail.make}</VehicalDetailDiv>
                </VehicalDetailContainer>
                <VehicalDetailContainer>
                  <InsertDetailsLabel>Model</InsertDetailsLabel>
                  <VehicalDetailDiv>{vehicleDetail.model}</VehicalDetailDiv>
                </VehicalDetailContainer>
                <VehicalDetailContainer>
                  <InsertDetailsLabel>Color</InsertDetailsLabel>
                  <VehicalDetailDiv>{vehicleDetail.color}</VehicalDetailDiv>
                </VehicalDetailContainer>
                {/* <VehicalDetailContainer>
                <InsertDetailsLabel>Manufacture</InsertDetailsLabel>
                <VehicalDetailDiv>1234hjf, kf</VehicalDetailDiv>
              </VehicalDetailContainer>
              <VehicalDetailContainer>
                <InsertDetailsLabel>Engine</InsertDetailsLabel>
                <VehicalDetailDiv>{vehicleDetail.engine}</VehicalDetailDiv>
              </VehicalDetailContainer>
              <VehicalDetailContainer>
                <InsertDetailsLabel>Trim</InsertDetailsLabel>
                <VehicalDetailDiv>{vehicleDetail.trim}</VehicalDetailDiv>
              </VehicalDetailContainer>
              <VehicalDetailContainer>
                <InsertDetailsLabel>Transmission</InsertDetailsLabel>
                <VehicalDetailDiv>
                  {vehicleDetail.transmission}
                </VehicalDetailDiv>
              </VehicalDetailContainer> */}
              </ContentFooterAreaInsertDetails>
            </MainDownContainer>
          </MainBgInsertDetails>
        </ImageBackgroundInsertDetails>
      </MainContainer>
    </div>
  );
};

export default withAuth(VehicleDetails);
