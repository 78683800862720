/* eslint-disable */

import React, { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Collapse, Row, Col, Typography, Button } from 'antd';
import { UpCircleOutlined, CheckOutlined, CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import Modal from 'react-modal';
import { get } from 'lodash';
import moment from 'moment';

import LeftSidebarComp from '../LeftSidebar/LeftSidebar.component';
import store from '../../store/index';
import { withAuth } from '../../hocs/withAuth';
import { RootState } from '../../reducers';
import {
  loginFromToken,

  // logoutUser,
} from '../../actions/AuthActions';
import { getToken } from '../../api';
import { s3Link, apiUrl } from '../../config';
import { setThankYouModal } from '../../actions/VehicleAction';
import { useHistory } from 'react-router-dom';
//@ts-ignore
import Pdf from 'react-to-pdf';

import {
  ContentVehicleInspectionH1,
  OpenNavSpan,
  MainContainer,
  VehicleDetailMainContainer,
  ImageContainer,
  Images,
  VehicleModalImageOpen,
  VehicleModalTextArea,
  VehicleModalImage,
  VehicleModalText,
  VehicleModalVideoOpen,
  VehicleModalVideo,
  CrossVideoIcon,
  ImageBackgroundInsertDetails,
  MainBgInsertDetails,
  HeaderItem,
  ApprovedImages,
  GrayTextHeader,
  Reviewtext,
  DaysText,
  CommentContainer,
  UpArrow,
  CollapseContainer,
} from './vehicleStatusAfterPayment.styles';
import DownloadCertifcate from '../GeneralCertificate/DownloadCertificate.component';
//@ts-ignore
import { useMediaQuery } from 'react-responsive';

const { Panel } = Collapse;
const { Paragraph } = Typography;

const refs: React.RefObject<HTMLDivElement> = React.createRef();

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '550px',
    overflow: 'scroll',
    minWidth: '80%',
  },
};

const VehicleDetailAfterPayment: FC = () => {
  const history = useHistory();
  const [showSideBar, setShowSidebar] = useState(false);
  const [showModal, setModalValue] = useState(false);
  const [showFileData, setFileData] = useState(null);
  const [difference, setDifference] = useState(0);

  const dispatch = useDispatch();

  const mainState = useSelector((state: RootState) => state);
  const vehicle = get(mainState, 'auth.user.vehicles[0]');
  const vehicleFiles = get(mainState, 'auth.user.files');
  const createdDate = get(mainState, 'auth.user.created_at');

  // const logout = () => dispatch(logoutUser());

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1000px)',
  });
  const isBigScreen = useMediaQuery({ query: '(min-device-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1000px)' });
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)',
  });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  useEffect(() => {
    const diff = moment().diff(createdDate, 'days');
    setDifference(diff);
    const token = getToken();
    if (token) store.dispatch(loginFromToken());
    dispatch(setThankYouModal(false));
  }, []);

  const handleModal = (value: boolean, file: object) => {
    setModalValue(value);
    setFileData(file);
  };

  const genExtra = () => (
    <UpCircleOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );

  if (showSideBar) {
    return <LeftSidebarComp handleClose={() => setShowSidebar(false)} />;
  }

  return (
    <MainContainer>
      <ImageBackgroundInsertDetails>
        <MainBgInsertDetails>
          <HeaderItem>
            <ContentVehicleInspectionH1>
              <span>CHEX</span>
              .AI
            </ContentVehicleInspectionH1>

            <OpenNavSpan type="button" onClick={() => setShowSidebar(true)}>
              &#9776;
            </OpenNavSpan>
          </HeaderItem>

          <div style={{ background: '#E5E5E5', overflow: 'hidden' }}>
            <VehicleDetailMainContainer>
              {vehicle.review_status === 'REVIEWED' ? (
                <>
                  {isDesktopOrLaptop && (
                    <div className="d-flex justify-content-center align-items-center mb-2">
                      {vehicle && vehicle.link ? (
                        <Button type="primary" onClick={() => history.push(`${vehicle && vehicle.link}`)}>
                          Open Report
                        </Button>
                      ) : null}
                    </div>
                  )}
                  {isTabletOrMobile && (
                    <Pdf
                      targetRef={refs}
                      // options={options}
                      x={15.99}
                      // y={-7.5}
                      scale={0.55}
                      filename={`Report of Vehicle ${vehicle?.id}.pdf`}
                    >
                      {({ toPdf }: any) => (
                        <div className="p-3" style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button onClick={toPdf} style={{ background: '#1362B1' }} type="primary">
                            Download Report
                            {/* <DownloadOutlined onClick={toPdf} style={{ fontSize: '40px', display: 'flex', justifyContent: 'flex-end', color: '#1468BA' }} /> */}
                          </Button>
                        </div>
                      )}
                    </Pdf>
                  )}
                </>
              ) : (
                <div style={{ color: '#ff0000', textAlign: 'center' }}>
                  Your vehicle is currently being inspected, you will be able to download your inspection certificate once your vehicle is reviewed.
                </div>
              )}
              <DownloadCertifcate id={vehicle.id} refs={refs} />
              <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <CollapseContainer>
                  <Collapse
                    defaultActiveKey={['1']}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => (
                      <UpCircleOutlined style={isActive ? { fontSize: 28, color: '#FF7A00' } : { fontSize: 28, color: 'lightGray' }} rotate={isActive ? 180 : 0} />
                    )}
                  >
                    <Panel
                      style={{ overflow: 'hidden' }}
                      header={
                        <Row gutter={40} style={{ overflow: 'hidden' }}>
                          <Col>
                            <GrayTextHeader>Status</GrayTextHeader>
                            <Reviewtext>{vehicle.review_status}</Reviewtext>
                          </Col>
                          <Col>
                            <GrayTextHeader>Time</GrayTextHeader>
                            <DaysText>{difference > 0 ? `${difference} Day ago` : 'Today'}</DaysText>
                          </Col>
                          <Col></Col>
                        </Row>
                      }
                      key="1"
                      extra={genExtra}
                    >
                      <ApprovedImages>
                        <ImageContainer>
                          {vehicleFiles.map((file: any) => (
                            <Images onClick={() => handleModal(true, file)}>
                              {file?.ext?.split('/')[0] === 'video' ? (
                                <VehicleModalVideo>
                                  <source src={`${s3Link}/${file.url}`} />
                                </VehicleModalVideo>
                              ) : (
                                <div style={{ position: 'relative' }}>
                                  <VehicleModalImage src={`${s3Link}/${file.url}`} />
                                  <>
                                    {file.review === true ? (
                                      <div
                                        style={{
                                          position: 'absolute',
                                          bottom: '-7px',
                                          left: '50%',
                                          width: '30px',
                                          height: '30px',
                                          backgroundColor: 'green',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderRadius: '50%',
                                          transform: 'translate(-50%, 10%)',
                                        }}
                                      >
                                        <CheckOutlined style={{ color: 'white', fontSize: '16px' }} />
                                      </div>
                                    ) : file.review === false ? (
                                      <div
                                        style={{
                                          position: 'absolute',
                                          bottom: '-7px',
                                          left: '50%',
                                          width: '30px',
                                          height: '30px',
                                          backgroundColor: 'red',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          borderRadius: '50%',
                                          transform: 'translate(-50%, 10%)',
                                        }}
                                      >
                                        <CloseOutlined style={{ color: 'white', fontSize: '16px' }} />
                                      </div>
                                    ) : null}
                                  </>
                                </div>
                              )}
                              <VehicleModalText style={file.comments ? null : { border: '1px solid #F1F5FC' }}>{file.category}</VehicleModalText>
                              {file.comments ? (
                                <CommentContainer>
                                  <Paragraph
                                    style={{ color: '#8793A2' }}
                                    ellipsis={{
                                      rows: 3,
                                      expandable: true,
                                    }}
                                  >
                                    {file.comments}
                                  </Paragraph>

                                  <UpArrow />
                                </CommentContainer>
                              ) : null}
                            </Images>
                          ))}
                        </ImageContainer>
                      </ApprovedImages>
                    </Panel>
                  </Collapse>
                </CollapseContainer>
              </div>
              {/* <ApprovedImages>
              <ImageContainer>
                {vehicleFiles.map((file: any) => (
                  <Images onClick={() => handleModal(true, file)}>
                    {file?.ext.split('/')[0] === 'video' ? (
                      <VehicleModalVideo>
                        <source
                          src={`${s3Link}${file.url}`}
                        />
                      </VehicleModalVideo>
                    ) : (
                      <VehicleModalImage
                        src={`${s3Link}${file.url}`}
                      />
                    )}
                    <VehicleModalText>{file.category}</VehicleModalText>
                  </Images>
                ))}
              </ImageContainer>
              <VehicleModalTextArea disabled value={vehicle?.review_comments} />
            </ApprovedImages> */}
            </VehicleDetailMainContainer>
          </div>
          {/* <ButtonContainer>
          <LinkSignInBtn onClick={logout}>
            Logout
          </LinkSignInBtn>
        </ButtonContainer> */}

          {vehicle.review_status === 'REVIEWED' ? (
            <>
              <Modal isOpen={showModal} onRequestClose={() => handleModal(false, null)} style={customStyles}>
                <ImageContainer>
                  <CrossVideoIcon onClick={() => handleModal(false, null)}>X</CrossVideoIcon>
                  {showFileData?.ext?.split('/')[0] === 'video' ? (
                    <VehicleModalVideoOpen width="320" height="400" controls>
                      <source src={`${s3Link}/${showFileData?.url}`} />
                    </VehicleModalVideoOpen>
                  ) : (
                    <VehicleModalImageOpen src={`${s3Link}/${showFileData?.url}`} />
                  )}
                  <VehicleModalText>{showFileData?.category}</VehicleModalText>
                  <VehicleModalTextArea disabled value={showFileData?.comments} />
                </ImageContainer>
              </Modal>
            </>
          ) : null}
        </MainBgInsertDetails>
      </ImageBackgroundInsertDetails>
    </MainContainer>
  );
};

export default withAuth(VehicleDetailAfterPayment);
