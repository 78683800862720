import React, { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import { RootState } from '../../reducers';
import TextInput from '../formFields/TextInput';
import { resetPassword } from '../../actions/AuthActions';
import { withoutAuth } from '../../hocs/withoutAuth';

import {
  MainContainer,
  ImageBackgroundSignin,
  MainBgSignin,
  ArrowBack,
  ContentFooterAreaSignin,
  MainScreenP,
  InputSigninPage,
  ArrowBackA,
  MtB5,
  LinkSignInBtn,
  HeaderItem,
  ContentVehicleInspectionH1,
} from './ResetPassword.styles';

const validationSchema = Yup.object({}).shape({
  OTP: Yup.string().required('OPT is required!'),
  password: Yup.string().required('Password is required!'),
  confirmPassword: Yup.string().required('Confirm Password is required!'),
});

const initialValues = {
  OTP: '',
  password: '',
  confirmPassword: '',
};

const ResetPassword: FC = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const loading: any = useSelector((state: RootState) => state.auth.loading);
  const [showEmail, setShowEmail] = useState('');

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const obj = {
        email: showEmail,
        OTP: values.OTP,
        password: values.password,
        confirmPassword: values.confirmPassword,
      };
      dispatch(resetPassword(obj));
    },
  });

  useEffect(() => {
    const { email } = qs.parse(search.replace('?', '')) as { email?: string; };
    setShowEmail(email);
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContainer>
        <ImageBackgroundSignin>
          <MainBgSignin>
            <HeaderItem>
              <ArrowBack>
                <ArrowBackA to="/">
                  <i className="fas fa-chevron-left fa-2x" />
                </ArrowBackA>
              </ArrowBack>
              <ContentVehicleInspectionH1>Forgot password</ContentVehicleInspectionH1>
            </HeaderItem>
            <MainScreenP>Please check your email for unique pin and type below</MainScreenP>
            <ContentFooterAreaSignin>
              <div>
                <InputSigninPage>
                  <TextInput formik={formik} type="number" name="OTP" label="Unique pin" />
                </InputSigninPage>
                <InputSigninPage>
                  <TextInput formik={formik} type="password" name="password" label="Password" />
                </InputSigninPage>
                <InputSigninPage>
                  <TextInput formik={formik} type="password" name="confirmPassword" label="Confirm Password" />
                </InputSigninPage>
              </div>
              <MtB5>
                <LinkSignInBtn disabled={loading} type="submit">
                  {loading ? <ClipLoader size={20} color="#1A60A6" loading={loading} /> : 'Confirm'}
                </LinkSignInBtn>
              </MtB5>
            </ContentFooterAreaSignin>
          </MainBgSignin>
        </ImageBackgroundSignin>
      </MainContainer>
    </form>
  );
};

export default withoutAuth(ResetPassword);
