import React, { useState } from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import {
  MainContainer,
  ModalBody,
  ModalHeading,
  InSideBody,
  ModalDescription,
  MtB5,
  LinkSignInBtn,
} from './logoutModal.styles';

const LogoutModal = ({ logout, setShowModel }: { logout: any; setShowModel: any }) => {
  const [visible, setVisible] = useState(true);
  return (
    <MainContainer>
      <Modal
        title="Basic Modal"
        className="sure-modal"
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setShowModel(false)}
        closeIcon={<CloseOutlined color="#BCCBD9" size={30} />}
      >
        <ModalBody>
          <InSideBody>
            <ModalHeading>Are you sure you want to log out?</ModalHeading>
            <ModalDescription>data will remain stored for 3 months.</ModalDescription>
            <MtB5>
              <LinkSignInBtn onClick={logout} type="submit">
                Yes
              </LinkSignInBtn>
              <LinkSignInBtn
                style={{ marginTop: '10px' }}
                onClick={() => setShowModel(false)}
                type="submit"
              >
                No
              </LinkSignInBtn>
            </MtB5>
          </InSideBody>
        </ModalBody>
      </Modal>
    </MainContainer>
  );
};

export default LogoutModal;
