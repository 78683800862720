import styled from 'styled-components';

export const ReviewHeaderDiv = styled.div`
  margin: 20px 0px 0px 0px;
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const ReviewTitle = styled.h1`
  font-size: 25px;
  color: #F5F8F9;
  text-align: center;
  font-family: 'Conv_Georgia Regular font';
  line-height: 50px;
  font-weight: 500;
`;

export const VideoContainer = styled.div`
  width: 100%;
`;

export const Video = styled.video`
  width: 100%;
  margin-bottom: 20px;
`;
