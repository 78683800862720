import React from 'react';
import {
  ThankYouContainer,
  ImageBackgroundThankYou,
  ThankYouBg,
  ContentFooterAreaThankYou,
  ContentThankYou,
  ContentThankYouH5,
  ContentThankYouSpan,
  ContentThankYouP,
  ContentFooterAreaThankYouMtB5,
  SigninBtnThankYou,
} from './ThankYou.styles';

const ThankyouComp = () => (
  <ThankYouContainer>
    <ImageBackgroundThankYou>
      <ThankYouBg>
        <ContentFooterAreaThankYou>
          <ContentThankYou>
            <ContentThankYouH5>
              <ContentThankYouSpan>Thank you for using</ContentThankYouSpan>
              <br />
              CHEX.AI
            </ContentThankYouH5>
            <ContentThankYouP>
              {`You may now exit our app and
            one of the representatives 
            will reach out to you for the
            next steps.`}
            </ContentThankYouP>
          </ContentThankYou>
          <div style={{ background: 'green' }}>
            <ContentFooterAreaThankYouMtB5>
              <SigninBtnThankYou to="/vehiclestatus">Next</SigninBtnThankYou>
            </ContentFooterAreaThankYouMtB5>
          </div>
        </ContentFooterAreaThankYou>
      </ThankYouBg>
    </ImageBackgroundThankYou>
  </ThankYouContainer>
);
export default ThankyouComp;
