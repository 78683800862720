import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  width: 100%;
`;

export const LeftSidebar = styled.div`
  background: linear-gradient(186.11deg, #1876cc -17.67%, #001b51 115.19%),
    #191a1b;
  margin: 0;
  padding: 0;
  width: 300px;
  background-color: #f1f1f1;
  position: fixed;
  height: 100%;
  padding-left: 50px;
  padding-top: 20px;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 96px;
    line-height: 96px;
    color: #ffffff;
    margin: 20px 0 0 0;
    @media screen and (max-width: 880px) {
      display: none;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    margin: 0;
    @media screen and (max-width: 880px) {
      display: none;
    }
  }

@media screen and (max-width: 880px) {
    width: 100%;
    height: auto;
    position: relative;
  }
`;

export const LeftSidebarLink = styled(Link)`
  border: 0;
`;

export const NotifyMenu = styled.ul`
  background: linear-gradient(348.56deg, #191a1b 13.16%, #142e47 100%);
  border-radius: 3px;
  padding: 20px;
  position: relative;
  right: 0px;
  width: 107%;
  margin-top:20px;

  li {
    list-style: none;
  }
  @media screen and (max-width: 880px) {
    display: none;
  }
`;

export const NotifyMenuLink = styled(Link)`
  color: #9ab6df;
  text-decoration: none;
  display: block;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    padding-right: 20px;
    color: #fff;
  }
  i {
    float: right;
    padding-top: 10px;
    color: #414a56;
  }
  :hover {
    color: #ff7a00 !important;
    span {
      color: #ff7a00 !important;
    }
    i {
      color: #ff7a00 !important;
    }
  }
`;

export const LeftMenu = styled.ul`
  padding: 20px;
  li {
    list-style: none;
    margin-bottom: 23px;
    @media screen and (max-width: 580px) {
      list-style: none;
      margin-bottom: 23px;
      text-align: left;
      width: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    padding: 0px;
  }
`;

export const LeftMenuLink = styled(Link)`
  color: #87a7cd;
  text-decoration: none;
  svg {
    padding-right: 20px;
  }
  :hover {
    color: #fff;
  }
  :last-child {
    position: relative;
    bottom: -30px;
  }
  @media screen and (max-width: 991px) {
    margin-right: 20px;
    i{
      padding-right: 10px;
    }
  }
`;

export const RightContent = styled.div`
  margin-left: 300px;
  padding: 1px 0px;
  background-color: #e5e5e5;
  @media screen and (max-width: 1024px) {
    background: none;
  }
`;

export const TopBar = styled.div`
  background: linear-gradient(268.75deg, #ffffff 80.6%, #f0f2f6 99.93%);
  padding: 25px 20px 0;
  h1 {
    color: #000;
    margin: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    color: #1467b8;
    border-bottom: 1px solid #1467b8;
    padding-bottom: 15px;
    display: flex;
    @media screen and (max-width: 1024px) {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 580px) {
    margin: 0 auto;
    width: 100%;
  }
`;

export const TopBarTitle = styled.div`
  width: 20%;
  display: inline-block;
  vertical-align: top;
  margin-right: 10%;
  @media screen and (max-width: 1126px) {
    width: 20%;
  }
  @media screen and (max-width: 991px) {
    width: 20%;
  }
  @media screen and (max-width: 580px) {
    width: 100%;
    text-align: center;
  }
`;

export const TopbarSearch = styled.div`
  width: 39%;
  display: inline-block;
  vertical-align: top;
  #myInput {
    background: url("../images/searchicon.png");
    background-position: 10px 12px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    margin-bottom: 12px;
    border: 1px solid #e9ebf4;
    box-sizing: border-box;
    border-radius: 3px;
    ::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #c4cbd7;
    }
  }
  @media screen and (max-width: 1126px) {
    width: 36%;
  }
  @media screen and (max-width: 991px) {
    width: 30%;
  }
  @media screen and (max-width: 580px) {
    width: 100%;
    margin-top: 20px;
  }
`;

export const TopbarNotifications = styled.div`
  width: 30%;
  display: inline-block;
  vertical-align: top;
  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #4a5059;
    margin: 0px;
    text-align: right;
    float: right;
    padding-right: 20px;
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      text-align: right;
      color: #c4cbd7;
    }
  }
  img {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    float: right;
  }
  @media screen and (max-width: 1126px) {
    width: 30%;
  }
  @media screen and (max-width: 991px) {
    width: 36%;
  }
  @media screen and (max-width: 580px) {
    width: 100%;
  }
`;

export const Dropbtn = styled.button`
  background: none !important;
  border: 0px !important;
  i {
    color: #ff7a00;
    font-size: 16px;
    border: none;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  float: right;
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  z-index: 1;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
`;

export const DropdownContentLink = styled(Link)`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  :hover {
    background-color: #ddd;
  }
`;

// .dropdown:hover .dropdown-content {display: block;}

// .dropdown:hover .dropbtn {background-color: #3e8e41;}

export const ContentTable = styled.div`
  width: 100%;
  padding: 30px;
  width: 100%;
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    padding: 20px;
    width: 100%;
    display: block;
  }
`;

export const ColHead = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-transform: uppercase;
  color: #9cabba;
  padding-bottom: 10px;
  border-bottom: 1px solid #dde2ec;
  width: 100%;
  margin-bottom: 20px;
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
`;

export const ColHead1 = styled.div`
  width: 13%;
  display: inline-block;
  padding-left: 20px;
  @media screen and (max-width: 1261px) {
    width: 13%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

export const ColHead2 = styled.div`
  width: 18%;
  display: inline-block;
  padding-left: 18px;
  @media screen and (max-width: 1261px) {
    width: 17%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

export const ColHead3 = styled.div`
  width: 18%;
  display: inline-block;
  padding-left: 18px;
  @media screen and (max-width: 1261px) {
    width: 17%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

export const ColHead4 = styled.div`
  width: 18%;
  display: inline-block;
  padding-left: 18px;
  @media screen and (max-width: 1261px) {
    width: 17%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

export const ColHead5 = styled.div`
  width: 18%;
  display: inline-block;
  padding-left: 18px;
  @media screen and (max-width: 1261px) {
    width: 17%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
  }
`;

export const ColBody = styled.div`
  background: #ffffff;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  width: 100%;
  padding: 15px 0;
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    border: 1px solid #ccc;
  }
`;

export const ColBody1 = styled.div`
  color: #ff7a00;
  width: 13%;
  display: inline-block;
  padding-left: 20px;
  a {
    color: #ff7a00;
    text-decoration: none;
  }
  @media screen and (max-width: 1261px) {
    width: 13%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    width: 100%;
    padding-bottom: 10px;
    :before {
      position: absolute;
      top: 0px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: 'ID #';
    }
  }
`;

export const ColBody2 = styled.div`
  color: #414b55;
  width: 18%;
  display: inline-block;
  padding-left: 18px;
  a {
    color: #414b55;
    text-decoration: none;
  }
  @media screen and (max-width: 1261px) {
    width: 17%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    width: 100%;
    padding-bottom: 10px;
    :before {
      position: absolute;
      top: 0px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: 'REQUESTOR';
    }
  }
`;

export const ColBody3 = styled.div`
  color: #667d94;
  width: 18%;
  display: inline-block;
  padding-left: 18px;
  a {
    color: #667d94;
    text-decoration: none;
  }
  @media screen and (max-width: 1261px) {
    width: 17%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    width: 100%;
    padding-bottom: 10px;
    :before {
      position: absolute;
      top: 0px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: 'REQUESTOR DATE';
    }
  }
`;

export const ColBody4 = styled.div`
  color: #1467b8;
  width: 18%;
  display: inline-block;
  padding-left: 18px;
  a {
    color: #1467b8;
    text-decoration: none;
  }
  @media screen and (max-width: 1261px) {
    width: 17%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    width: 100%;
    padding-bottom: 10px;
    :before {
      position: absolute;
      top: 0px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: 'REVIEW LINK';
    }
  }
`;

export const ColBody5 = styled.div`
  color: #e46d6d;
  width: 18%;
  display: inline-block;
  padding-left: 18px;
  a {
    color: #e46d6d;
    text-decoration: none;
  }
  @media screen and (max-width: 1261px) {
    width: 17%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    width: 100%;
    padding-bottom: 10px;
    :before {
      position: absolute;
      top: 0px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: 'STATUS';
    }
  }
`;

export const ColBody6 = styled.div`
  color: #e46d6d;
  width: 3%;
  display: inline-block;
  @media screen and (max-width: 1261px) {
    width: 3%;
  }
  @media screen and (max-width: 1024px) {
    width: 50%;
  }
  @media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
    display: block;
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    width: 100%;
    padding-bottom: 10px;
    :before {
      position: absolute;
      top: 0px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      content: ' ';
    }
  }
`;

export const ContentRow = styled.div`
  width:100%;
`;

export const ColContent = styled.div`
  width:100%;
`;

export const ColContenth5 = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #FC7900;
`;

export const ColContentCol = styled.div`
  width:14%;
  float: left;
  border-right:1px solid #D4DFEC;
  padding-left:20px;
  :first-child{
    padding-left:0px;
  }
  :last-child{
    border-right:0px solid #D4DFEC;
  }
`;

export const ColContentColH6 = styled.h6`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #667D94;
  margin:0px;
`;

export const ColContentColH3 = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #414B55;
`;

export const GalleryArea = styled.div`
  width:70%;
  float: left;
`;

export const Container = styled.div`
  position: relative;
`;

export const GalleryListing = styled.div`
  width:27%;
  float: left;
  margin-left:3%;
`;

export const ColColumn = styled.div`
  float: left;
  width: 18%;
  padding: 7px;
`;

export const ImageText = styled.div`
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
  font-size: 20px;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  font-size: 35px;
  cursor: pointer;
`;

export const ContentPara = styled.div`
  font-size:14px;
  line-height:30px;
`;

export const ContentParaH3 = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-transform: capitalize;
  color: #414B55;
`;

export const ContentParaP = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #738292;
`;

export const Column = styled.div`
  float: left;
  width: 18%;
  padding: 7px;
`;
