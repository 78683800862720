import React from 'react';
import {
  ContentTable, ContentRow, ColContent, ColContenth5,
  ColContentCol, ColContentColH6, ColContentColH3, GalleryArea,
  Container, ContentPara, ContentParaH3, ContentParaP, Column,
} from '../DashboardComponent/DashboardPage.styles';

const DashboardInspectionContentComp = () => (
  <ContentTable>
    <ContentRow>
      <ColContent>
        <ColContenth5>
          Vehicle information
          <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.58679 0.293044C5.97731 -0.0974808 6.61048 -0.0974803 7.001 0.293044L12.4403 5.73239C12.7553 6.04737 12.5322 6.58594 12.0868 6.58594H0.501002C0.0555495 6.58594 -0.167533 6.04737 0.147449 5.73238L5.58679 0.293044Z"
              fill="#FF7A00"
            />
          </svg>
        </ColContenth5>
      </ColContent>
      <ColContent>
        <ColContentCol>
          <ColContentColH6>Vehicle Make:</ColContentColH6>
          <ColContentColH3>Honda</ColContentColH3>
        </ColContentCol>
        <ColContentCol>
          <ColContentColH6>Vehicle Model:</ColContentColH6>
          <ColContentColH3>Civic</ColContentColH3>
        </ColContentCol>
        <ColContentCol>
          <ColContentColH6>Vehicle Year:</ColContentColH6>
          <ColContentColH3>2017</ColContentColH3>
        </ColContentCol>
        <ColContentCol>
          <ColContentColH6>Vin#:</ColContentColH6>
          <ColContentColH3>1HG00000000</ColContentColH3>
        </ColContentCol>
        <ColContentCol>
          <ColContentColH6>Plate#:</ColContentColH6>
          <ColContentColH3>5MDS007</ColContentColH3>
        </ColContentCol>
        <ColContentCol>
          <ColContentColH6>Color:</ColContentColH6>
          <ColContentColH3>Metallic Grey</ColContentColH3>
        </ColContentCol>
      </ColContent>
      <ColContent>
        <GalleryArea>
          <Container>
            <img id="expandedImg" style={{ width: '100%' }} alt="" src="images/gallery_img1.jpg" />
            <div id="imgtext" />
          </Container>
          <div className="row" id="activated">
            <Column>
              <img src="images/gallery_img1.jpg" alt="" style={{ width: '100%' }} />
              <div className="centered">
                1
              </div>
            </Column>
            <Column>
              <img src="images/gallery_img2.jpg" alt="" style={{ width: '100%' }} />
              <div className="centered">
                2
              </div>
            </Column>
            <Column>
              <img src="images/gallery_img3.jpg" alt="" style={{ width: '100%' }} />
              <div className="centered">
                3
              </div>
            </Column>
            <Column>
              <img src="images/gallery_img4.jpg" alt="" style={{ width: '100%' }} />
              <div className="centered">
                4
              </div>
            </Column>
            <Column>
              <img src="images/gallery_img2.jpg" alt="" style={{ width: '100%' }} />
              <div className="centered">
                5
              </div>
            </Column>
          </div>
          <ContentPara>
            <ContentParaH3>
              1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Habitasse.
            </ContentParaH3>
            <ContentParaP>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. At et maecenas ac proin nunc
              adipiscing eleifend. Metus dui nulla lacus blandit eu purus. Lorem velit amet arcu
              arcu quis et, dictum amet mollis. Turpis sed tortor montes, in dignissim dolor.
              Nec et a nam nullam. Purus donec facilisis nibh tempus dolor quis sapien maecenas.
              Montes, pharetra pellentesque natoque accumsan tincidunt mauris. Maecenas suscipit sed
              egestas purus, lectus et, felis leo, lacus. Felis maecenas aliquam cras eget enim.
            </ContentParaP>
          </ContentPara>
        </GalleryArea>
        <div className="gallery_listing">
          <div className="box_area_grey">
            <div className="left_list_heading">Exterior</div>
            <div className="right_list_numbering">
              1
              <small>/5</small>
            </div>
          </div>
          <div className="list_vertical">
            <div className="pallete_title_with_wrong_right">
              <div className="pallete_title">Vehicle information</div>
              <div className="pallete_wrong_right">
                <i className="fas fa-check" />
                <i className="fas fa-times" />
              </div>
            </div>

            <div className="pallete_title_with_wrong_right">
              <div className="pallete_title">Vehicle information</div>
              <div className="pallete_wrong_right">
                <i className="fas fa-check" />
                <i className="fas fa-times" />
              </div>
            </div>

            <div className="pallete_title_with_wrong_right">
              <div className="pallete_title">Vehicle information</div>
              <div className="pallete_wrong_right">
                <i className="fas fa-check" />
                <i className="fas fa-times" />
              </div>
            </div>

            <div className="pallete_title_with_wrong_right">
              <div className="pallete_title">Vehicle information</div>
              <div className="pallete_wrong_right">
                <i className="fas fa-check" />
                <i className="fas fa-times" />
              </div>
            </div>

            <div className="pallete_title_with_wrong_right">
              <div className="pallete_title">Vehicle information</div>
              <div className="pallete_wrong_right">
                <i className="fas fa-check" />
                <i className="fas fa-times" />
              </div>
            </div>

            <p>Lorem ipsum dolor sit amet, consectetur </p>
          </div>
        </div>
      </ColContent>
    </ContentRow>
  </ContentTable>
);

export default DashboardInspectionContentComp;
