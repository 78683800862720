/* eslint-disable */

import React, { Component } from "react";
import ReactCrop from "react-image-crop";
import Tesseract from "tesseract.js";
import { ClipLoader } from "react-spinners";

import {
  OcrFooter,
  FooterTextContainer,
  OrangeTextOcr,
  OcrWhiteText,
  CameraImageContainer,
  RedOcrtext,
} from "./AddVehiclePage.styles";

import "react-image-crop/dist/ReactCrop.css";
import "./style.css";

class CropVinNumber extends Component<any, any> {
  imageRef: any;

  fileUrl: any;

  constructor(props: any) {
    super(props);
    this.state = {
      uploads: [],
      log: 1,
      crop: {
        unit: "%",
        width: 30,
        aspect: 16 / 9,
      },
    };
  }

  generateText = () => {
    const { closeOcrModal } = this.props;
    Tesseract.recognize(this.state.croppedImageUrl, "eng", {
      logger: (m: any) => this.setState({ log: m }),
    }).then(({ data: { text } }: any) => {
      alert(text);
      closeOcrModal(text);
    });
  };

  onCropComplete = (crop: any) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop: any, percentCrop: any) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl }, () => {
        this.props.getCroppedImageUrl(croppedImageUrl);
      });
    }
  }

  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          // reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        // window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  handleChange = (event: any) => {
    if (event.target.files[0]) {
      const uploads = [];
      for (const key in event.target.files) {
        if (!event.target.files.hasOwnProperty(key)) continue;
        const upload = event.target.files[key];
        uploads.push(URL.createObjectURL(upload));
      }
      this.setState({
        uploads,
      });
    } else {
      this.setState({
        uploads: [],
      });
    }
  };

  render() {
    return (
      <div
        style={{
          background: "rgba(0,0,0,0.8)",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            padding: "20px",
            background: "#000000",
            width: "100%",
          }}
        >
          <img src="/images/flash.svg" />
        </div>
        <div>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
          </section>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              textAlign: "center",
              // zIndex: 200,
            }}
          >
            <OrangeTextOcr >Your Vin code has been read</OrangeTextOcr>
            <OcrWhiteText style={
              this.state.uploads.length === 0 ? { marginBottom: '50px' } : { marginBottom: '20px' }
            }>please check the code for errors</OcrWhiteText>

            <img
              src="/images/downArrowOcr.svg"
              // style={[{ marginBottom: "20px" }]}
              style={this.state.uploads.length === 0 ? { marginBottom: '80px' } : { marginBottom: '10px' }}
            />
            <div>
              {this.state.uploads.length === 0 ? null : (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: '20px',
                    // maxHeight: '100px'
                  }}
                >
                  <ReactCrop
                    src={this.state.uploads[0]}
                    crop={this.state.crop}
                    ruleOfThirds
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                  />
                </div>
              )}
            </div>

            <CameraImageContainer>
              <label
                className="fileUploaderContainer"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <img src="/images/redCamera.svg" alt="camera" />
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="fileUploader"
                  onChange={this.handleChange}
                />
              </label>
              <RedOcrtext>Try Again</RedOcrtext>
            </CameraImageContainer>
          </div>
          <OcrFooter>
            <FooterTextContainer onClick={() => this.props.closeOcrModal()}>
              <img src="/images/backImageSvg.svg" />
              <p
                style={{
                  color: "#767D84",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  marginLeft: "20px",
                }}
              >
                Enter manually
              </p>
            </FooterTextContainer>
            <div>
              {this.state.uploads[0] ?
                <button
                  style={{
                    position: "relative",
                    width: "60px",
                    height: "60px",
                    borderRadius: "50%",
                    background: "#2977EC",
                    border: "none",
                  }}
                  onClick={this.generateText}
                >
                  {this.state.log === 1 ? (
                    <img
                      src="/images/checkOcr.svg"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "25px",
                        width: "25px",
                      }}
                    />
                  ) : (
                      <ClipLoader
                        size={20}
                        color="white"
                        loading={this.state.log !== 1}
                      />
                    )}
                </button> :
                null
              }
            </div>
          </OcrFooter>
        </div>
      </div>
    );
  }
}

export default CropVinNumber;
