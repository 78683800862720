import React from 'react';
import Modal from 'react-modal';

import {
  ThankYouContainer,
  ImageBackgroundThankYou,
  ThankYouBg,
  ContentFooterAreaThankYou,
  ContentThankYou,
  ContentThankYouH5,
  ContentThankYouSpan,
  ContentThankYouP,
  ContentFooterAreaThankYouMtB5,
  SigninBtnThankYou,
} from './ThankYouModal.styles';

const customStyles = {
  content: {
    width: '100%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: ' 100%',
    backgroundColor: '#12579b',
  },
};

export const ThankyouModal = ({ isOpen }: { isOpen: boolean }) => (
  <Modal
    isOpen={isOpen}
    // onAfterOpen={afterOpenModal}
    // onRequestClose={() => setShowModalValue(false)}
    style={customStyles}
  >
    <ThankYouContainer>
      <ImageBackgroundThankYou>
        <ThankYouBg>
          <ContentFooterAreaThankYou>
            <ContentThankYou>
              <ContentThankYouH5>
                <ContentThankYouSpan>Thank you for using</ContentThankYouSpan>
                <br />
                CHEX.AI
              </ContentThankYouH5>
              <ContentThankYouP>
                {`You may now exit our app 
              and one of the
              representatives will reach 
              out to you for the next steps.`}
              </ContentThankYouP>
            </ContentThankYou>
            <ContentFooterAreaThankYouMtB5>
              <SigninBtnThankYou to="/vehiclestatus">Next</SigninBtnThankYou>
            </ContentFooterAreaThankYouMtB5>
          </ContentFooterAreaThankYou>
        </ThankYouBg>
      </ImageBackgroundThankYou>
    </ThankYouContainer>
  </Modal>
);
