import styled from 'styled-components';

export const UploadButton = styled.button`
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  text-align: center;
  /* padding: 13px; */
  /* border-radius: 4%; */
  width: 30%;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  border-color: transparent;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
  :focus {
    outline: none;
    border: none;
  }
`;

export const ImageContainerUpload = styled.div`
  height: 200px;
  width: 30%;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const ImageContainerMainUpload = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CrossIcon = styled.div`
  color: white;
  font-size: 25px;
  float: right;
  cursor: pointer;
`;

export const CrossContainer = styled.div`
  padding-bottom: 16px;
`;

export const UploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 45px;
`;

export const ImageCapture = styled.input`
  display: none;
`;
export const VideoCapture = styled.input`
  display: none;
`;

export const VehicalDetailContainer = styled.div`
  margin-bottom: 13px;
`;

export const InsertDetailsLabel = styled.label`
  padding-bottom: 8px;
  padding-left: 10px;
  display: block;
`;

export const VehicalDetailDiv = styled.div`
  padding-left: 10px;
  background: rgb(228, 226, 226);
  padding-top: 13px;
  padding-bottom: 13px;
`;

export const CrossIconVehicleDetail = styled.div`
  font-size: 25px;
  float: right;
  cursor: pointer;
`;

export const UploadLabel = styled.label`
  padding: 14px 49% 14px 48%;
  width: 100%;
  margin-left: -13px;
  width: 100%;
`;

export const VehicleInstructionMainContainer = styled.div`
  width: 30%;
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const VehicleTitleWrapper = styled.div`
  text-align: center;
  padding-top: 13px;
`;

export const VehicleTitle = styled.div`
  color: white;
  font-size: 19px;
`;

export const VehicleInstructionDescription = styled.div`
  text-align: center;
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const VehicleInstructionImageContainer = styled.div`
  div video {
    border-radius: 20px !important;
  }
`;

export const VehicleInstructionImage = styled.img`
  border-radius: 4px;
  width: 100%;
`;

export const VehicleInstructionStepsContainer = styled.div`
  margin-top: 40px;
`;

export const VehicleInstructionStep = styled.div`
padding-top: 40px; 
color: white';
`;
