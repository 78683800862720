import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Sidenav = styled.div`
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  @media screen and (max-height: 450px) {
    padding-top: 15px;
  }
`;

export const SidebarLink = styled.button`
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  border: 0px solid #efefef;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  color: #fff;
  line-height: 60px;
  outline:none;
  background:none;
  position:absolute;
  right:20px;
  :hover {
    color: #2A2A2A;
  }
  svg{
    padding-right:20px;
  }
  @media screen and (max-height: 450px) {
    font-size: 18px;
  }
`;

export const SidebarLinkAnc = styled(Link)`
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  border-bottom: 1px solid #efefef;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #2A2A2A;
  line-height: 60px;
  :hover {
    color: #2A2A2A;
  }
  svg{
    padding-right:20px;
  }
  @media screen and (max-height: 450px) {
    font-size: 18px;
  }
`;

export const SidebarLinkButton = styled.button`
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
  width: 100%;
  text-align: left;
  cursor: pointer;
  border: 0;
  background: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #2A2A2A;
  line-height: 60px;
  :hover {
    color: #2A2A2A;
  }
  svg{
    padding-right:20px;
  }
  @media screen and (max-height: 450px) {
    font-size: 18px;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  border:0px;
  color: #fff;
`;

export const MySlideNavH1 = styled.h1`
  background: rgb(8,29,62), url(../images/final_car_img.png);
  background: -moz-linear-gradient(270deg, rgba(255, 122, 0, 0.8) 0%, rgba(255, 153, 0, 0.8) 100%), url(../images/final_car_img.png);
  background: -webkit-linear-gradient(270deg, rgba(255, 122, 0, 0.8) 0%, rgba(255, 153, 0, 0.8) 100%), url(../images/final_car_img.png);
  background: linear-gradient(270deg, rgba(255, 122, 0, 0.8) 0%, rgba(255, 153, 0, 0.8) 100%), url(../images/final_car_img.png);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FF7A00",endColorstr="#FF9900",GradientType=1), url(../images/final_car_img.png);
  mix-blend-mode: normal;
  border-radius: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 84px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  margin-top:0px;
  padding:70px 0;
  background-size: 170%;
  background-position:center;
`;
