import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  width: 100%;
`;

export const ImageBackgroundPaymentinfo = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const MainDownContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  background: #1b68b3;
  // background: 'linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%)';
  background-repeat: no-repeat;
  width: 100%;
  background-size: 205%;
  background-position: center top;

  @media screen and (max-width: 700px) {
    background: none;
    margin-top: 0px;
    width: 100%;
    height: 100vh;
  }
`;

export const MainBgPaymentInfo = styled.div`
  background: linear-gradient(185.51deg, #1876cc -17.67%, #051532 115.19%), #191a1b;
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const ContentPaymentInfo = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const ContentPaymentinfoH5 = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 8px;
  text-align: center;
  color: #ffffff;
  margin: 39px 0;
`;

export const ContentPaymentInfoH5Span = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;
  margin: 0 auto;
  color: #ffffff;
`;

export const ContentPaymentinfoH1 = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 96px;
  text-align: center;
  color: #fff;
  letter-spacing: 0.05em;
  margin: 0px 0 0 0;
`;

export const ContentPaymentInfoSpan = styled.span`
  font-size: 40px;
  text-align: center;
`;

export const ContentPaymentInfoSpanDot = styled.span`
  font-family: 'Courier New', Courier, monospace;
  font-size: 30px;
  letter-spacing: 0px;
  text-align: left;
`;

export const ContentPaymentinfoSpanArrowBack = styled.span`
  color: #2584e0;
  position: absolute;
  left: 30px;
  top: 30px;
  font-size: 14px;
`;

export const ContentPaymentInfoSpanArrowBackA = styled(Link)`
  color: #2584e0;
`;

export const ContentPaymentInfoP = styled.p`
  color: #fff;
  text-align: center;
`;

export const ContentFooterAreaPaymentInfo = styled.div`
  background: #fff;
  border-radius: 30px 30px 0 0;
  margin-top: 0px;
  height: 100%;
  // height: 76.3vh;
  padding: 50px 30px;
  width: 30%;

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

export const ContentFooterAreaBorderInput = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #889bad;

  input {
    background: #e7eef5;
    border: 1px solid #e7eff8;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    margin: 0 auto 20px;
    padding: 15px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #4e565f;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;

    ::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      color: #1262b1;
    }
  }

  input:focus {
    box-shadow: 0px;
    outline: 0px;
    border: 1px solid #1262b1;
  }
`;

export const ContentFooterAreaBorderInputStripe = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #889bad;

  input {
    backgrround: red;
  }
  input,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 15px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background-color: #e7eef5;
    disabled: disabled;
  }
`;

export const ContentFooterAreaPaymentInputType = styled.div`
  position: relative;
  color: #7f7f7f;
  text-align: right;
`;

export const ContentFooterAreaPaymentInputTypeSpan = styled.span`
  position: absolute;
  right: 15px;
  padding: 18px 30px;

  input[type='text'] {
    padding: 0px;
    margin: 0px;
  }
`;

export const ContentFooterAreaPaymentLabel = styled.label`
  padding-bottom: 12px;
  display: block;
  color: #889bad;
  font-size: 16px;
`;

export const ContentFooterAreaPaymentP = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #889bad;
`;

export const ContentFooterAreaPaymenMtB5 = styled.div`
  margin-top: 28px;
  text-align: center;
  margin-bottom: 20px;
`;

export const ContentFooterAreaPaymentExpireCVV = styled.div`
  width: 100%;
`;

export const ContentFooterAreaPaymentExpireDate = styled.div`
  width: 70%;
  display: inline-block;
`;

export const ContentFooterAreaPaymentCVV = styled.div`
  width: 27%;
  display: inline-block;
  margin-left: 3%;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

export const ContentFooterAreaPaymentSignInBtn = styled(Link)`
  background: #ff7a00;
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 14px 104px 15px;
`;

export const PromoConditionText = styled.p`
  color: #1262b1;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  margin: 39px 0;
`;
