import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  width: 100%;
`;

export const ImageBackgroiundExteriorFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const MainBgExteriorFront = styled.div`
  background: linear-gradient(185.51deg, #1876cc -17.67%, #051532 115.19%),
    #191a1b;
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const ContentExteriorFront = styled.div`
  width: 80%;
  margin: 0 auto;
`;

export const ContentExteriorFrontH5 = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 8px;
  text-align: center;
  color: #ffffff;
  margin: 39px 0;
`;

export const ContentExteriorArrowBack = styled.span`
  color: #2584e0;
  position: absolute;
  left: 30px;
  top: 30px;
  font-size: 14px;
`;

export const ContentExteriorSpan = styled.span`
  color:#fff;
`;

export const ContentExteriorA = styled(Link)`
  color:#2584e0;
`;

export const ContentExteriorP = styled.p`
  color: #fff;
  text-align: center;
`;

export const ContentFooterAreaExteriorFront = styled.div`
  padding-top: 41px;
  width: 90%;
  margin: 0 auto;
  img{
    border-radius:5px;
  }
`;

export const ContentFooterAreaExteriorMtB5 = styled.div`
  margin-top: 48px;
  text-align: center;
  margin-bottom: 0px;
`;

export const ContentFooterAreaExteriorBtmImgText = styled.div`
  text-align: left;
  font-size: 14px;
  color: #b2c7f0;
  line-height: 21px;
  width: 94%;
  margin: 0 auto;
  padding-top: 36px;
  padding-bottom: 80px;
`;

export const ContentFooterAreaExteriorPlayBtn = styled.div`
  position: absolute;
  top: 30%;
  left: 40%;
i{
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 21px 20px 21px 25px;
  color: #fc7900;
  font-size: 24px;
}
  `;

export const ContentFooterAreaExteriorSigninBtn = styled(Link)`
  background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
  border-radius: 100px;
  border: 1px solid #ff7400;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  padding: 14px 104px 15px;
`;
