import {
  put, takeEvery, call, take,
} from 'redux-saga/effects';
import { channel } from 'redux-saga';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { FormikValues } from 'formik';
import { removeToken, setToken } from '../api';
import {
  login, forgetPassword, resetPassword, contactUs,
} from '../api/auth';
import {
  getUserFromToken, register, resendEmail, confirmEmail,
} from '../api/users';
import { getSignedUrl, uploadToS3Bucket } from '../api/upload';
import { uploadFile, deleteFileRequest } from '../api/files';
import { ActionTypes } from '../actions/ActionTypes';
import * as AuthActions from '../actions/AuthActions';
import * as VehicleActions from '../actions/VehicleAction';
import {
  vehicle,
  getCompanies,
  getTransactionStatus,
  vehicleStatus,
  getReportData,
  skipPayment,
} from '../api/vehicle';
import { getVinData } from '../api/vinNumber';
import history from '../history';
import { createPayment, getUserTransactions } from '../api/transaction';

interface ValuesAction {
  type: string;
  values: FormikValues;
}

const progressBar: any = channel();
// const delay = () => new Promise(resolve => setTimeout(resolve,1000 ));

export function* loginUserSaga(action: ValuesAction) {
  try {
    yield put(AuthActions.setLoading(true));
    const { data } = yield call(login, action.values);
    if (data.token) yield setToken(data.token);

    yield put(AuthActions.loginUserComplete(data.data));
    yield put(AuthActions.setLoading(false));
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    yield put(AuthActions.setLoading(false));
    toast(payload, { type: 'error' });
  }
}

export function* forgetPasswordSaga(action: ValuesAction) {
  try {
    yield put(AuthActions.setLoading(true));
    console.log('forgetPasswordSaga', action.values);
    const data = yield call(forgetPassword, action.values);
    yield put(AuthActions.forgetPasswordComplete(data));
    toast('Email sent successfully', { type: 'success' });
    if (data) {
      history.push(`/resetPassword?email=${action.values.email}`);
    }
    yield put(AuthActions.setLoading(false));
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    yield put(AuthActions.setLoading(false));
    toast(payload, { type: 'error' });
  }
}

export function* resetPasswordSaga(action: ValuesAction) {
  try {
    yield put(AuthActions.setLoading(true));
    const data = yield call(resetPassword, action.values);
    yield put(AuthActions.resetPasswordComplete(data));
    yield put(AuthActions.setLoading(false));
    if (data) {
      toast('Password updated successfully', { type: 'success' });
      history.push('/login');
    }
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    yield put(AuthActions.setLoading(false));
    toast(payload, { type: 'error' });
  }
}

export function* loginFromTokenSaga() {
  try {
    yield put(VehicleActions.setAddVehicleLoading(true));

    const { data } = yield call(getUserFromToken);

    yield put(AuthActions.loginUserComplete(data.user));
    yield put(VehicleActions.setAddVehicleLoading(false));
  } catch (err) {
    yield removeToken();
    yield put(VehicleActions.setAddVehicleLoading(false));
  }
}

export function* logoutUserSaga() {
  yield removeToken();
  // yield removeCompanyName();
  yield put(AuthActions.logoutUserComplete());
}

export function* registerUserSaga(action: ValuesAction) {
  try {
    yield put(AuthActions.setLoading(true));

    const { data } = yield call(register, action.values);

    if (data.token) yield setToken(data.token);

    yield put(AuthActions.loginUserComplete(data.user));

    yield put(AuthActions.setLoading(false));
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    yield put(AuthActions.setLoading(false));
    toast(payload, { type: 'error' });
  }
}

export function* uploadFileSaga(action: ValuesAction) {
  try {
    yield put(AuthActions.setImageUploadLoading(true));
    const { category, file, vehicleId } = action.values;
    const error = new Error('Unable to upload file!');
    const { type } = file;
    const ext = type;
    const { data } = yield call(getSignedUrl, { type });
    if (!data || !data.url || !data.key) throw error;
    const { url, key } = data as { url: string; key: string };
    const progress = function (progressEvent: any) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      progressBar.put(VehicleActions.imageToken({ percentCompleted, category }));
    };
    const response = yield call(
      uploadToS3Bucket,
      {
        url,
        file,
        type,
      },
      progress,
    );
    if (!response || response.status !== 200) throw error;
    const resp = yield call(uploadFile, {
      url: key,
      category,
      vehicleId,
      ext,
    });
    yield put(
      AuthActions.uploadFileComplete({
        url: key,
        category,
        vehicle_id: vehicleId,
        id: resp.data.id,
      }),
    );
    yield put(AuthActions.setImageUploadLoading(false));
    // yield put(
    //   VehicleActions.imageToken({
    //     category: '',
    //     percentCompleted: 0,
    //   })
    // );
    // yield put(AuthActions.setModalState(false));
  } catch (err) {
    console.log('Error', err);
    yield put(AuthActions.setImageUploadLoading(false));
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast(payload, { type: 'error' });
  }
}

export function* deleteFileSaga(action: ValuesAction) {
  console.log('action', action.values);
  try {
    const { catagory } = action.values;
    const { data } = yield call(deleteFileRequest, action.values);
    if (data.token) yield setToken(data.token);
    yield put(
      VehicleActions.imageToken({
        category: catagory,
        percentCompleted: 0,
      }),
    );
    yield put(AuthActions.deleteFileComplete({ fileId: action.values.id }));
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast(payload, { type: 'error' });
  }
}

export function* isVehicalInspectionModalSaga(action: any) {
  yield put(AuthActions.setModalState(action.payload));
}

export function* vehicleStatusChangedSaga(action: any) {
  try {
    yield put(VehicleActions.setAddVehicleLoading(true));
    yield call(vehicleStatus, action.values);
    const { data } = yield call(getUserFromToken);
    yield put(AuthActions.loginUserComplete(data.user));
    yield put(VehicleActions.setAddVehicleLoading(false));
    history.replace('vehiclestatus');
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    yield put(VehicleActions.setAddVehicleLoading(false));
    toast(payload, { type: 'error' });
  }
}

export function* isPaymentMethodSkipSaga(action: any) {
  try {
    yield put(VehicleActions.setAddVehicleLoading(true));
    yield put(VehicleActions.setThankYouModalState(true));
    yield call(skipPayment, action.values);
    const { data } = yield call(getUserFromToken);
    yield put(AuthActions.loginUserComplete(data.user));
    yield put(VehicleActions.setAddVehicleLoading(false));

    // history.replace('/vehiclestatus');
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    yield put(VehicleActions.setAddVehicleLoading(false));
    yield put(VehicleActions.setThankYouModalState(false));
    toast(payload, { type: 'error' });
  }
}

export function* setThankYouModalSaga(action: any) {
  yield put(VehicleActions.setThankYouModalState(action.payload));
}

// export function* setVehicleDetailModalSaga(action: any) {
//   yield put(VehicleActions.setVehicleDetailModal(action.payload));
// }

export function* addVehicleSaga(action: ValuesAction) {
  try {
    yield put(VehicleActions.setAddVehicleLoading(true));
    const { data } = yield call(vehicle, action.values);
    if (data.token) yield setToken(data.token);

    yield put({ type: ActionTypes.LoginFromToken });
    yield put(VehicleActions.setVehicleDetailModal(false));
    yield put(VehicleActions.setAddVehicleLoading(false));
    history.push('/doAndDont');
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    yield put(VehicleActions.setAddVehicleLoading(false));
    toast(payload, { type: 'error' });
  }
}

export function* getVinNumberSaga(action: ValuesAction) {
  try {
    yield put(AuthActions.setLoading(true));
    const { data } = yield call(getVinData, action.values);
    yield put(VehicleActions.vehicleDetailModalData(data));
    yield put(VehicleActions.setVehicleDetailModal(true));
    yield put(VehicleActions.getVinNumberComplete(data.data));
    yield put(AuthActions.setLoading(false));
  } catch (err) {
    // const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    // toast(payload, { type: 'error' });
    yield put(AuthActions.setLoading(false));
    console.log(err);
  }
}

export function* resendEmailSaga(action: ValuesAction) {
  try {
    yield call(resendEmail, action.values.email);
    toast('Email sent successfully!', { type: 'success' });
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast(payload, { type: 'error' });
  }
}

export function* confirmEmailSaga(action: ValuesAction) {
  try {
    yield call(confirmEmail, action.values);
    toast('Email verified successfully!', { type: 'success' });
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast(payload, { type: 'error' });
  }
  history.push('/login');
}

export function* createPaymentSaga(action: ValuesAction) {
  try {
    yield put(VehicleActions.setAddVehicleLoading(true));
    yield call(createPayment, action.values);
    console.log('PAYMENT ACTION', action.values);
    yield put(VehicleActions.setThankYouModalState(true));
    history.push('/vehicleInspection');
    yield put(VehicleActions.setAddVehicleLoading(false));
  } catch (err) {
    console.log('ERROR FROM API', err);
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast(payload, { type: 'error' });
    yield put(VehicleActions.setThankYouModalState(false));
    yield put(VehicleActions.setAddVehicleLoading(false));
  }
}

export function* transactionHistorySaga(action: ValuesAction) {
  try {
    yield put(VehicleActions.setAddVehicleLoading(true));
    const { data } = yield call(getUserTransactions, action.values);
    yield put(AuthActions.setTransactionHistory(data));
    yield put(VehicleActions.setAddVehicleLoading(false));
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast(payload, { type: 'error' });
    yield put(VehicleActions.setThankYouModalState(false));
    yield put(VehicleActions.setAddVehicleLoading(false));
  }
}

export function* getVehicleCompanySaga() {
  try {
    // yield put(VehicleActions.setAddVehicleLoading(true));
    const { data } = yield call(getCompanies);
    yield put(VehicleActions.getVehcicleCompaniesComplete(data));
    // yield put(AuthActions.setTransactionHistory(data));
    // yield put(VehicleActions.setAddVehicleLoading(false));
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast(payload, { type: 'error' });
    // yield put(VehicleActions.setThankYouModalState(false));
    // yield put(VehicleActions.setAddVehicleLoading(false));
  }
}

export function* getReportDataSaga(action: any) {
  try {
    const { data } = yield call(getReportData, action.payload);
    yield put(VehicleActions.certificateData(data));
  } catch (err) {
    // const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast('Unauthorized user', { type: 'error' });
    history.replace('/login');
  }
}

export function* getVehicleTransactionStatusSaga(action: any) {
  try {
    yield put(VehicleActions.setAddVehicleLoading(true));
    const { data } = yield call(getTransactionStatus, action.payload);
    yield put(VehicleActions.setTransactionStatus(data));
    yield put(VehicleActions.setAddVehicleLoading(false));
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    toast(payload, { type: 'error' });
  }
}

export function* contactUsSaga(action: any) {
  try {
    yield put(VehicleActions.setAddVehicleLoading(true));

    yield call(contactUs, action.payload);
    yield put(VehicleActions.setAddVehicleLoading(false));

    // const { data } = yield call(getUserFromToken);
    toast('Thank you! Your email has been sent. We will get back to you shortly.', { type: 'success' });
    // history.replace('/vehiclestatus');
  } catch (err) {
    const payload = get(err, 'response.data.message[0]', 'Something went wrong!');
    yield put(VehicleActions.setAddVehicleLoading(false));
    toast(payload, { type: 'error' });
  }
}

export function* watchProgressSaga() {
  while (true) {
    const action = yield take(progressBar);
    yield put(action);
  }
}

export default function* watchAuth() {
  yield takeEvery(ActionTypes.LoginUserStart, loginUserSaga);
  yield takeEvery(ActionTypes.LoginFromToken, loginFromTokenSaga);
  yield takeEvery(ActionTypes.setContactUs, contactUsSaga);
  yield takeEvery(ActionTypes.ForgetPasswordStart, forgetPasswordSaga);
  yield takeEvery(ActionTypes.RestPasswordStart, resetPasswordSaga);
  yield takeEvery(ActionTypes.LogoutUserStart, logoutUserSaga);
  yield takeEvery(ActionTypes.transactionHistory, transactionHistorySaga);

  yield takeEvery(ActionTypes.RegisterUserStart, registerUserSaga);
  yield takeEvery(ActionTypes.UploadFileStart, uploadFileSaga);
  yield takeEvery(ActionTypes.DeleteFile, deleteFileSaga);
  yield takeEvery(ActionTypes.AddVehicleStart, addVehicleSaga);
  yield takeEvery(ActionTypes.IsVehicalInspectionModal, isVehicalInspectionModalSaga);
  yield takeEvery(ActionTypes.setThankYouModalValue, setThankYouModalSaga);
  // yield takeEvery(ActionTypes.setVehicleDetailModal, setVehicleDetailModalSaga);
  yield takeEvery(ActionTypes.getVinNumber, getVinNumberSaga);
  yield takeEvery(ActionTypes.EmailVerificationStart, resendEmailSaga);
  yield takeEvery(ActionTypes.ConfirmEmailStart, confirmEmailSaga);
  yield takeEvery(ActionTypes.CreatePaymentStart, createPaymentSaga);
  yield takeEvery(ActionTypes.getVehicleCompanies, getVehicleCompanySaga);
  yield takeEvery(ActionTypes.getGeneralCertificate, getReportDataSaga);
  yield takeEvery(ActionTypes.getVehicleTransactionStatus, getVehicleTransactionStatusSaga);
  yield takeEvery(ActionTypes.vehicleStatusChanged, vehicleStatusChangedSaga);
  yield takeEvery(ActionTypes.skipPaymentMethod, isPaymentMethodSkipSaga);
  yield takeEvery(progressBar, watchProgressSaga);
}
