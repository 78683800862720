import React from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import {
  MainContainer, ImageBackgroiundExteriorFront, MainBgExteriorFront,
  ContentExteriorFront, ContentExteriorFrontH5, ContentExteriorArrowBack,
  ContentExteriorA, ContentExteriorSpan, ContentExteriorP, ContentFooterAreaExteriorFront,
  ContentFooterAreaExteriorPlayBtn, ContentFooterAreaExteriorBtmImgText,
  ContentFooterAreaExteriorMtB5, ContentFooterAreaExteriorSigninBtn,
} from './InstructionsPage.styles';
import { RootState } from '../../reducers';
import { withAuth } from '../../hocs/withAuth';

const InstructionsPage = () => {
  const { id } = useParams() as { id: string };
  const docs = useSelector(({ instructions }: RootState) => instructions.instructions);

  const doc = docs.find((x) => x.id === id);

  if (!doc) {
    toast('Invalid inspection type!', { type: 'error' });
    return <Redirect to="/vehicleinspection" />;
  }

  return (
    <MainContainer>
      <ImageBackgroiundExteriorFront>
        <MainBgExteriorFront>
          <ContentExteriorFront>
            <ContentExteriorFrontH5>
              <ContentExteriorArrowBack>
                <ContentExteriorA to="/">
                  <i className="fas fa-chevron-left fa-2x" />
                </ContentExteriorA>
              </ContentExteriorArrowBack>
              <ContentExteriorSpan>
                {doc.title}
              </ContentExteriorSpan>
            </ContentExteriorFrontH5>
            <ContentExteriorP>
              {doc.description}
            </ContentExteriorP>
          </ContentExteriorFront>
          <ContentFooterAreaExteriorFront>
            <ContentFooterAreaExteriorPlayBtn>
              <i className="fas fa-play fa-2x" />
            </ContentFooterAreaExteriorPlayBtn>
            <img src="/images/upload_img.png" alt="" width="100%" />
            <ContentFooterAreaExteriorBtmImgText>
              {doc.steps}
            </ContentFooterAreaExteriorBtmImgText>
            <ContentFooterAreaExteriorMtB5>
              <ContentFooterAreaExteriorSigninBtn to={`/capture/${id}`}>
                Next
              </ContentFooterAreaExteriorSigninBtn>
            </ContentFooterAreaExteriorMtB5>
          </ContentFooterAreaExteriorFront>
        </MainBgExteriorFront>
      </ImageBackgroiundExteriorFront>
    </MainContainer>
  );
};

export default withAuth(InstructionsPage);
