import { AxiosResponse } from 'axios';
import api from '.';

export const login = (values: object): Promise<AxiosResponse> => api.post('/api/v1/auth/login', values);

export const forgetPassword = (values: object): Promise<AxiosResponse> => api.post('/api/v1/auth/reset/email', values);

export const resetPassword = (values: object): Promise<AxiosResponse> => api.post('/api/v1/auth/reset/password', values);

export const contactUs = (values: object): Promise<AxiosResponse> => api.post('api/v1/auth/contactUs', values);
