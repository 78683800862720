/* eslint-disable */
import styled, { css } from 'styled-components';

interface InputInterface {
  invalid: boolean;
}

export const Input = styled.input`
  background: #e7eef5;
  border: 1px solid #e7eff8;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  margin: 0 auto 20px;
  padding: 15px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* color: #889bad; */

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  letter-spacing: ${(props) => (props.type === 'password' ? (props.value ? '5px' : '1px') : '1px')}
    ${(p: InputInterface) =>
      p.invalid &&
      css`
        border-color: red;
      `};
`;

export const InputDiv = styled.div`
  position: relative;
  width: 30%;

  @media screen and (max-width: 786px) {
    width: 100%;
  }
`;

export const InputLabel = styled.label`
  text-align: right;
  display: block;
  color: #2698fb;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  right: 26px;
  top: 3px;
  padding: 15px;

  ${(p: InputInterface) =>
    p.invalid &&
    css`
      color: red;
    `}
`;
