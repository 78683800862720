import { FormikValues } from 'formik';
import history from '../history';
import { ActionTypes } from './ActionTypes';

export const hideError = () => ({ type: ActionTypes.AuthFAIL });

export const authError = (payload: string) => ({ type: ActionTypes.AuthFAIL, payload });

export const loginFromToken = () => ({ type: ActionTypes.LoginFromToken });

export const loginUser = (values: FormikValues) => ({
  type: ActionTypes.LoginUserStart,
  values,
});

export const loginUserComplete = (payload: Object) => ({
  type: ActionTypes.LoginUserEnd,
  payload,
});

export const forgetPassword = (values: FormikValues) => ({
  type: ActionTypes.ForgetPasswordStart,
  values,
});

export const forgetPasswordComplete = (payload: Object) => ({
  type: ActionTypes.ForgetPasswordEnd,
  payload,
});

export const resetPassword = (values: FormikValues) => ({
  type: ActionTypes.RestPasswordStart,
  values,
});

export const resetPasswordComplete = (payload: Object) => ({
  type: ActionTypes.RestPasswordEnd,
  payload,
});

export const setLoading = (payload: boolean) => ({
  type: ActionTypes.Loading,
  payload,
});

export const uploadFileLoading = (payload: Object) => ({
  type: ActionTypes.SpecificImageeUploadLoading,
  payload,
});

export const setImageUploadLoading = (payload: boolean) => ({
  type: ActionTypes.ImageUploadLoading,
  payload,
});

export const isModalOpen = (payload: boolean) => ({
  type: ActionTypes.IsVehicalInspectionModal,
  payload,
});

export const setModalState = (payload: boolean) => ({
  type: ActionTypes.setVehicalModalValue,
  payload,
});

export const registerUser = (values: FormikValues) => ({
  type: ActionTypes.RegisterUserStart,
  values,
});

export const logoutUser = () => ({ type: ActionTypes.LogoutUserStart });

export const logoutUserComplete = () => {
  history.push('/');

  return { type: ActionTypes.LogoutUserEnd };
};

export const uploadFile = (file: File, category: string, vehicleId: number) => ({
  type: ActionTypes.UploadFileStart,
  values: {
    file,
    category,
    vehicleId,
  },
});

export const deleteFileForInspection = (id: string, vehicleId: string, catagory: string) => ({
  type: ActionTypes.DeleteFile,
  values: { id, vehicleId, catagory },
});

interface FileType {
  category: string;
  url: string;
  vehicle_id: number;
  id: number;
}

export const uploadFileComplete = (payload: FileType) => {
  history.push('/vehicleInspection');

  return { type: ActionTypes.UploadFileEnd, payload };
};

export const deleteFileComplete = (payload: any) => {
  history.push('/vehicleInspection');

  return { type: ActionTypes.deleteFileEnd, payload };
};

export const resendEmailLink = (email: string) => ({
  type: ActionTypes.EmailVerificationStart,
  values: { email },
});

export const confirmEmailStart = (values: { email: string; token: string }) => ({
  type: ActionTypes.ConfirmEmailStart,
  values,
});

export const startPayment = (values: any) => ({
  type: ActionTypes.CreatePaymentStart,
  values,
});

export const getTransactionHistory = (values: any) => ({
  type: ActionTypes.transactionHistory,
  values,
});

export const setTransactionHistory = (payload: object) => ({
  type: ActionTypes.setTransactionHistory,
  payload,
});

export const contactUs = (payload: object) => ({
  type: ActionTypes.setContactUs,
  payload,
});
