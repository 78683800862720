import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MainContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 20px;
`;

export const ImageBackgroundInsertDetails = styled.div`
  position: absolute;
  width: 100%;
  height: 72vh;
  left: 0px;
  top: 0px;
  background-repeat: no-repeat;
  background-size: 135%;
  background-position: center top;
`;

export const MainDownContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 95vh;
  background: #1b68b3;
  // background: linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%);
  // background: 'linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%)';
  background-repeat: no-repeat;
  width: 100%;
  background-size: 205%;
  background-position: center top;

  @media screen and (max-width: 700px) {
    background: none;
    margin-top: 0px;
    width: 100%;
    height: 100vh;
  }
`;

export const MainBgInsertDetails = styled.div`
  background: linear-gradient(0deg, rgba(27, 104, 179, 0.6) 0%, #1b68b3 99.97%);
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
`;

export const ContentFooterAreaInsertDetails = styled.div`
  background: #fff;
  border-radius: 30px 30px 0 0;
  margin-top: 70px;
  height: 89%;
  padding: 30px 30px;
  width: 30%;

  @media screen and (max-width: 700px) {
    width: 100%;
    height: 100%;
  }
`;

export const CancelButton = styled(Link)`
  // float: right;
  cursor: pointer;
  font-size: 17px;
  position: absolute;
  top: 31px;
  left: 25px;
  color: #2584e0;
}
`;

export const VehicleInspectionP = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #798696;
  margin: 20px auto;
  width: 50%;
`;

export const ContentVehicleInspectionH1Span = styled.span`
  color: #ff9900;
`;

export const VehicleInspectionArrowBack = styled.span`
  color: #2584e0;
  font-size: 14px;
`;

export const ContentVehicleInspectionH1 = styled.h1`
  margin: 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  text-align: center;
  color: #ffffff;
  align-self: center;
  margin: 0 auto;
`;

export const OpenNavSpan = styled.button`
  font-size: 30px;
  cursor: pointer;
  background: none;
  border: 0;
  color: #2584e0;
`;

export const VehicalDetailDiv = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #2a2a2a;
`;

export const VehicalDetailContainer = styled.div`
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 13px;
`;

export const InsertDetailsLabel = styled.label`
  color: #889bad;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
`;

export const VehicleDetailHeading = styled.h4`
  text-align: center;
  display: block;
  margin-top: 10px;
  margin-bottom: 30px;
`;

export const InsertDetailsMtB5 = styled.div`
  margin-top: 48px;
  text-align: center;
  margin-bottom: 0px;

  button {
    background: linear-gradient(90deg, #ff7a00 0%, #ff9900 94.74%);
    border-radius: 100px;
    border: 1px solid #ff7400;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;
    padding: 14px 104px 15px;
    text-decoration: none;
    margin-bottom: 20px;
    width: 100%;
    :focus {
      outline: none;
      box-shadow: 0;
    }
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
`;

export const ArrowBackA = styled(Link)`
  color: #2584e0;
`;
